import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
import { EAuthCode } from '~/enums/EAuthCode';

export default [ 
  {
    path: routeNames.orderManageList,
    component: () => import('../../pages/orderManage/orderManageList'),
    meta: { 
      title: '商城订单管理',
      code:EAuthCode.OrderManage_MallOrderManage
    }
  },
  {
    path: routeNames.orderDetail,
    component: () => import('../../pages/orderManage/orderManageList/orderDetail'),
    meta: {
      title: '订单详情',
      hideInMenu: true
    }
  },
  {
    path: routeNames.afterSale,
    component: () => import('../../pages/orderManage/afterSale'),
    meta: { 
      title: '售后管理',
      code:EAuthCode.OrderManage_RefundOrderManage 
    }
  },
  {
    path: routeNames.afterDetail,
    component: () => import('../../pages/orderManage/afterSale/afterDetail'),
    meta: {
      title: '售后详情',
      hideInMenu: true
    }
  },
  {
    path: routeNames.propertyPayableList,
    component: () => import('../../pages/orderManage/propertyPayableList'),
    meta: {
      title: '物业费已缴费列表',
      code:EAuthCode.OrderManage_PropertyPaymentManage
    }
  },
  {
    path: routeNames.propertyUnpaidList,
    component: () => import('../../pages/orderManage/propertyUnpaidList'),
    meta: {
      title: '物业费未缴费列表',
      code:EAuthCode.OrderManage_PropertyNoPaymentManage
    }
  },
  {
    path: routeNames.orderRepairList,
    component: () => import('../../pages/orderManage/orderRepairList'),
    meta: {
      title: '物业维修订单列表',
      code:EAuthCode.OrderManage_PropertyMaintainOrderManage
    }
  },
  {
    path: routeNames.orderRepairDetail,
    component: () => import('../../pages/orderManage/orderRepairList/orderDetail'),
    meta: {
      title: '物业维修订单详情',
      hideInMenu: true
    }
  },
  {
    path: routeNames.visitorAppointList,
    component: () => import('../../pages/orderManage/visitorAppointList'),
    meta: {
      title: '访客预约申请列表',
      code:EAuthCode.OrderManage_ReservationManage
    }
  },

  {
    path: routeNames.housekeepingList,
    component: () => import('../../pages/orderManage/housekeepingList'),
    meta: {
      title: '家政订单列表',
      code:EAuthCode.OrderManage_HousekeepintOrderManage
    }
  },
  {
    path: routeNames.housekeepingDetail,
    component: () => import('../../pages/orderManage/housekeepingList/orderDetail'),
    meta: {
      title: '家政订单详情',
      hideInMenu: true
    }
  },

  {
    path: routeNames.fitnessList,
    component: () => import('../../pages/orderManage/fitnessList'),
    meta: {
      title: '健身订单列表',
      code:EAuthCode.OrderManage_FitnessOrderManage
    }
  },
  {
    path: routeNames.fitnessDetail,
    component: () => import('../../pages/orderManage/fitnessList/orderDetail'),
    meta: {
      title: '健身订单详情',
      hideInMenu: true
    }
  }
]  as TRoutes[]
