import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
import { EAuthCode } from '~/enums/EAuthCode';
export default [ 

  {
    path: routeNames.members,
    component: () => import('../../pages/memberManagement/members'),
    meta: { title: '会员管理',
    code:EAuthCode.IndividualManage_MemberManage
    }
  },
  {
    path: routeNames.userTags,
    component: () => import('../../pages/personalCenter/userTags'),
    meta: { 
      title: '用户标签管理',
      code:EAuthCode.IndividualManage_MemberTagManage
    }
  },
  {
    path: routeNames.memberInfo,
    component: () => import('../../pages/memberManagement/memberInfo'),
    meta: { title: '会员信息',
      hideInMenu:true

    }
  },{
    path: routeNames.auditManagement, 
    component: () => import('../../pages/memberManagement/auditManagement'),
    meta: { title: '认证审核管理',
    code:EAuthCode.IndividualManage_IdentityAuthentication
      //, code:EAuthCode.BANNER_MANAGEMENT 
    }
  },{
    path: routeNames.ownerInformationManagement, 
    component: () => import('../../pages/memberManagement/ownerInformationManagement'),
    meta: { title: '业主信息管理',
    code:EAuthCode.IndividualManage_OwnerInfoManage
      //, code:EAuthCode.BANNER_MANAGEMENT 
    }
  },{
    path: routeNames.propertyConsultantManage, 
    component: () => import('../../pages/memberManagement/propertyConsultantManage'),
    meta: { title: '置业顾问管理',
    code:EAuthCode.IndividualManage_ConsultanManage
      //, code:EAuthCode.BANNER_MANAGEMENT 
    }
  },
  {
    path: routeNames.ownerList, 
    component: () => import('../../pages/memberManagement/propertyConsultantManage/ownerList'),
    meta: { title: '关联业主',
      hideInMenu:true
    }
  },
  {
    path: routeNames.ownerCheck, 
    component: () => import('../../pages/memberManagement/propertyConsultantManage/ownerList/ownerCheck'),
    meta: { title: '查找业主',
      hideInMenu:true
    }
  },
  {
    path: routeNames.ownersAssets,
    component: () => import('../../pages/memberManagement/ownerInformationManagement/ownersAssets'),
    meta: { title: ' ',
      hideInMenu:true
      //, code:EAuthCode.BANNER_MANAGEMENT 
    }
  },{
    path: routeNames.houseProperty, 
    component: () => import('../../pages/memberManagement/ownerInformationManagement/ownersAssets/houseProperty'),
    meta: { title: '业主资产',
    hideInMenu:true
      //, code:EAuthCode.BANNER_MANAGEMENT 
    }
  },

  {
    path: routeNames.memberRights,
    component: () => import('../../pages/memberCardManagement/memberRights'),
    meta: { title: '会员权益管理',
    code:EAuthCode.IndividualManage_MemberPowerManage
      //, code:EAuthCode.BANNER_MANAGEMENT 
    }
  },
  {
    path: routeNames.memberLevel,
    component: () => import('../../pages/memberCardManagement/memberLevel'),
    meta: { title: '会员等级管理',
    code:EAuthCode.IndividualManage_MemberLevelManage
     // hideInMenu:true
      //, code:EAuthCode.BANNER_MANAGEMENT 
    }
  },
  {
    path: routeNames.growthConfig,
    component: () => import('../../pages/memberCardManagement/growthConfig'),
    meta: { title: '成长值设置',
    code:EAuthCode.IndividualManage_GrowthManage
     // hideInMenu:true
      //, code:EAuthCode.BANNER_MANAGEMENT 
    }
  },


  {
    path: routeNames.couponManageList,
    component: () => import('../../pages/couponManage/couponManageList'),
    meta: { 
      title: '优惠券管理',
      code:EAuthCode.IndividualManage_CouponManage
    }
  },
  {
    path: routeNames.allCommodity,
    component: () => import('../../pages/couponManage/couponManageList/allCommodity'),
    meta: {
      title: '适用商品',
      hideInMenu: true
    }
  },
  {
    path: routeNames.participateCommodity,
    component: () => import('../../pages/couponManage/couponManageList/participateCommodity'),
    meta: {
      title: '参与商品',
      hideInMenu: true
    }
  },
  {
    path: routeNames.couponGrant,
    component: () => import('../../pages/couponManage/couponGrant'),
    meta: { 
      title: '优惠券发放', 
      code:EAuthCode.IndividualManage_CouponSend
    }
  },
  {
    path: routeNames.grantDetail,
    component: () => import('../../pages/couponManage/couponGrant/grantDetail'),
    meta: {
      title: '发放详情',
      hideInMenu: true
    }
  },
  {
    path: routeNames.couponList,
    component: () => import('../../pages/couponManage/couponList'),
    meta: { 
      title: '优惠券记录', 
      code:EAuthCode.IndividualManage_CouponRecord
    }
  },
  {
    path: routeNames.integrateSetup,
    component: () => import('../../pages/integrateManage'),
    meta: { title: '积分设置',
    code:EAuthCode.IndividualManage_IntegralManage
   }
  },
  {
    path: routeNames.integrateDaily,
    component: () => import('../../pages/integrateManage/integrateDaily'),
    meta: { title: '积分日志',
    code:EAuthCode.IndividualManage_Integrallog
   }
  },
 
 
  {
    path: routeNames.agreeOn,
    component: () => import('../../pages/personalCenter/agreeOn'),
    meta: {
       title: '相关协议',
       code:EAuthCode.IndividualManage_ProtocolManage
    }
  },
  {
    path: routeNames.editAgreeOn,
    component: () => import('../../pages/personalCenter/agreeOn/edit'),
    meta: { 
    title: '编辑协议',
    hideInMenu: true
    }
  },
  {
    path: routeNames.feedbackManagement,
    component: () => import('../../pages/contentManagement/feedbackManagement'),
    meta: { title: '意见反馈', 
    code:EAuthCode.IndividualManage_FeedbackManage
    }
  },
  {
    path: routeNames.qA,
    component: () => import('../../pages/personalCenter/qA'),
    meta: { 
      title: 'Q&A设置',
      code:EAuthCode.IndividualManage_QAManage
    }
  },
  {
    path: routeNames.otherEvaluations,
    component: () => import('../../pages/personalCenter/otherEvaluations'),
    meta: { 
      title: '跑腿和维修评价',
      code:EAuthCode.IndividualManage_PWEvaluation
    }
  },
  {
    path: routeNames.goodsReviews,
    component: () => import('../../pages/personalCenter/goodsReviews'),
    meta: { 
      title: '评价管理',
      code:EAuthCode.IndividualManage_EvaluationManage
    }
  },
  {
    path: routeNames.customerService,
    component: () => import('../../pages/personalCenter/customerService'),
    meta: { 
      title: '客服中心设置',
      code:EAuthCode.IndividualManage_CustomerService
    }
  }
]  as TRoutes[]
