const { REACT_APP_ENV } = process.env;
export const isDevelopment = REACT_APP_ENV === 'development';
export const isUat = REACT_APP_ENV === 'uat';

export const apiUrl = isDevelopment ? '/' : isUat ? '/' : '/';

export const aliyunOssTokenUrl = `/admin/oss/info`;
// export const aliyunOssTokenUrl = `/merchant/oss/info`;

export const defaultPwd = 'ocj123456'; // 默认密码

export const publicPath = '/'

export const routeType = 'hash'; // 路由类型 history | hash

// export const outUrl = "https://dev.fengabner.com" //后台测试
export const outUrl = "https://admin.jdzjtzy.com" //后台测试
export const outUrl2 = "https://jinghua.heifeng.xin"

/**
 * 是否跳过权限验证
 * @warning 允许在接口无法调用时继续本地开发.只允许在本地开发时使用
 */
export const skipAuth = process.env.NODE_ENV === 'development' && false

/** 
 * 配置栏配置属性
 * 
 * @description 请从配置栏目中拷贝替换
 */
export const systemConfig: SystemInfo = {
  layoutSetting: {
    menuTheme: 'dark',
    headerTheme: 'light'
  },
  breadcrumb: {
    type: 'normal'
  }
}