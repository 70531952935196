import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
import { EAuthCode } from '~/enums/EAuthCode';

export default [ 
  {
    path: routeNames.banner,
    component: () => import('../../pages/commodityManagement/banner'),
    meta: { 
      title: '商城轮播图',
      code:EAuthCode.MallManage_BannerManage
    }
  },
  {
    path: routeNames.addBanner,
    component: () => import('../../pages/commodityManagement/banner/addBanner'),
    meta: {
      title: '新建banner',
      hideInMenu: true
    }
  },  
  {
    path: routeNames.sortManagement,
    component: () => import('../../pages/commodityManagement/sortManagement'),
    meta: { 
      title: '商品分类管理',
      code:EAuthCode.MallManage_GoodsClassify
    }
  },
  // {
  //   path: routeNames.freightTemplate,
  //   component: () => import('../../pages/commodityManagement/freightTemplate'),
  //   meta: {
  //      title: '运费模板'
  //   }
  // },
  // {
  //   path: routeNames.addFreight,
  //   component: () => import('../../pages/commodityManagement/freightTemplate/addFreight'),
  //   meta: {
  //     title: '运费模板',
  //     hideInMenu: true
  //   }
  // },  
  // {
  //   path: routeNames.commodityManage,
  //   component: () => import('../../pages/commodityManagement/commodityManage'),
  //   meta: { title: '商品管理'
  //   }
  // },

  {
    path: routeNames.entranceDiagram,
    component: () => import('../../pages/commodityManagement/entranceDiagram'),
    meta: {
      title: '入口图设置',
      code:EAuthCode.MallManage_EntranceConfig
    }
  },
  {
    path: routeNames.merchantManagement,
    component: () => import('../../pages/commodityManagement/merchantManagement'),
    meta: {
      title: '商家管理',
      code:EAuthCode.MallManage_StoreManage
    }
  },
  {
    path: routeNames.addBusiness,
    component: () => import('../../pages/commodityManagement/merchantManagement/add'),
    meta: {
      title: '商家管理',
      hideInMenu: true
    }
  },
  {
    path: routeNames.merchantClassification,
    component: () => import('../../pages/commodityManagement/merchantClassification'),
    meta: {
      title: '商家分类',
      code:EAuthCode.MallManage_StoreClassify
    }
  },
  {
    path: routeNames.merchantReview,
    component: () => import('../../pages/commodityManagement/merchantReview'),
    meta: {
      title: '自营认证审核',
      code:EAuthCode.MallManage_SelfAuth
    }
  },
  {
    path: routeNames.accountReview,
    component: () => import('../../pages/commodityManagement/accountReview'),
    meta: {
      title: '修改账号审核',
      code:EAuthCode.MallManage_AccountAuth
    }
  },

  {
    path: routeNames.productRecommendations,
    component: () => import('../../pages/commodityManagement/productRecommendations'),
    meta: {
      title: '商品推荐管理',
      code:EAuthCode.MallManage_GoodsRecommendManage
    }
  }
]  as TRoutes[]
