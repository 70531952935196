import { createBrowserHistory, createHashHistory } from 'history'
import iconFile from './images/icon_file.jpg';
import { generateRouteAndMenu, IRoute, routeNames } from './const'
import BasicLayout from '~/layouts/basicLayout'
import MMPNoAuth from '~/pages/403'

import generate from './modules/generates'
import _example from './modules/_example'
import departmentManagement from './modules/departmentManagement'
import basicSetting from './modules/basicSetting'
import categoryStorage from './modules/categoryStorage'
import decorationSetting from './modules/decorationSetting'
import contentManagement from './modules/contentManagement'

//物业服务
import propertyManagement from './modules/propertyManagement'

// rhb
import integrateManage from './modules/integrateManage'
import signinManage from './modules/signinManage'
import memberManagement from './modules/memberManagement'
import memberCardManagement from './modules/memberCardManagement'
import recommendManage from './modules/recommendManage'
import buildingManage from './modules/buildingManage'
//商城管理
import commodityManagement from './modules/commodityManagement'
//数据统计
import dataManagement from './modules/dataManagement'
//订单管理
import orderManage from './modules/orderManage'
//优惠券管理
import couponManage from './modules/couponManage'
//营销活动
import marketingCampaign from './modules/marketingCampaign'

import personalCenter from './modules/personalCenter'

import lifeServicesManagement from './modules/lifeServicesManagement'
// 财务管理
import financialManagement from './modules/financialManagement'


//商家端
import storeInformation from './modules/storeInformation'
import storeGoodsManagement from './modules/storeGoodsManagement'
import storeEvaluateManagement from './modules/storeEvaluateManagement'
import storeCouponManagement from './modules/storeCouponManagement'
import marketingActivities from './modules/marketingActivities'
import storeOrderManagement from './modules/storeOrderManagement'
import storeFinanceManagement from './modules/storeFinanceManagement'
import storeStatistics from './modules/storeStatistics'
import Login from '../pages/login'
import businessLogin from '../pages/businessLogin'
import { AppstoreOutlined, BgColorsOutlined, ClusterOutlined, FundOutlined, SettingOutlined, TableOutlined } from '@ant-design/icons'
import { routeType } from '~/config';
import { EAuthCode } from '~/enums/EAuthCode';

export const history = routeType === 'hash' ? createHashHistory() : createBrowserHistory()

export const routers: IRoute[] = [
  { path: routeNames.login, component: Login, meta: { title: '登录', sync: true, noLogin: true } },
  // { path: routeNames.login, component: businessLogin, meta: { title: '商家端登录', sync: true, noLogin: true } },
  {
    path: '/',
    layout: BasicLayout,
    children: [
      { name: '首页', path: '/contentManagement', icon: FundOutlined, children: contentManagement },
      { name: '物业服务', path: '/propertyManagement', icon: FundOutlined, children: propertyManagement },
      { name: '推荐管理', path: '/recommendManage', icon: BgColorsOutlined, children: recommendManage },
      { name: '商城', path: '/commodityManagement', icon: AppstoreOutlined, children: commodityManagement },
      { name: '生活服务', path: '/lifeServices', icon: AppstoreOutlined, children: lifeServicesManagement },
      { name: '订单管理', path: '/orderManage', icon: FundOutlined, children: orderManage },
      { name: '个人中心', path: '/personalCenter', icon: AppstoreOutlined, children: personalCenter },
      { name: '数据统计', path: '/dataManagement', icon: FundOutlined, children: dataManagement },
      { name: '权限管理', path: '/departmentManagement', icon: AppstoreOutlined, children: departmentManagement },
      { name: '财务管理', path: '/financialManagement', icon: SettingOutlined, children: financialManagement },

        // 以下是商家端
      // { name: '店铺信息', path: '/storeInformation', icon: FundOutlined, children: storeInformation },
      // { name: '商品管理', path: '/storeGoodsManagement', icon: FundOutlined, children: storeGoodsManagement },
      // { name: '营销管理', path: '/marketingActivities', icon: FundOutlined, children: marketingActivities },
      // { name: '优惠券管理', path: '/storeCouponManagement', icon: FundOutlined, children: storeCouponManagement },
      // { name: '评价管理', path: '/storeEvaluateManagement', icon: FundOutlined, children: storeEvaluateManagement },
      // { name: '订单管理', path: '/storeOrderManagement', icon: FundOutlined, children: storeOrderManagement },
      // { name: '财务管理', path: '/storeFinanceManagement', icon: FundOutlined, children: storeFinanceManagement },
      // { name: '数据统计', path: '/storeStatistics', icon: FundOutlined, children: storeStatistics },

      { path: routeNames[403], component: MMPNoAuth, meta: { sync: true, noLogin: true } }
    
    
    ]
  }
]


const { routes } = generateRouteAndMenu(routers)
export function getMenus() {
  return generateRouteAndMenu(routers).menus;
}

export { routes }
