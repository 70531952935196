import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
import { EAuthCode } from '~/enums/EAuthCode';

export default [ 
  {
    path: routeNames.commodityExpense,
    component: () => import('../../pages/dataManagement/commodityExpense'),
    meta: { 
      title: '商品消费统计',
      code:EAuthCode.StatManage_PConsumeStat
    }
  },
  {
    path: routeNames.commDetail,
    component: () => import('../../pages/dataManagement/commodityExpense/commDetail'),
    meta: {
      title: '商品消费详情',
      hideInMenu: true
    }
  },
  {
    path: routeNames.userExpense,
    component: () => import('../../pages/dataManagement/userExpense'),
    meta: { 
      title: '用户消费统计' ,
      code:EAuthCode.StatManage_UConsumeStat
    }
  },
  {
    path: routeNames.membershipStatistics,
    component: () => import('../../pages/dataManagement/membershipStatistics'),
    meta: { 
      title: '会员统计',
      code:EAuthCode.StatManage_MemberStat 
    }
  },
  {
    path: routeNames.userStatistics,
    component: () => import('../../pages/dataManagement/userStatistics'),
    meta: { 
      title: '用户画像',
      code:EAuthCode.StatManage_UserProtrait 
    }
  },
  {
    path: routeNames.storeStatistics,
    component: () => import('../../pages/dataManagement/storeStatistics'),
    meta: { 
      title: '店铺统计',
      code:EAuthCode.StatManage_StoreStat 
    }
  },
  {
    path: routeNames.trafficStatistics,
    component: () => import('../../pages/dataManagement/trafficStatistics'),
    meta: { 
      title: '流量统计',
      code:EAuthCode.StatManage_FlowStat 
    }
  }
  
]  as TRoutes[]
