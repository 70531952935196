import { LogoutOutlined, KeyOutlined } from '@ant-design/icons'
import { Avatar, Dropdown, Menu, Space, Input, Modal, Form, Button, message } from 'antd'
import { useContext, FC, memo, useCallback, useState } from 'react'
import type { MenuInfo } from 'rc-menu/lib/interface'
import globalContext from '~/contexts/global.context'
import { api } from '~/request'
import { useHistory } from 'react-router-dom'
import { routeNames } from '~/routes/const'
import { IUserInfoProps } from './const'
import avatar from '~/assets/images/default_avatar.png'
import { encryptPassword } from '../../../pages/login/const'
const Component: FC<IUserInfoProps> = (props) => {
  const { name, dispatch } = useContext(globalContext)
  const history = useHistory()
  const [visible, setVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const onMenuClick = useCallback(
    (event: MenuInfo) => {
      const { key } = event
      if (key === 'logout') {
        window.localStorage.removeItem('Authorization')
        dispatch({ name: '' })
        history.replace(routeNames.login)
        return
      } else if (key === 'psd') {
        setVisible(true)
      }
    },
    [history]
  )

  const handleOk = () => {
    console.log('输入的内容：', inputValue)
    setVisible(false)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const onFinish = async (values) => {
    await api['/admin/auth/resetPwd_PUT']({
      password: encryptPassword(values.password),
      newPassword: encryptPassword(values.newPassword)
    })
    message.success('修改成功')
    window.localStorage.removeItem('Authorization')
    dispatch({ name: '' })
    history.replace(routeNames.login)
  }

  const menuHeaderDropdown = (
    <Menu selectedKeys={[]} onClick={onMenuClick}>
      <Menu.Item key="psd">
        <KeyOutlined />
        修改密码
      </Menu.Item>
      <Menu.Item key="logout">
        <LogoutOutlined />
        退出登录
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <Dropdown overlay={menuHeaderDropdown}>
        <Space>
          <Avatar src={avatar} />
          <span>{name}, 您好</span>
        </Space>
      </Dropdown>
      <Modal title="修改密码" visible={visible} onOk={handleOk} onCancel={handleCancel} destroyOnClose footer={null}>
        <Form name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} initialValues={{ remember: true }} onFinish={onFinish} autoComplete="off">
          <Form.Item label="旧密码" name="password" rules={[{ required: true, message: '请输入旧密码' }]}>
            <Input.Password />
          </Form.Item>

          <Form.Item label="新密码" name="newPassword" rules={[{ required: true, message: '请输入新密码' }]}>
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

Component.defaultProps = {}
Component.displayName = 'UserInfo'

const UserInfo = memo(Component)
export default UserInfo
