import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
import { EAuthCode } from '~/enums/EAuthCode';

export default [ 
  {
    path: routeNames.lifeBanner,
    component: () => import('../../pages/lifeServicesManagement/lifeBanner'),
    meta: { 
      title: '生活服务轮播图',
      code:EAuthCode.LifeManage_BannerManage
    }
  },
  {
    path: routeNames.announcementGuide,
    component: () => import('../../pages/lifeServicesManagement/announcementGuide'),
    meta: { 
      title: '公告指南',
      code:EAuthCode.LifeManage_Announcement
    }
  },
  {
    path: routeNames.addAnnouncementGuide,
    component: () => import('../../pages/lifeServicesManagement/announcementGuide/addNew'),
    meta: { 
      title: '公告指南',
      hideInMenu: true
    }
  },
  {
    path: routeNames.secondhandMarket,
    component: () => import('../../pages/lifeServicesManagement/secondhandMarket'),
    meta: { 
      title: '跳蚤管理',
      code:EAuthCode.LifeManage_MarketManage
    }
  },
  {
    path: routeNames.secondhandMarketClassification,
    component: () => import('../../pages/lifeServicesManagement/secondhandMarketClassification'),
    meta: { 
      title: '跳蚤分类管理',
      code:EAuthCode.LifeManage_MarketClassify
    }
  },
  {
    path: routeNames.bodybuildingManage,
    component: () => import('../../pages/lifeServicesManagement/bodybuildingManage'),
    meta: { 
      title: '健身娱乐管理',
      code:EAuthCode.LifeManage_FitnessManage
    }
  },

  {
    path: routeNames.addBodybuildingManage,
    component: () => import('../../pages/lifeServicesManagement/bodybuildingManage/addNew'),
    meta: { 
      title: '健身娱乐管理',
      hideInMenu: true
    }
  },

  {
    path: routeNames.equipmentList,
    component: () => import('../../pages/lifeServicesManagement/bodybuildingManage/equipment'),
    meta: { 
      title: '设备器材',
      hideInMenu: true
    }
  },

  {
    path: routeNames.addEquipment,
    component: () => import('../../pages/lifeServicesManagement/bodybuildingManage/equipment/add'),
    meta: { 
      title: '设备器材',
      hideInMenu: true
    }
  },

  {
    path: routeNames.homeEconomicsManage,
    component: () => import('../../pages/lifeServicesManagement/homeEconomicsManage'),
    meta: { 
      title: '家政管理',
      code:EAuthCode.LifeManage_HousekeepingManage
    }
  },
  {
    path: routeNames.addHomeEconomicsManage,
    component: () => import('../../pages/lifeServicesManagement/homeEconomicsManage/addNew'),
    meta: { 
      title: '家政管理',
      hideInMenu: true
    }
  },


  {
    path: routeNames.householdClassification,
    component: () => import('../../pages/lifeServicesManagement/householdClassification'),
    meta: { 
      title: '家政分类管理',
      code:EAuthCode.LifeManage_HouseheepingClassify
    }
  }
]  as TRoutes[]
