export enum EAuthCode {
  /** 权限管理 */
  PERMISSIONS = 'permissions',
  /** 权限管理-部门管理 */
  PermissionsManage_DeptManage = 'PermissionsManage-DeptManage',

  /** 权限管理-用户管理 */
  PermissionsManage_UserManage = 'PermissionsManage-UserManage',

  /** 权限管理-角色管理 */
  PermissionsManage_RoleManage = 'PermissionsManage-RoleManage',
  FinanceManage_RakeSet='FinanceManage-RakeSet',
  FinanceManage_CouponSettle='FinanceManage-CouponSettle',
  FinanceManage_ObjectionAppeal='FinanceManage-ObjectionAppeal',
  /** 内容管理 */
  CONTENTMANAGE = 'contentManage',
  /** 内容管理-banner管理 */
  HomeManage_Banner = 'HomeManage-Banner',


  //跑马灯
  HomeManage_Marquee='HomeManage-Marquee',
  //转发区
  HomeManage_ForwardInterface = 'HomeManage-ForwardInterface',
  HomeManage_Activity='HomeManage-Activity',
  //活动管理
  CONTENTMANAGE_ACTIVITY='contentManage-activity',

  /** 内容管理-问答管理 */
  CONTENTMANAGE_QUESTION = 'contentManage-question',

  /** 内容管理-意见反馈 */
  IndividualManage_FeedbackManage = 'IndividualManage-FeedbackManage',
  IndividualManage_QAManage='IndividualManage-QAManage',
  IndividualManage_PWEvaluation='IndividualManage-PWEvaluation',
  IndividualManage_EvaluationManage='IndividualManage-EvaluationManage',
  IndividualManage_CustomerService='IndividualManage-CustomerService',
  /** 内容管理-金刚区管理 */
  HomeManage_DiamondRegion = 'HomeManage-DiamondRegion',
  

  /** 内容管理-问卷管理 */
  HomeManage_PropertyQuestion = 'HomeManage-PropertyQuestion',
  HomeManage_QuestionClassify='HomeManage-QuestionClassify',
  /** 内容管理-资讯管理 */
  HomeManage_InformationList = 'HomeManage-InformationList',
  HomeManage_InformationData='HomeManage-InformationData',
  HomeManage_InformationClassify='HomeManage-InformationClassify',
  /** 积分管理 */
  INTEGRALMANAGE = 'integralManage',
  /** 积分管理-积分设置 */
  IndividualManage_IntegralManage = 'IndividualManage-IntegralManage',

  /** 积分管理-积分日志 */
  IndividualManage_Integrallog = 'IndividualManage-Integrallog',
  IndividualManage_ProtocolManage='IndividualManage-ProtocolManage',
  /** 签到管理 */
  SIGNMANAGE = 'signManage',
  /** 签到管理-签到配置 */
  HomeManage_SignConfig = 'HomeManage-SignConfig',

  /** 签到管理-签到活动管理 */
  HomeManage_SignActivityManage = 'HomeManage-SignActivityManage',

  /** 签到管理-签到记录 */
  HomeManage_SignLog = 'HomeManage-SignLog',

  HomeManage_SensitiveWords='HomeManage-SensitiveWords',
  HomeManage_HotKeyword='HomeManage-HotKeyword',
  HomeManage_VoteManage='HomeManage-VoteManage',
  HomeManage_VoteCassify='HomeManage-VoteCassify',
  HomeManage_RedPacketManage='HomeManage-RedPacketManage',
  HomeManage_RedSet='HomeManage-RedSet',
  HomeManage_ActivityConfigManage='HomeManage-ActivityConfigManage',
  HomeManage_OfflineActivityManage='HomeManage-OfflineActivityManage',
  HomeManage_PaidActivityOrder='HomeManage-PaidActivityOrder',
  HomeManage_LotteryManage='HomeManage-LotteryManage',
  HouseManage_StaffEmployeeManage='HouseManage-StaffEmployeeManage',
  HouseManage_ButlerEmployeeManage='HouseManage-ButlerEmployeeManage',
  HouseManage_SpotCostManage='HouseManage-SpotCostManage',
  HouseManage_MaintainClassify='HouseManage-MaintainClassify',
  // /** 商城管理 */
  // COMMODITYMANAGE = 'commodityManage',
  // /** 商城管理-banner管理 */
  // COMMODITYMANAGE_BANNER = 'commodityManage-banner',
  // /** 商城管理-分类管理 */
  // COMMODITYMANAGE_SORT = 'commodityManage-sort',
  // /** 商城管理-运费模板 */
  // COMMODITYMANAGE_FREIGHT = 'commodityManage-freight',
  // /** 商城管理-商品管理 */
  // COMMODITYMANAGE_COMMODITY = 'commodityManage-commodity',
  // /** 数据统计 */
  // DATAMANAGEMENT = 'dataManagement',
  // /** 数据统计-商品销售统计 */
  StatManage_PConsumeStat = 'StatManage-PConsumeStat',
  // /** 数据统计-用户消费统计 */
  StatManage_UConsumeStat = 'StatManage-UConsumeStat',
  StatManage_MemberStat='StatManage-MemberStat',
  StatManage_UserProtrait='StatManage-UserProtrait',
  StatManage_StoreStat='StatManage-StoreStat',
  StatManage_FlowStat='StatManage-FlowStat',
  // /** 订单管理 */
  // ORDERMANAGE = 'orderManage',
  // /** 订单管理-订单管理 */
  // ORDERMANAGE_ORDERMANAGELIST = 'orderManage-orderManageList',
  // /** 订单管理-售后管理 */
  // ORDERMANAGE_USEREXPENSE = 'orderManage-afterSale',
  // /** 优惠券管理 */
  // COUPONMANAGE = 'couponManage',
  // /** 优惠券管理-优惠券管理 */
  IndividualManage_CouponManage = 'IndividualManage-CouponManage',
  // /** 数据统计-优惠券发放 */
  IndividualManage_CouponSend = 'IndividualManage-CouponSend',
  // /** 数据统计-优惠券记录 */
  IndividualManage_CouponRecord = 'IndividualManage-CouponRecord',
  // /** 营销活动 */
  // MARKETINGCAMPAIGN = 'marketingCampaign',
  // /** 营销活动-配置管理 */
  // MARKETINGCAMPAIGN_CONFIGMANAGE = 'marketingCampaign-configManage',
  // /** 营销活动-线下活动管理 */
  // MARKETINGCAMPAIGN_OFFLINEACTIVITYMANAGE = 'marketingCampaign-offlineActivityManage',
  // /** 营销活动-付费活动订单管理 */
  // MARKETINGCAMPAIGN_PAYINGACTIVITY = 'marketingCampaign-payingActivity',
  // /** 营销活动-抽奖活动 */
  // MARKETINGCAMPAIGN_LOTTERY = 'marketingCampaign-lottery',
  /** 会员管理 */
  MEMBERMANAGE = 'memberManage',
  /** 会员管理-会员管理 */
  IndividualManage_MemberManage = 'IndividualManage-MemberManage',
  IndividualManage_MemberTagManage='IndividualManage-MemberTagManage',
  /** 会员管理-认证审核管理 */
  IndividualManage_IdentityAuthentication = 'IndividualManage-IdentityAuthentication',

  /** 会员管理-业主信息管理 */
  IndividualManage_OwnerInfoManage = 'IndividualManage-OwnerInfoManage',

  /** 会员管理-置业顾问管理 */
  IndividualManage_ConsultanManage = 'IndividualManage-ConsultanManage',

  /** 会员卡管理 */
  MEMBERCARDMANAGE = 'memberCardManage',
  /** 会员卡管理-会员权益管理 */
  IndividualManage_MemberPowerManage = 'IndividualManage-MemberPowerManage',

  /** 会员卡管理-会员等级管理 */
  IndividualManage_MemberLevelManage = 'IndividualManage-MemberLevelManage',

  /** 会员卡管理-成长值设置 */
  IndividualManage_GrowthManage = 'IndividualManage-GrowthManage',

  /** 楼盘管理 */
  HOUSEMANAGE = 'houseManage',
  /** 楼盘管理-小区管理 */
  HouseManage_CommunityManage = 'HouseManage-CommunityManage',

  /** 楼盘管理-楼栋管理 */
  HouseManage_BuildingManage = 'HouseManage-BuildingManage',

  /** 楼盘管理-门牌号管理 */
  HouseManage_DoorPlateManage = 'HouseManage-DoorPlateManage',

  /** 楼盘管理-户型管理 */
  HouseManage_HouseTypeManage = 'HouseManage-HouseTypeManage',

  /** 推荐管理 */
  RECOMMENDMANAGE = 'recommendManage',
  /** 推荐管理-推荐配置 */
  AdviseManage_Config = 'AdviseManage-Config',

  /** 推荐管理-审核管理 */
  AdviseManage_AuditManage = 'AdviseManage-AuditManage',

  /** 推荐管理-推荐管理 */
  AdviseManage_AManage = 'AdviseManage-AManage',
  MallManage_BannerManage='MallManage-BannerManage',
  MallManage_GoodsClassify='MallManage-GoodsClassify',
  MallManage_EntranceConfig='MallManage-EntranceConfig',
  MallManage_StoreManage='MallManage-StoreManage',
  MallManage_StoreClassify='MallManage-StoreClassify',
  MallManage_SelfAuth='MallManage-SelfAuth',
  MallManage_AccountAuth='MallManage-AccountAuth',
  MallManage_GoodsRecommendManage='MallManage-GoodsRecommendManage',
  LifeManage_BannerManage='LifeManage-BannerManage',
  LifeManage_Announcement='LifeManage-Announcement',
  LifeManage_MarketManage='LifeManage-MarketManage',
  LifeManage_MarketClassify='LifeManage-MarketClassify',
  LifeManage_FitnessManage='LifeManage-FitnessManage',
  LifeManage_HousekeepingManage='LifeManage-HousekeepingManage',
  LifeManage_HouseheepingClassify='LifeManage-HouseheepingClassify',
  OrderManage_MallOrderManage='OrderManage-MallOrderManage',
  OrderManage_RefundOrderManage='OrderManage-RefundOrderManage',
  OrderManage_PropertyNoPaymentManage='OrderManage-PropertyNoPaymentManage',
  OrderManage_PropertyPaymentManage='OrderManage-PropertyPaymentManage',
  OrderManage_PropertyMaintainOrderManage='OrderManage-PropertyMaintainOrderManage',
  OrderManage_ReservationManage='OrderManage-ReservationManage',
  OrderManage_HousekeepintOrderManage='OrderManage-HousekeepintOrderManage',
  OrderManage_FitnessOrderManage='OrderManage-FitnessOrderManage',
  //物业员工管理
  SERVE_PEOPLE = 'serve-people',
  STEWARD_PEOPLE = 'steward-people',
  COMMUNITY_MANAGEMENT = 'community-management',
  BUILDING_MANAGEMENT = 'building-management',
  NUMBERPLATE_MANAGEMENT = 'number-plate-management',
  HOUSETYPE_MANAGEMENT = 'house-type-management',
  PARKING_MANAGEMENT = 'parking-management',
  REPAIR_CLASS = 'repair-class',
  EXAMINE_AND_VERIFY = 'examine-and-verify',
  PROPERTY_QUESTIONS_MANAGE = 'property-questions-manage'
}


