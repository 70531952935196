import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
import { EAuthCode } from '~/enums/EAuthCode';

export default [
  {
    path: routeNames.bannerManagement,
    component: () => import('../../pages/contentManagement/bannerManagement'),
    meta: {
      title: '首页轮播图管理',
      code: EAuthCode.HomeManage_Banner
    }
  },
  {
    path: routeNames.noticebarManagement,
    component: () => import('../../pages/contentManagement/noticebarManagement'),
    meta: {
      title: '跑马灯',
      code: EAuthCode.HomeManage_Marquee
    }
  },
  {
    path: routeNames.addNoticebar,
    component: () => import('../../pages/contentManagement/noticebarManagement/addNoticebar'),
    meta: {
      title: '跑马灯',
      hideInMenu: true
    }
  },

  {
    path: routeNames.vajraDistrictManage,
    component: () => import('../../pages/contentManagement/vajraDistrictManage'),
    meta: {
      title: '金刚区',
      code: EAuthCode.HomeManage_DiamondRegion
    }
  },
  {
    path: routeNames.retransmissionManagement,
    component: () => import('../../pages/contentManagement/retransmissionManagement'),
    meta: {
      title: '转发界面管理',
      code: EAuthCode.HomeManage_ForwardInterface
    }
  },
  {
    path: routeNames.activityManagement,
    component: () => import('../../pages/contentManagement/activityManagement'),
    meta: { title: '活动区管理' },
    code: EAuthCode.HomeManage_Activity
  },


  {
    path: routeNames.questionnaireManage,
    component: () => import('../../pages/contentManagement/questionnaireManage'),
    meta: {
      title: '物业问卷管理',
      code: EAuthCode.HomeManage_PropertyQuestion
    }
  },
  {
    path: routeNames.questionnaireClassify,
    component: () => import('../../pages/contentManagement/questionnaireManage/classify'),
    meta: { title: '物业问卷分类' },
    code: EAuthCode.HomeManage_QuestionClassify
  },
  {
    path: routeNames.addQuestion,
    component: () => import('../../pages/contentManagement/questionnaireManage/addQuestion'),
    meta: {
      title: '新增问卷',
      hideInMenu: true
    }
  },
  {
    path: routeNames.questionsManage,
    component: () => import('../../pages/contentManagement/questionsManage'),
    meta: {
      title: '物业问答管理'
    }
  },
  {
    path: routeNames.topicStatic,
    component: () => import('../../pages/contentManagement/questionnaireManage/topicStatic'),
    meta: {
      title: '题目维度统计',
      hideInMenu: true
    }
  },
  {
    path: routeNames.staticDetail,
    component: () => import('../../pages/contentManagement/questionnaireManage/topicStatic/staticDetail'),
    meta: {
      title: '用户答卷',
      hideInMenu: true
    }
  },
  {
    path: routeNames.appraiseManage,
    component: () => import('../../pages/contentManagement/newManage/appraiseManage'),
    meta: {
      title: '评价管理',
      hideInMenu: true
    }
  },
  {
    path: routeNames.newManage,
    component: () => import('../../pages/contentManagement/newManage'),
    meta: {
      title: '资讯列表',
      code: EAuthCode.HomeManage_InformationList
    }
  },
  {
    path: routeNames.newManageStatistics,
    component: () => import('../../pages/contentManagement/newManage/statistics'),
    meta: {
      title: '资讯数据',
      code: EAuthCode.HomeManage_InformationData
    }
  },
  {
    path: routeNames.newsClassify,
    component: () => import('../../pages/contentManagement/newsClassify'),
    meta: {
      title: '资讯分类',
      code: EAuthCode.HomeManage_InformationClassify
    }
  },
  {
    path: routeNames.addNew,
    component: () => import('../../pages/contentManagement/newManage/addNew'),
    meta: {
      title: '资讯管理',
      hideInMenu: true
    }
  },

  {
    path: routeNames.signinManage,
    component: () => import('../../pages/signinManage'),
    meta: {
      title: '签到配置',
      code: EAuthCode.HomeManage_SignConfig
    }
  },
  {
    path: routeNames.signinActiveManage,
    component: () => import('../../pages/signinManage/signinActiveManage'),
    meta: {
      title: '签到活动管理',
      code: EAuthCode.HomeManage_SignActivityManage
    }
  },
  {
    path: routeNames.signinRecord,
    component: () => import('../../pages/signinManage/signinRecord'),
    meta: {
      title: '签到记录',
      code: EAuthCode.HomeManage_SignLog
    }
  },
  {
    path: routeNames.sensitiveManagement,
    component: () => import('../../pages/contentManagement/sensitiveManagement'),
    meta: {
      title: '敏感词',
      code: EAuthCode.HomeManage_SensitiveWords
    }
  },
  {
    path: routeNames.hotWordsManagement,
    component: () => import('../../pages/contentManagement/hotWordsManagement'),
    meta: {
      title: '热门搜索词',
      code: EAuthCode.HomeManage_HotKeyword
      
    }
  },
  
  {
    path: routeNames.voteManagement,
    component: () => import('../../pages/contentManagement/voteManagement'),
    meta: {
      title: '投票管理',
      code: EAuthCode.HomeManage_VoteManage
    }
  },
  {
    path: routeNames.addVoteManagement,
    component: () => import('../../pages/contentManagement/voteManagement/addNew'),
    meta: {
      title: '投票管理',
      hideInMenu: true
    }
  },
  {
    path: routeNames.voteManagementClassification,
    component: () => import('../../pages/contentManagement/voteManagement/classification'),
    meta: {
      title: '投票分类',
      code:EAuthCode.HomeManage_VoteCassify
    }
  },
  {
    path: routeNames.voteAwards,
    component: () => import('../../pages/contentManagement/voteManagement/awards'),
    meta: {
      title: '投票获奖名单',
      hideInMenu: true
    }
  },
  {
    path: routeNames.voteParticipant,
    component: () => import('../../pages/contentManagement/voteManagement/participant'),
    meta: {
      title: '投票参与者管理',
      hideInMenu: true
    }
  },
  {
    path: routeNames.addVoteParticipant,
    component: () => import('../../pages/contentManagement/voteManagement/participant/add'),
    meta: {
      title: '投票参与者管理',
      hideInMenu: true
    }
  },
  {
    path: routeNames.redDnvelopeManagement,
    component: () => import('../../pages/contentManagement/redDnvelopeManagement'),
    meta: {
      title: '红包管理',
      code:EAuthCode.HomeManage_RedPacketManage
    }
  },
  {
    path: routeNames.addRedDnvelope,
    component: () => import('../../pages/contentManagement/redDnvelopeManagement/addNew'),
    meta: {
      title: '红包管理',
      hideInMenu: true
    }
  },
  {
    path: routeNames.participantsList,
    component: () => import('../../pages/contentManagement/redDnvelopeManagement/participantsList'),
    meta: {
      title: '红包参与列表',
      hideInMenu: true
    }
  },
  {
    path: routeNames.redDnvelopeManagementSet,
    component: () => import('../../pages/contentManagement/redDnvelopeManagement/set'),
    meta: {
      title: '设置红包每日中奖次数',
      code:EAuthCode.HomeManage_RedSet
    }
  },
  {
    path: routeNames.configManage,
    component: () => import('../../pages/marketingCampaign/configManage'),
    meta: { 
      title: '配置管理',
      code:EAuthCode.HomeManage_ActivityConfigManage
    }
  },
  {
    path: routeNames.offlineActivityManage,
    component: () => import('../../pages/marketingCampaign/offlineActivityManage'),
    meta: {
      title: '线下活动管理',
      code:EAuthCode.HomeManage_OfflineActivityManage
    }
  },
  {
    path: routeNames.addActivity,
    component: () => import('../../pages/marketingCampaign/offlineActivityManage/addActivity'),
    meta: {
      title: '新建活动',
      hideInMenu: true
    }
  },
  {
    path: routeNames.detailActivity,
    component: () => import('../../pages/marketingCampaign/offlineActivityManage/detailActivity'),
    meta: {
      title: '活动详情',
      hideInMenu: true
    }
  },
  {
    path: routeNames.selectMember,
    component: () => import('../../pages/marketingCampaign/offlineActivityManage/addActivity/selectMember'),
    meta: {
      title: '选择会员',
      hideInMenu: true
    }
  },
  {
    path: routeNames.freeMember,
    component: () => import('../../pages/marketingCampaign/offlineActivityManage/freeMember'),
    meta: {
      title: '参与会员-免费',
      hideInMenu: true
    }
  },
  {
    path: routeNames.chargeMember,
    component: () => import('../../pages/marketingCampaign/offlineActivityManage/chargeMember'),
    meta: {
      title: '参与会员-收费',
      hideInMenu: true
    }
  },
  {
    path: routeNames.payingActivity,
    component: () => import('../../pages/marketingCampaign/payingActivity'),
    meta: { 
      title: '付费活动订单管理', 
      code:EAuthCode.HomeManage_PaidActivityOrder
    }
  },
  {
    path: routeNames.lottery,
    component: () => import('../../pages/marketingCampaign/lottery'),
    meta: { 
      title: '抽奖活动', 
      code:EAuthCode.HomeManage_LotteryManage
    }
  },
  // {
  //   path: routeNames.lotteryWhiteList,
  //   component: () => import('../../pages/marketingCampaign/lotteryWhiteList'),
  //   meta: { 
  //     title: '抽奖活动白名单',
  //     hideInMenu: true 
  //   }
  // },
  {
    path: routeNames.addLottery,
    component: () => import('../../pages/marketingCampaign/lottery/addLottery'),
    meta: {
      title: '新增抽奖活动',
      hideInMenu: true
    }
  },
  {
    path: routeNames.userPart,
    component: () => import('../../pages/marketingCampaign/lottery/userPart'),
    meta: {
      title: '参与用户',
      hideInMenu: true
    }
  },
  {
    path: routeNames.prizeWhiteList,
    component: () => import('../../pages/marketingCampaign/lottery/prizeWhiteList'),
    meta: {
      title: '奖品白名单设置',
      hideInMenu: true
    }
  }

] as TRoutes[]
