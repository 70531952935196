import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
import { EAuthCode } from '~/enums/EAuthCode';

export default [ 
  {
    path: routeNames.servePeople,
    component: () => import('../../pages/propertyManagement/servePeople'),
    meta: { title: '员工管理',
    // code:EAuthCode.SERVE_PEOPLE 
    code:EAuthCode.HouseManage_StaffEmployeeManage 
    }
  },
  {
    path: routeNames.stewardPeople,
    component: () => import('../../pages/propertyManagement/stewardPeople'),
    meta: { title: '管家管理',
    // code:EAuthCode.STEWARD_PEOPLE 
    code:EAuthCode.HouseManage_ButlerEmployeeManage 
    }
  },
  {
    path: routeNames.relevance,
    component: () => import('../../pages/propertyManagement/stewardPeople/relevance'),
    meta: { 
    title: '已关联业主列表',
    hideInMenu: true
    }
  },
  {
    path: routeNames.addAssociated,
    component: () => import('../../pages/propertyManagement/stewardPeople/ownerNotAssociated'),
    meta: { 
    title: '业主列表',
    hideInMenu: true
    }
  },

 
  {
    path: routeNames.parkingManagement,
    component: () => import('../../pages/propertyManagement/parkingManagement'),
    meta: { 
      title: '车位管理费设置',
      code:EAuthCode.HouseManage_SpotCostManage
    }
  },
  {
    path: routeNames.repairClass,
    component: () => import('../../pages/propertyManagement/repairClass'),
    meta: {
       title: '物业维修分类',
       code:EAuthCode.HouseManage_MaintainClassify
    }
  },

  {
    path: routeNames.buildingManage,
    component: () => import('../../pages/buildingManage'),
    meta: {
      title: '小区管理',
      code: EAuthCode.HouseManage_CommunityManage
    }
  },
  {
    path: routeNames.newCreateCommunity,
    component: () => import('../../pages/buildingManage/communityManage/newCreateCommunity'),
    meta: {
      title: '新建小区',
      hideInMenu: true
    }
  },
  {
    path: routeNames.communityDetail,
    component: () => import('../../pages/buildingManage/communityManage/communityDetail'),
    meta: {
      title: '小区详情',
      hideInMenu: true
    }
  },
  {
    path: routeNames.buildingNoManage,
    component: () => import('../../pages/buildingManage/buildingNoManage'),
    meta: {
      title: '楼栋管理',
      code: EAuthCode.HouseManage_BuildingManage
    }
  },
  {
    path: routeNames.BuildingProgress,
    component: () => import('../../pages/buildingManage/buildingNoManage/BuildingProgress'),
    meta: {
      title: '楼栋进度',
      hideInMenu: true
      //, code:EAuthCode.BANNER_MANAGEMENT 
    }
  },
  {
    path: routeNames.houseNumberManage,
    component: () => import('../../pages/buildingManage/houseNumberManage'),
    meta: {
      title: '门牌号管理',
      code: EAuthCode.HouseManage_DoorPlateManage
      //, code:EAuthCode.BANNER_MANAGEMENT 
    }
  },
  {
    path: routeNames.houseTypeManage,
    component: () => import('../../pages/buildingManage/houseTypeManage'),
    meta: {
      title: '户型管理',
      code: EAuthCode.HouseManage_HouseTypeManage
      //, code:EAuthCode.BANNER_MANAGEMENT 
    }
  },
  {
    path: routeNames.addHouseType,
    component: () => import('../../pages/buildingManage/houseTypeManage/addHouseType'),
    meta: {
      title: '添加户型',
      hideInMenu: true
    }
  },
  {
    path: routeNames.associatedHouseNumber,
    component: () => import('../../pages/buildingManage/houseTypeManage/associatedHouseNumber'),
    meta: {
      title: '关联门牌号',
      hideInMenu: true
    }
  },{
    path: routeNames.searchHouseNumber,
    component: () => import('../../pages/buildingManage/houseTypeManage/searchHouseNumber'),
    meta: {
      title: '查找门牌号',
      hideInMenu: true
    }
  }

]  as TRoutes[]
