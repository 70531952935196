import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
import { EAuthCode } from '~/enums/EAuthCode';
export default [
  {
    path: routeNames.financialStatistics,
    component: () => import('../../pages/financialManagement/financialStatistics'),
    meta: { title: '财务统计'}
  },
  // {
  //   path: routeNames.extractionSetting,
  //   component: () => import('../../pages/financialManagement/extractionSetting'),
  //   meta: { 
  //     title: '平台抽成设置',
  //     code:EAuthCode.FinanceManage_RakeSet
  //   }
  // },
  {
    path: routeNames.couponSettlement,
    component: () => import('../../pages/financialManagement/couponSettlement'),
    meta: { 
      title: '商家平台优惠券结算',
      code:EAuthCode.FinanceManage_CouponSettle
     }
  },
  {
    path: routeNames.appealList,
    component: () => import('../../pages/financialManagement/appealList'),
    meta: { 
      title: '商家异议申诉',
      code:EAuthCode.FinanceManage_ObjectionAppeal
    }
  }
] as TRoutes[]