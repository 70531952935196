import { IRequestConfig } from '~/modules/@wmeimob/request/src/types/fetch-type'
import {
  AcceptanceEvaluation,
  AdminApiAcceptanceevaluationQueryGetParams,
  AdminApiAnswerrecordQueryAnswerListGetParams,
  AdminApiAnswerrecordQueryGetParams,
  AdminApiAudimanageQueryGetParams,
  AdminApiBannermanagementQueryBannerPageGetParams,
  AdminApiBannerQueryGetParams,
  AdminApiBuildingDeleteBuildingDeleteParams,
  AdminApiBuildingQueryBuildForCommunityGetParams,
  AdminApiBuildingQueryBuildingInfoPageGetParams,
  AdminApiBuildingQueryGetParams,
  AdminApiBuildingscheduleDeleteBuildingScheduleDeleteParams,
  AdminApiBuildingscheduleQueryBuildingScheduleGetParams,
  AdminApiBuildingscheduleQueryGetParams,
  AdminApiCarinfoQueryCarInfoGetParams,
  AdminApiCarinfoQueryGetParams,
  AdminApiCartQueryGetParams,
  AdminApiCommunityDeleteCommunityDeleteParams,
  AdminApiCommunityQueryCommunityImgGetParams,
  AdminApiCommunityQueryCommunityInfoGetParams,
  AdminApiCommunityQueryCommunityPageGetParams,
  AdminApiCommunityQueryGetParams,
  AdminApiCommunityQueryHouseTypeByCommunityGetParams,
  AdminApiConsultaninfoQueryGetParams,
  AdminApiConsultantOwnerRelationshipQueryGetParams,
  AdminApiConsultantOwnerRelationshipQueryUnRelationshipGetParams,
  AdminApiCouponentityExportGetParams,
  AdminApiCouponentityQueryGetParams,
  AdminApiCouponGetGoodsInCouponGetParams,
  AdminApiCouponGetGoodsNotInCouponGetParams,
  AdminApiCouponQueryGetParams,
  AdminApiCouponQueryListGetParams,
  AdminApiCouponsendlogsinfoExportGetParams,
  AdminApiCouponsendlogsinfoQueryGetParams,
  AdminApiCouponsendlogsQueryGetParams,
  AdminApiDatastatisticsExportGoodsSaleStatisticsGetParams,
  AdminApiDatastatisticsExportMemberPayStatisticsGetParams,
  AdminApiDatastatisticsGoodsSaleStatisticsGetParams,
  AdminApiDatastatisticsQueryGoodsOrderNumberGetParams,
  AdminApiDatastatisticsQueryGoodsSaleStatisticsDetailGetParams,
  AdminApiDatastatisticsQueryMemberOrderAmountGetParams,
  AdminApiDatastatisticsQueryMemberPayStatisticsGetParams,
  AdminApiDiamondregionQueryGetParams,
  AdminApiDoorplateDeleteDoorPlateDeleteParams,
  AdminApiDoorplateQueryDoorPlateInfoBeyondHouseTypeGetParams,
  AdminApiDoorplateQueryDoorPlateInfoGetParams,
  AdminApiDoorplateQueryDoorPlateInfoListGetParams,
  AdminApiDoorplateQueryGetParams,
  AdminApiEventprizemanagementGetEffectivePrizeListGetParams,
  AdminApiEventprizemanagementQueryGetParams,
  AdminApiExpresstemplateareaQueryGetParams,
  AdminApiExpresstemplatedetailsQueryGetParams,
  AdminApiExpresstemplateQueryAllExpressTemplateListGetParams,
  AdminApiExpresstemplateQueryExpressTemplateDetailGetParams,
  AdminApiExpresstemplateQueryExpressTemplateListGetParams,
  AdminApiFamilyinfoQueryGetParams,
  AdminApiFamilyQueryGetParams,
  AdminApiFamilyrelationshipQueryGetParams,
  AdminApiFeedbackQueryGetParams,
  AdminApiGoodsclassifyQueryGoodsClassifyListGetParams,
  AdminApiGoodsQueryGoodsDetailGetParams,
  AdminApiGoodsQueryGoodsPageGetParams,
  AdminApiGoodsskuQueryGetParams,
  AdminApiGrowthconfigQueryGetParams,
  AdminApiGrowthlogQueryGetParams,
  AdminApiHousetypeDeleteHouseTypeDeleteParams,
  AdminApiHousetypeQueryGetParams,
  AdminApiHousetypeQueryHousePageGetParams,
  AdminApiIdentityauthenticationmanageQueryGetParams,
  AdminApiIdentityauthenticationmanageQueryPageGetParams,
  AdminApiInformationClassifyQueryListGetParams,
  AdminApiInformationClassifyQueryPageGetParams,
  AdminApiInformationQueryGetParams,
  AdminApiIntegralconfigQueryGetParams,
  AdminApiIntegrallogQueryGetParams,
  AdminApiIntegrallogQueryPageGetParams,
  AdminApiIntroQueryGetParams,
  AdminApiInvitefamilyrecordQueryGetParams,
  AdminApiLuckydrawactivitymanagementQueryLuckyDrawActivityDetailPostParams,
  AdminApiLuckydrawactivitymanagementQueryPageGetParams,
  AdminApiLuckydrawactivitymembermanagementExportLuckyDrawActivityGetParams,
  AdminApiLuckydrawactivitymembermanagementQueryLuckyDrawActivityMemberPageGetParams,
  AdminApiMallConfPageDeleteDeleteParams,
  AdminApiMallConfPageDetailGetParams,
  AdminApiMemberaddressmanagementQueryListGetParams,
  AdminApiMemberaudirecordQueryGetParams,
  AdminApiMemberinfoQueryIntegralLogForMemberGetParams,
  AdminApiMemberinfoQueryMemberPageGetParams,
  AdminApiMemberinfoQueryMyFamilyGetParams,
  AdminApiMemberlevelconfigQueryAllGetParams,
  AdminApiMemberlevelconfigQueryGetParams,
  AdminApiMemberlevellogQueryGetParams,
  AdminApiMemberpowerconfigQueryAllGetParams,
  AdminApiMemberpowerconfigQueryGetParams,
  AdminApiMemberpowerconfigQueryMemberPowerOfLevelGetParams,
  AdminApiMemberpowerlevelrefQueryGetParams,
  AdminApiOfflineactivitymanagementAddOfflineActivityPostParams,
  AdminApiOfflineactivitymanagementQueryListPageGetParams,
  AdminApiOfflineactivitymanagementQueryOfflineActivityDetailGetParams,
  AdminApiOfflineactivitymembermanagementExportFreeMemberListGetParams,
  AdminApiOfflineactivitymembermanagementExportPayMemberListGetParams,
  AdminApiOfflineactivitymembermanagementQueryFreeMemberListPageGetParams,
  AdminApiOfflineactivitymembermanagementQueryPayMemberListPageGetParams,
  AdminApiOrderitemsQueryGetParams,
  AdminApiOrderQueryOrderDetailGetParams,
  AdminApiOrderQueryOrderListPageGetParams,
  AdminApiOwnerinfoDeleteOwnerHouseDeleteParams,
  AdminApiOwnerinfoQueryGetParams,
  AdminApiOwnerinfoQueryOwnerHouseGetParams,
  AdminApiPaidactivityordermanagementExportPaidOrderListGetParams,
  AdminApiPaidactivityordermanagementQueryOrderListPageGetParams,
  AdminApiPaidactivityorderrefundrecordQueryGetParams,
  AdminApiPrizeWhitelistQueryPageGetParams,
  AdminApiQuestionnaireFillAnswerListGetParams,
  AdminApiQuestionnaireFillGetInfoGetParams,
  AdminApiQuestionnaireFillListGetParams,
  AdminApiQuestionnaireFillQueryGetParams,
  AdminApiQuestionnaireQueryGetParams,
  AdminApiQuestionrecordQueryGetParams,
  AdminApiQuestionrecordQueryQuestionRecordPageGetParams,
  AdminApiRecommendconfigQueryGetParams,
  AdminApiRecommendrecordExportRecommendRecordGetParams,
  AdminApiRecommendrecordQueryGetParams,
  AdminApiRecommendrecordQueryRecommendRecordGetParams,
  AdminApiRecommendrewardrecordQueryGetParams,
  AdminApiRefundorderitemsQueryGetParams,
  AdminApiRefundorderQueryRefundOrderDetailGetParams,
  AdminApiRefundorderQueryRefundOrderPageGetParams,
  AdminApiReviewmanagerExportReviewManagerGetParams,
  AdminApiReviewmanagerQueryGetParams,
  AdminApiReviewmanagerQueryReviewManagerPageGetParams,
  AdminApiSignactivityDeleteSignActivityDeleteParams,
  AdminApiSignactivityQueryGetParams,
  AdminApiSignconfigQueryGetParams,
  AdminApiSignlogQueryGetParams,
  AdminApiSignlogQuerySignLogGetParams,
  AdminApiSurroundinfoQueryForCommunityGetParams,
  AdminApiSurroundinfoQueryGetParams,
  AdminApiSysdeptDeleteDeleteParams,
  AdminApiSysdeptQueryGetParams,
  AdminApiSysRoleDeleteDeleteParams,
  AdminApiSysRoleQueryAllGetParams,
  AdminApiSysRoleQueryGetParams,
  AdminApiSysStoreUserGroupDeleteDeleteParams,
  AdminApiSysStoreUserGroupDetailGetParams,
  AdminApiSysStoreUserGroupQueryGetParams,
  AdminApiSysUserDeleteDeleteParams,
  AdminApiSysUserQueryGetParams,
  AdminApiSysUserResetDefaultDeleteParams,
  AdminApiSysUserUpdatePasswordDeleteParams,
  AdminMallConfAdvertisingDeleteDeleteParams,
  AdminMallConfAdvertisingDetailGetParams,
  AdminMallConfAdvertisingQueryGetParams,
  AdminMallConfChannelDeleteDeleteParams,
  AdminMallConfChannelQueryGetParams,
  AdminMallConfHotKeyDeleteDeleteParams,
  AdminMallConfHotKeyQueryGetParams,
  AdminMallConfigControllerQueryByKeyGetParams,
  AdminMallConfMaterialDeleteDeleteParams,
  AdminMallConfMaterialGroupDeleteDeleteParams,
  AdminMallConfMaterialGroupQueryAllGetParams,
  AdminMallConfMaterialQueryGetParams,
  AdminMallConfNavigationDeleteDeleteParams,
  AdminMallConfRefundReasonDeleteDeleteParams,
  AdminMallGoodsCategoryDelDeleteParams,
  AdminMallGoodsCategoryGetOneGetParams,
  AdminMallGoodsCategoryGetPageGetParams,
  AdminMallGoodsCategoryShowPutParams,
  AdminMallGoodsCategorySortPutParams,
  AnswerRecord,
  AnswerRecordDto,
  Audimanage,
  Banner,
  BannerDto,
  BannerManagement,
  BannerManagementDto,
  BatchRefuseDto,
  Building,
  BuildingDto,
  BuildingSchedule,
  BuildingScheduleDto,
  CarInfo,
  CarInfoDto,
  Cart,
  Collection,
  Community,
  CommunityDto,
  ConsultantInfo,
  ConsultantInfoDto,
  ConsultantOwnerRelationship,
  ConsultantOwnerRelationshipDto,
  Coupon,
  CouponDto,
  CouponEntity,
  CouponSendLogs,
  CouponSendLogsInfo,
  DiamondRegion,
  DiamondRegionDto,
  DoorPlate,
  DoorPlateDto,
  EventPrizeManagement,
  ExpressTemplate,
  ExpressTemplateArea,
  ExpressTemplateDetails,
  ExpressTemplateDto,
  Family,
  FamilyInfo,
  FamilyInfoDto,
  FamilyRelationship,
  Feedback,
  Goods,
  GoodsClassify,
  GoodsClassifyDto,
  GoodsCouponDto,
  GoodsDto,
  GoodsSku,
  GrowthConfig,
  GrowthConfigDto,
  GrowthLog,
  HouseType,
  HouseTypeDto,
  IdentityAuthenticationManage,
  IdentityAuthenticationManageDto,
  Information,
  InformationClassify,
  InformationClassifyDto,
  InformationDto,
  IntegralConfig,
  IntegralConfigDto,
  IntegralLog,
  IntegralLogDto,
  Intro,
  InviteFamilyRecord,
  JsonResult,
  JsonResultAcceptanceEvaluation,
  JsonResultAnswerRecord,
  JsonResultAudimanage,
  JsonResultBanner,
  JsonResultBannerManagement,
  JsonResultBigdecimal,
  JsonResultBoolean,
  JsonResultBuilding,
  JsonResultBuildingSchedule,
  JsonResultCarInfo,
  JsonResultCart,
  JsonResultCollection,
  JsonResultCommunity,
  JsonResultCommunityVo,
  JsonResultConsultantInfo,
  JsonResultConsultantOwnerRelationship,
  JsonResultCoupon,
  JsonResultCouponEntity,
  JsonResultCouponSendLogs,
  JsonResultCouponSendLogsInfo,
  JsonResultDiamondRegion,
  JsonResultDoorPlate,
  JsonResultEventPrizeManagement,
  JsonResultExpressTemplate,
  JsonResultExpressTemplateArea,
  JsonResultExpressTemplateDetails,
  JsonResultExpressTemplateVo,
  JsonResultFamily,
  JsonResultFamilyInfo,
  JsonResultFamilyRelationship,
  JsonResultFeedback,
  JsonResultGoods,
  JsonResultGoodsClassify,
  JsonResultGoodsDetailVo,
  JsonResultGoodsSku,
  JsonResultGrowthConfig,
  JsonResultGrowthLog,
  JsonResultHouseType,
  JsonResultIdentityAuthenticationManage,
  JsonResultInformation,
  JsonResultInformationClassify,
  JsonResultInt,
  JsonResultIntegralConfig,
  JsonResultIntegralLog,
  JsonResultIntro,
  JsonResultInviteFamilyRecord,
  JsonResultListAnswerRecord,
  JsonResultListBuilding,
  JsonResultListCarInfo,
  JsonResultListCommunity,
  JsonResultListCoupon,
  JsonResultListDoorPlate,
  JsonResultListEventPrizeManagement,
  JsonResultListExpressTemplate,
  JsonResultListGoodsClassifyVo,
  JsonResultListGoodsSkuStatisticsDetailVo,
  JsonResultListHouseInfoVo,
  JsonResultListHouseTypeVo,
  JsonResultListIdentityAuthenticationManageVo,
  JsonResultListInformationClassify,
  JsonResultListLong,
  JsonResultListMallConfConfigDto,
  JsonResultListMallConfMaterialGroupVo,
  JsonResultListMallConfNavigationVo,
  JsonResultListMallConfPageVo,
  JsonResultListMallConfRefundReasonDto,
  JsonResultListMallGoodsCategoryVo,
  JsonResultListMemberAddressManagement,
  JsonResultListMemberLevelConfigVo,
  JsonResultListMemberPowerConfig,
  JsonResultListMyFamilyVo,
  JsonResultListOwnerHouseVo,
  JsonResultListQuestionnaireFillQuestion,
  JsonResultListQuestionStatisticsVO,
  JsonResultListSurroundInfo,
  JsonResultListSysDeptHaveUserVo,
  JsonResultListSysDeptVo,
  JsonResultListSysMenuVo,
  JsonResultListSysRoleDto,
  JsonResultLong,
  JsonResultLuckyDrawActivityManagement,
  JsonResultLuckyDrawActivityManagementDetailVo,
  JsonResultLuckyDrawActivityMemberManagement,
  JsonResultLuckyDrawActivityWheelConfig,
  JsonResultMallConfAdvertisingVo,
  JsonResultMallConfPageVo,
  JsonResultMallGoodsCategoryVo,
  JsonResultMapStringObject,
  JsonResultMarketingCampaignConfig,
  JsonResultMemberAddressManagement,
  JsonResultMemberAudirecord,
  JsonResultMemberInfo,
  JsonResultMemberLevelConfig,
  JsonResultMemberLevelLog,
  JsonResultMemberPowerConfig,
  JsonResultMemberPowerLevelRef,
  JsonResultObject,
  JsonResultOfflineActivityManagement,
  JsonResultOfflineActivityManagementDetailVo,
  JsonResultOfflineActivityMemberManagement,
  JsonResultOrder,
  JsonResultOrderDetailVo,
  JsonResultOrderItems,
  JsonResultOwnerInfo,
  JsonResultPageInfoBanner,
  JsonResultPageInfoBannerManagement,
  JsonResultPageInfoBuildingScheduleVo,
  JsonResultPageInfoBuildingVo,
  JsonResultPageInfoCommunityVo,
  JsonResultPageInfoConsultantInfo,
  JsonResultPageInfoConsultantOwnerRelationshipVo,
  JsonResultPageInfoCoupon,
  JsonResultPageInfoCouponEntity,
  JsonResultPageInfoCouponSendLogsInfo,
  JsonResultPageInfoCouponSendLogsVo,
  JsonResultPageInfoDoorPlateVo,
  JsonResultPageInfoExpressTemplate,
  JsonResultPageInfoFeedback,
  JsonResultPageInfoGoods,
  JsonResultPageInfoGoodsStatisticsDetailVo,
  JsonResultPageInfoHouseType,
  JsonResultPageInfoHouseTypeVo,
  JsonResultPageInfoIdentityAuthenticationManage,
  JsonResultPageInfoIdentityAuthenticationManageVo,
  JsonResultPageInfoInformationClassify,
  JsonResultPageInfoInformationVo,
  JsonResultPageInfoIntegralLog,
  JsonResultPageInfoIntegralLogVo,
  JsonResultPageInfoLuckyDrawActivityManagementVo,
  JsonResultPageInfoLuckyDrawActivityMemberManagementVo,
  JsonResultPageInfoMallConfAdvertisingVo,
  JsonResultPageInfoMallConfChannelDto,
  JsonResultPageInfoMallConfHotKeyDto,
  JsonResultPageInfoMallConfMaterialVo,
  JsonResultPageInfoMallConfPageVo,
  JsonResultPageInfoMemberInfoVo,
  JsonResultPageInfoMemberLevelConfig,
  JsonResultPageInfoMemberPayStatisticsDetailVo,
  JsonResultPageInfoOfflineActivityManagement,
  JsonResultPageInfoOfflineActivityMemberManagementVo,
  JsonResultPageInfoOrderDetailVo,
  JsonResultPageInfoOwnerInfo,
  JsonResultPageInfoPaidActivityOrderManagementVo,
  JsonResultPageInfoPrizeWhitelistVo,
  JsonResultPageInfoQuestionnaire,
  JsonResultPageInfoQuestionnaireFill,
  JsonResultPageInfoQuestionnaireFillAnswer,
  JsonResultPageInfoRecommendRecordVo,
  JsonResultPageInfoRefundOrderDetailVo,
  JsonResultPageInfoReviewManagerVo,
  JsonResultPageInfoSignActivity,
  JsonResultPageInfoSignLogVo,
  JsonResultPageInfoSysDeptVo,
  JsonResultPageInfoSysRoleDto,
  JsonResultPageInfoSysStoreUserGroupVo,
  JsonResultPageInfoSysUserVo,
  JsonResultPaidActivityOrderManagement,
  JsonResultPaidActivityOrderRefundRecord,
  JsonResultPrizeWhitelist,
  JsonResultPrizeWhitelistVo,
  JsonResultQuestionnaire,
  JsonResultQuestionnaireFill,
  JsonResultQuestionRecord,
  JsonResultRecommendConfig,
  JsonResultRecommendRecord,
  JsonResultRecommendRewardRecord,
  JsonResultRefundOrder,
  JsonResultRefundOrderDetailVo,
  JsonResultRefundOrderItems,
  JsonResultReviewManager,
  JsonResultSignActivity,
  JsonResultSignConfig,
  JsonResultSignLog,
  JsonResultString,
  JsonResultSurroundInfo,
  JsonResultSysAuthVo,
  JsonResultSysStoreUserGroupVo,
  JsonResultVoid,
  LoginDto,
  LuckyDrawActivityManagement,
  LuckyDrawActivityManagementDto,
  LuckyDrawActivityMemberManagement,
  LuckyDrawActivityWheelConfig,
  MallConfAdvertisingAddParam,
  MallConfChannelAddParam,
  MallConfHotKeyAddParam,
  MallConfMaterialAddParam,
  MallConfMaterialGroupAddParam,
  MallConfMaterialMoveParam,
  MallConfNavigationAddParam,
  MallConfPageAddParam,
  MallConfRefundReasonAddParam,
  MallGoodsCategoryParam,
  MarketingCampaignConfig,
  MarketingCampaignConfigDto,
  MemberAddressManagement,
  MemberAudirecord,
  MemberInfo,
  MemberInfoDto,
  MemberLevelConfig,
  MemberLevelConfigDto,
  MemberLevelLog,
  MemberPowerConfig,
  MemberPowerConfigDto,
  MemberPowerLevelRef,
  OfflineActivityManagement,
  OfflineActivityManagementDto,
  OfflineActivityMemberManagement,
  OfflineActivityMemberManagementDto,
  Order,
  OrderDto,
  OrderItems,
  OwnerInfo,
  OwnerInfoDto,
  PaidActivityOrderManagement,
  PaidActivityOrderManagementDto,
  PaidActivityOrderRefundRecord,
  PrizeWhitelist,
  PrizeWhitelistDto,
  Questionnaire,
  QuestionnaireDto,
  QuestionnaireFill,
  QuestionRecord,
  RecommendConfig,
  RecommendConfigDto,
  RecommendRecord,
  RecommendRewardRecord,
  RefundOrderDto,
  RefundOrderItems,
  RefundOrderReq,
  RegisterDto,
  ResetPwdParam,
  ReviewManager,
  ReviewManagerDto,
  ReviewManagerVo,
  SendBatchCouponDto,
  SignActivity,
  SignActivityDto,
  SignConfig,
  SignConfigDto,
  SignLog,
  SurroundInfo,
  SysDeptAddParam,
  SysRoleAddParam,
  SysStoreUserGroupAddParam,
  SysUserAddParam,
  staffListParams,
  AcceptanceEmployeesPost,
  infoListParams,
  maintainClassifyParams,
  parkingParams,
  JsonResultParking,
  housekeeperParams,
  housekeeperSearchParams,
  employeeOwnerParams,
  repairOrderParams,
  repairOrderDetailParams,
  repairOrderDetail,
  allocateParams,
  propertyPayableParams,
  visitorAppointParams,
  visitorAppointApproval,
  visitorAppointApprovalAll
} from './data-contracts'
import requestInstance from './instance'

type RequestConfig = Omit<IRequestConfig, 'url' | 'method'>

export const API = {
  /**
   * No description
   * @name POST /admin/api/acceptanceevaluation
   * @summary 新增
   * @tags 房屋验收评价
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/acceptanceevaluation_POST': (t: AcceptanceEvaluation, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/acceptanceevaluation`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/acceptanceevaluation
   * @summary 修改
   * @tags 房屋验收评价
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/acceptanceevaluation_PUT': (t: AcceptanceEvaluation, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/acceptanceevaluation`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/acceptanceevaluation/query
   * @summary 房屋验收评价分页查询
   * @tags 房屋验收评价
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/acceptanceevaluation/query_GET': (query: AdminApiAcceptanceevaluationQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/acceptanceevaluation/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/acceptanceevaluation/{id}
   * @summary 详情
   * @tags 房屋验收评价
   * @response `200` `JsonResultAcceptanceEvaluation` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/acceptanceevaluation/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/acceptanceevaluation/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultAcceptanceEvaluation>,
  /**
   * No description
   * @name PUT /admin/api/acceptanceevaluation/{id}
   * @summary 修改
   * @tags 房屋验收评价
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/acceptanceevaluation/{id}_PUT': (id: number, t: AcceptanceEvaluation, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/acceptanceevaluation/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/acceptanceevaluation/{id}
   * @summary 删除
   * @tags 房屋验收评价
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/acceptanceevaluation/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/acceptanceevaluation/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/answerrecord
   * @summary 新增
   * @tags 问题回复管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/answerrecord_POST': (t: AnswerRecord, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/answerrecord`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/answerrecord
   * @summary 修改
   * @tags 问题回复管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/answerrecord_PUT': (t: AnswerRecord, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/answerrecord`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/answerrecord/answer
   * @summary 回复
   * @tags 问题回复管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/answerrecord/answer_POST': (dto: AnswerRecordDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/answerrecord/answer`,
      method: 'POST',
      data: dto,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/answerrecord/query
   * @summary 分页查询
   * @tags 问题回复管理
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/answerrecord/query_GET': (query: AdminApiAnswerrecordQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/answerrecord/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/answerrecord/queryAnswerList
   * @summary 查看回复
   * @tags 问题回复管理
   * @response `200` `JsonResultListAnswerRecord` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/answerrecord/queryAnswerList_GET': (query: AdminApiAnswerrecordQueryAnswerListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/answerrecord/queryAnswerList`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListAnswerRecord>,
  /**
   * No description
   * @name GET /admin/api/answerrecord/{id}
   * @summary 详情
   * @tags 问题回复管理
   * @response `200` `JsonResultAnswerRecord` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/answerrecord/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/answerrecord/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultAnswerRecord>,
  /**
   * No description
   * @name PUT /admin/api/answerrecord/{id}
   * @summary 修改
   * @tags 问题回复管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/answerrecord/{id}_PUT': (id: number, t: AnswerRecord, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/answerrecord/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/answerrecord/{id}
   * @summary 删除
   * @tags 问题回复管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/answerrecord/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/answerrecord/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/audimanage
   * @summary 新增
   * @tags 审核管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/audimanage_POST': (t: Audimanage, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/audimanage`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/audimanage
   * @summary 修改
   * @tags 审核管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/audimanage_PUT': (t: Audimanage, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/audimanage`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/audimanage/query
   * @summary 分页查询
   * @tags 审核管理
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/audimanage/query_GET': (query: AdminApiAudimanageQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/audimanage/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/audimanage/{id}
   * @summary 详情
   * @tags 审核管理
   * @response `200` `JsonResultAudimanage` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/audimanage/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/audimanage/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultAudimanage>,
  /**
   * No description
   * @name PUT /admin/api/audimanage/{id}
   * @summary 修改
   * @tags 审核管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/audimanage/{id}_PUT': (id: number, t: Audimanage, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/audimanage/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/audimanage/{id}
   * @summary 删除
   * @tags 审核管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/audimanage/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/audimanage/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/banner
   * @summary 新增
   * @tags 首页Banner管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/banner_POST': (t: Banner, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/banner`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/banner
   * @summary 修改
   * @tags 首页Banner管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/banner_PUT': (t: Banner, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/banner`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/banner/add
   * @summary 首页banner新增
   * @tags 首页Banner管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/banner/add_POST': (entity: BannerDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/banner/add`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name DELETE /admin/api/banner/delete/{id}
   * @summary 首页banner删除
   * @tags 首页Banner管理
   * @response `200` `JsonResult` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/banner/delete/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/banner/delete/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/banner/query
   * @summary 分页查询
   * @tags 首页Banner管理
   * @response `200` `JsonResultPageInfoBanner` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/banner/query_GET': (query: AdminApiBannerQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/banner/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoBanner>,
  /**
   * No description
   * @name PUT /admin/api/banner/updateBanner
   * @summary 首页banner编辑
   * @tags 首页Banner管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/banner/updateBanner_PUT': (entity: BannerDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/banner/updateBanner`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description useStatus: 状态 0 禁用 1启用
   * @name POST /admin/api/banner/updateBannerStatus
   * @summary 修改banner状态
   * @tags 首页Banner管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/banner/updateBannerStatus_POST': (entity: BannerDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/banner/updateBannerStatus`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/banner/{id}
   * @summary 详情
   * @tags 首页Banner管理
   * @response `200` `JsonResultBanner` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/banner/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/banner/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultBanner>,
  /**
   * No description
   * @name PUT /admin/api/banner/{id}
   * @summary 修改
   * @tags 首页Banner管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/banner/{id}_PUT': (id: number, t: Banner, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/banner/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/banner/{id}
   * @summary 删除
   * @tags 首页Banner管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/banner/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/banner/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/bannermanagement
   * @summary 新增
   * @tags 商城banner管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/bannermanagement_POST': (t: BannerManagement, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/bannermanagement`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/bannermanagement
   * @summary 修改
   * @tags 商城banner管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/bannermanagement_PUT': (t: BannerManagement, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mall/banner`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/bannermanagement/addBanner
   * @summary 新增商城banner
   * @tags 商城banner管理
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/bannermanagement/addBanner_POST': (entity: BannerManagementDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mall/banner`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/api/mall/banner
   * @summary 删除商城banner
   * @tags 商城banner管理
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/mall/banner_DELETE': (query, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mall/banner`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/mall/banner/page
   * @summary 分页查询商品banner分页
   * @tags 商城banner管理
   * @response `200` `JsonResultPageInfoBannerManagement` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/mall/banner/page_GET': (query: AdminApiBannermanagementQueryBannerPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mall/banner/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoBannerManagement>,
  /**
   * No description
   * @name PUT /admin/api/bannermanagement/updateBanner
   * @summary 编辑商城banner
   * @tags 商城banner管理
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/bannermanagement/updateBanner_PUT': (entity: BannerManagementDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mall/banner`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/bannermanagement/{id}
   * @summary 详情
   * @tags 商城banner管理
   * @response `200` `JsonResultBannerManagement` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/bannermanagement/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/bannermanagement/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultBannerManagement>,
  /**
   * No description
   * @name PUT /admin/api/bannermanagement/{id}
   * @summary 修改
   * @tags 商城banner管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/bannermanagement/{id}_PUT': (id: number, t: BannerManagement, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/bannermanagement/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/bannermanagement/{id}
   * @summary 删除
   * @tags 商城banner管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/bannermanagement/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/bannermanagement/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/building
   * @summary 新增
   * @tags 楼栋管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/building_POST': (t: Building, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/building`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/building
   * @summary 修改
   * @tags 楼栋管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/building_PUT': (t: Building, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/building`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/building/addBuilding
   * @summary 新建楼栋
   * @tags 楼栋管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/building/addBuilding_POST': (buildingDto: BuildingDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/building/addBuilding`,
      method: 'POST',
      data: buildingDto,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/api/building/associateCommunity
   * @summary 楼栋关联小区
   * @tags 楼栋管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/building/associateCommunity_POST': (entityList: Building[], options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/building/associateCommunity`,
      method: 'POST',
      data: entityList,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name DELETE /admin/api/building/deleteBuilding
   * @summary 删除楼栋
   * @tags 楼栋管理
   * @response `200` `JsonResult` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/building/deleteBuilding_DELETE': (query: AdminApiBuildingDeleteBuildingDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/building/deleteBuilding`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/building/query
   * @summary 分页查询
   * @tags 楼栋管理
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/building/query_GET': (query: AdminApiBuildingQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/building/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/building/queryBuildForCommunity
   * @summary 查询小区下所有楼栋
   * @tags 楼栋管理
   * @response `200` `JsonResultListBuilding` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/building/queryBuildForCommunity_GET': (query: AdminApiBuildingQueryBuildForCommunityGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/building/queryBuildForCommunity`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListBuilding>,
  /**
   * No description
   * @name GET /admin/api/building/queryBuildingInfoPage
   * @summary 分页查询楼栋
   * @tags 楼栋管理
   * @response `200` `JsonResultPageInfoBuildingVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/building/queryBuildingInfoPage_GET': (query: AdminApiBuildingQueryBuildingInfoPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/building/queryBuildingInfoPage`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoBuildingVo>,
  /**
   * No description
   * @name POST /admin/api/building/updateBuilding
   * @summary 编辑楼栋
   * @tags 楼栋管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/building/updateBuilding_POST': (buildingDto: BuildingDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/building/updateBuilding`,
      method: 'POST',
      data: buildingDto,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/building/{id}
   * @summary 详情
   * @tags 楼栋管理
   * @response `200` `JsonResultBuilding` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/building/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/building/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultBuilding>,
  /**
   * No description
   * @name PUT /admin/api/building/{id}
   * @summary 修改
   * @tags 楼栋管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/building/{id}_PUT': (id: number, t: Building, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/building/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/building/{id}
   * @summary 删除
   * @tags 楼栋管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/building/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/building/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/buildingschedule
   * @summary 新增
   * @tags 楼栋进度管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/buildingschedule_POST': (t: BuildingSchedule, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/buildingschedule`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/buildingschedule
   * @summary 修改
   * @tags 楼栋进度管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/buildingschedule_PUT': (t: BuildingSchedule, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/buildingschedule`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/buildingschedule/addBuildingSchedule
   * @summary 新增楼栋进度
   * @tags 楼栋进度管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/buildingschedule/addBuildingSchedule_POST': (buildingScheduleDto: BuildingScheduleDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/buildingschedule/addBuildingSchedule`,
      method: 'POST',
      data: buildingScheduleDto,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name DELETE /admin/api/buildingschedule/deleteBuildingSchedule
   * @summary 删除楼栋进度
   * @tags 楼栋进度管理
   * @response `200` `JsonResult` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/buildingschedule/deleteBuildingSchedule_DELETE': (
    query: AdminApiBuildingscheduleDeleteBuildingScheduleDeleteParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/buildingschedule/deleteBuildingSchedule`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/buildingschedule/query
   * @summary 分页查询
   * @tags 楼栋进度管理
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/buildingschedule/query_GET': (query: AdminApiBuildingscheduleQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/buildingschedule/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/buildingschedule/queryBuildingSchedule
   * @summary 分页查询楼栋进度
   * @tags 楼栋进度管理
   * @response `200` `JsonResultPageInfoBuildingScheduleVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/buildingschedule/queryBuildingSchedule_GET': (query: AdminApiBuildingscheduleQueryBuildingScheduleGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/buildingschedule/queryBuildingSchedule`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoBuildingScheduleVo>,
  /**
   * No description
   * @name PUT /admin/api/buildingschedule/updateBuildingSchedule
   * @summary 编辑楼栋进度
   * @tags 楼栋进度管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/buildingschedule/updateBuildingSchedule_PUT': (buildingScheduleDto: BuildingScheduleDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/buildingschedule/updateBuildingSchedule`,
      method: 'PUT',
      data: buildingScheduleDto,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/buildingschedule/{id}
   * @summary 详情
   * @tags 楼栋进度管理
   * @response `200` `JsonResultBuildingSchedule` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/buildingschedule/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/buildingschedule/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultBuildingSchedule>,
  /**
   * No description
   * @name PUT /admin/api/buildingschedule/{id}
   * @summary 修改
   * @tags 楼栋进度管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/buildingschedule/{id}_PUT': (id: number, t: BuildingSchedule, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/buildingschedule/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/buildingschedule/{id}
   * @summary 删除
   * @tags 楼栋进度管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/buildingschedule/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/buildingschedule/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/carinfo
   * @summary 新增
   * @tags 汽车管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/carinfo_POST': (t: CarInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/carinfo`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/carinfo
   * @summary 修改
   * @tags 汽车管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/carinfo_PUT': (t: CarInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/carinfo`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/carinfo/addCarInfo
   * @summary 添加车产
   * @tags 汽车管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/carinfo/addCarInfo_POST': (entity: CarInfoDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/carinfo/addCarInfo`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name DELETE /admin/api/carinfo/deleteCarInfo/{id}
   * @summary 删除车产
   * @tags 汽车管理
   * @response `200` `JsonResult` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/carinfo/deleteCarInfo/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/carinfo/deleteCarInfo/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/carinfo/query
   * @summary 分页查询
   * @tags 汽车管理
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/carinfo/query_GET': (query: AdminApiCarinfoQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/carinfo/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/carinfo/queryCarInfo
   * @summary 查询我的车产
   * @tags 汽车管理
   * @response `200` `JsonResultListCarInfo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/carinfo/queryCarInfo_GET': (query: AdminApiCarinfoQueryCarInfoGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/carinfo/queryCarInfo`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListCarInfo>,
  /**
   * No description
   * @name PUT /admin/api/carinfo/updateCarInfo
   * @summary 修改车产
   * @tags 汽车管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/carinfo/updateCarInfo_PUT': (entity: CarInfoDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/carinfo/updateCarInfo`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/carinfo/{id}
   * @summary 详情
   * @tags 汽车管理
   * @response `200` `JsonResultCarInfo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/carinfo/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/carinfo/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultCarInfo>,
  /**
   * No description
   * @name PUT /admin/api/carinfo/{id}
   * @summary 修改
   * @tags 汽车管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/carinfo/{id}_PUT': (id: number, t: CarInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/carinfo/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/carinfo/{id}
   * @summary 删除
   * @tags 汽车管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/carinfo/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/carinfo/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/cart
   * @summary 新增
   * @tags 购物车
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/cart_POST': (t: Cart, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/cart`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/cart
   * @summary 修改
   * @tags 购物车
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/cart_PUT': (t: Cart, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/cart`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/cart/query
   * @summary 购物车分页查询
   * @tags 购物车
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/cart/query_GET': (query: AdminApiCartQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/cart/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/cart/{id}
   * @summary 详情
   * @tags 购物车
   * @response `200` `JsonResultCart` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/cart/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/cart/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultCart>,
  /**
   * No description
   * @name PUT /admin/api/cart/{id}
   * @summary 修改
   * @tags 购物车
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/cart/{id}_PUT': (id: number, t: Cart, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/cart/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/cart/{id}
   * @summary 删除
   * @tags 购物车
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/cart/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/cart/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/collection
   * @summary 新增
   * @tags 收藏
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/collection_POST': (t: Collection, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/collection`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/collection
   * @summary 修改
   * @tags 收藏
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/collection_PUT': (t: Collection, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/collection`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/collection/{id}
   * @summary 详情
   * @tags 收藏
   * @response `200` `JsonResultCollection` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/collection/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/collection/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultCollection>,
  /**
   * No description
   * @name PUT /admin/api/collection/{id}
   * @summary 修改
   * @tags 收藏
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/collection/{id}_PUT': (id: number, t: Collection, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/collection/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/collection/{id}
   * @summary 删除
   * @tags 收藏
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/collection/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/collection/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/community
   * @summary 新增
   * @tags 小区管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/community_POST': (t: Community, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/community`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/community
   * @summary 修改
   * @tags 小区管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/community_PUT': (t: Community, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/community`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/community/addCommunity
   * @summary 新增小区
   * @tags 小区管理
   * @response `200` `JsonResultCommunity` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/community/addCommunity_POST': (entity: Community, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/community/addCommunity`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultCommunity>,
  /**
   * No description
   * @name DELETE /admin/api/community/deleteCommunity
   * @summary 删除小区
   * @tags 小区管理
   * @response `200` `JsonResult` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/community/deleteCommunity_DELETE': (query: AdminApiCommunityDeleteCommunityDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/community/deleteCommunity`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/community/query
   * @summary 分页查询
   * @tags 小区管理
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/community/query_GET': (query: AdminApiCommunityQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/community/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/community/queryCommunityAll
   * @summary 查询全部小区列表
   * @tags 小区管理
   * @response `200` `JsonResultListCommunity` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/community/queryCommunityAll_GET': (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/community/queryCommunityAll`,
      method: 'GET',
      params,
      ...options
    }) as unknown as Promise<JsonResultListCommunity>,
  /**
   * No description
   * @name GET /admin/api/community/queryCommunityImg
   * @summary 查询小区图片视频
   * @tags 小区管理
   * @response `200` `JsonResultCommunityVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/community/queryCommunityImg_GET': (query: AdminApiCommunityQueryCommunityImgGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/community/queryCommunityImg`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultCommunityVo>,
  /**
   * No description
   * @name GET /admin/api/community/queryCommunityInfo
   * @summary 查询小区详情
   * @tags 小区管理
   * @response `200` `JsonResultCommunityVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/community/queryCommunityInfo_GET': (query: AdminApiCommunityQueryCommunityInfoGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/community/queryCommunityInfo`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultCommunityVo>,
  /**
   * No description
   * @name GET /admin/api/community/queryCommunityPage
   * @summary 分页查询小区列表
   * @tags 小区管理
   * @response `200` `JsonResultPageInfoCommunityVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/community/queryCommunityPage_GET': (query: AdminApiCommunityQueryCommunityPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/community/queryCommunityPage`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoCommunityVo>,
  /**
   * No description
   * @name GET /admin/api/community/queryHouseTypeByCommunity
   * @summary 查询小区下所有户型
   * @tags 小区管理
   * @response `200` `JsonResultListHouseTypeVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/community/queryHouseTypeByCommunity_GET': (query: AdminApiCommunityQueryHouseTypeByCommunityGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/community/queryHouseTypeByCommunity`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListHouseTypeVo>,
  /**
   * No description
   * @name PUT /admin/api/community/updateCommunity
   * @summary 编辑小区
   * @tags 小区管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/community/updateCommunity_PUT': (entity: Community, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/community/updateCommunity`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/community/uploadCommunityImg
   * @summary 上传小区图片
   * @tags 小区管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/community/uploadCommunityImg_POST': (dto: CommunityDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/community/uploadCommunityImg`,
      method: 'POST',
      data: dto,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/community/{id}
   * @summary 详情
   * @tags 小区管理
   * @response `200` `JsonResultCommunity` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/community/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/community/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultCommunity>,
  /**
   * No description
   * @name PUT /admin/api/community/{id}
   * @summary 修改
   * @tags 小区管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/community/{id}_PUT': (id: number, t: Community, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/community/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/community/{id}
   * @summary 删除
   * @tags 小区管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/community/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/community/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/consultaninfo
   * @summary 新增
   * @tags 置业顾问管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/consultaninfo_POST': (t: ConsultantInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/consultaninfo`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/consultaninfo
   * @summary 修改
   * @tags 置业顾问管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/consultaninfo_PUT': (t: ConsultantInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/consultaninfo`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/consultaninfo/addConsultantInfo
   * @summary 新增置业顾问
   * @tags 置业顾问管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/consultaninfo/addConsultantInfo_POST': (entity: ConsultantInfoDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/consultaninfo/addConsultantInfo`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/api/consultaninfo/importConsultantInfo
   * @summary 导入置业顾问
   * @tags 置业顾问管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/consultaninfo/importConsultantInfo_POST': (file: any, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/consultaninfo/importConsultantInfo`,
      method: 'POST',
      data: file,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/consultaninfo/query
   * @summary 分页查询
   * @tags 置业顾问管理
   * @response `200` `JsonResultPageInfoConsultantInfo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/consultaninfo/query_GET': (query: AdminApiConsultaninfoQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/consultaninfo/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoConsultantInfo>,
  /**
   * No description
   * @name POST /admin/api/consultaninfo/updateStatus
   * @summary 修改状态
   * @tags 置业顾问管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/consultaninfo/updateStatus_POST': (entity: ConsultantInfoDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/consultaninfo/updateStatus`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/api/consultaninfo/uploadQrCode
   * @summary 上传图片
   * @tags 置业顾问管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/consultaninfo/uploadQrCode_POST': (file: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/consultaninfo/uploadQrCode`,
      method: 'POST',
      data: file,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/consultaninfo/{id}
   * @summary 详情
   * @tags 置业顾问管理
   * @response `200` `JsonResultConsultantInfo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/consultaninfo/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/consultaninfo/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultConsultantInfo>,
  /**
   * No description
   * @name PUT /admin/api/consultaninfo/{id}
   * @summary 修改
   * @tags 置业顾问管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/consultaninfo/{id}_PUT': (id: number, t: ConsultantInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/consultaninfo/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/consultaninfo/{id}
   * @summary 删除
   * @tags 置业顾问管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/consultaninfo/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/consultaninfo/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/consultantOwnerRelationship
   * @summary 新增
   * @tags 置业顾问-关联业主管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/consultantOwnerRelationship_POST': (t: ConsultantOwnerRelationship, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/consultantOwnerRelationship`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/consultantOwnerRelationship
   * @summary 修改
   * @tags 置业顾问-关联业主管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/consultantOwnerRelationship_PUT': (t: ConsultantOwnerRelationship, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/consultantOwnerRelationship`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/consultantOwnerRelationship/addConsultantInfo
   * @summary 新增关联
   * @tags 置业顾问-关联业主管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/consultantOwnerRelationship/addConsultantInfo_POST': (entity: ConsultantOwnerRelationshipDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/consultantOwnerRelationship/addConsultantInfo`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name DELETE /admin/api/consultantOwnerRelationship/delete/{id}
   * @summary 删除关联
   * @tags 置业顾问-关联业主管理
   * @response `200` `JsonResult` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/consultantOwnerRelationship/delete/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/consultantOwnerRelationship/delete/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/consultantOwnerRelationship/query
   * @summary 分页查询顾问已关联的业主
   * @tags 置业顾问-关联业主管理
   * @response `200` `JsonResultPageInfoConsultantOwnerRelationshipVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/consultantOwnerRelationship/query_GET': (query: AdminApiConsultantOwnerRelationshipQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/consultantOwnerRelationship/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoConsultantOwnerRelationshipVo>,
  /**
   * No description
   * @name GET /admin/api/consultantOwnerRelationship/queryUnRelationship
   * @summary 分页查询顾问未关联的业主
   * @tags 置业顾问-关联业主管理
   * @response `200` `JsonResultPageInfoConsultantOwnerRelationshipVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/consultantOwnerRelationship/queryUnRelationship_GET': (
    query: AdminApiConsultantOwnerRelationshipQueryUnRelationshipGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/consultantOwnerRelationship/queryUnRelationship`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoConsultantOwnerRelationshipVo>,
  /**
   * No description
   * @name GET /admin/api/consultantOwnerRelationship/{id}
   * @summary 详情
   * @tags 置业顾问-关联业主管理
   * @response `200` `JsonResultConsultantOwnerRelationship` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/consultantOwnerRelationship/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/consultantOwnerRelationship/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultConsultantOwnerRelationship>,
  /**
   * No description
   * @name PUT /admin/api/consultantOwnerRelationship/{id}
   * @summary 修改
   * @tags 置业顾问-关联业主管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/consultantOwnerRelationship/{id}_PUT': (id: number, t: ConsultantOwnerRelationship, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/consultantOwnerRelationship/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/consultantOwnerRelationship/{id}
   * @summary 删除
   * @tags 置业顾问-关联业主管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/consultantOwnerRelationship/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/consultantOwnerRelationship/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/coupon
   * @summary 新增
   * @tags 优惠券
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/coupon_POST': (t: Coupon, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/coupon`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/coupon
   * @summary 修改
   * @tags 优惠券
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/coupon_PUT': (t: Coupon, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/coupon`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/coupon/addCoupon
   * @summary 优惠券新增
   * @tags 优惠券
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/coupon/addCoupon_POST': (entity: CouponDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/coupon`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name DELETE /admin/api/coupon/deleteCoupon/{id}
   * @summary 优惠券删除
   * @tags 优惠券
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/coupon/deleteCoupon/{id}_DELETE': (query, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/coupon`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/api/coupon/deleteGoodsCoupon/{goodsId}/{couponId}
   * @summary 删除该优惠券下的参与商品
   * @tags 优惠券
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/coupon/deleteGoodsCoupon/{goodsId}/{couponId}_DELETE': (couponId: number, goodsId: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/coupon/deleteGoodsCoupon/${goodsId}/${couponId}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/coupon/getGoodsInCoupon
   * @summary 获取该优惠券下参与的商品
   * @tags 优惠券
   * @response `200` `JsonResultPageInfoGoods` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/coupon/getGoodsInCoupon_GET': (query: AdminApiCouponGetGoodsInCouponGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/coupon/getGoodsInCoupon`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoGoods>,
  /**
   * No description
   * @name GET /admin/api/coupon/getGoodsNotInCoupon
   * @summary 获取该优惠券下没有参与的商品
   * @tags 优惠券
   * @response `200` `JsonResultPageInfoGoods` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/coupon/getGoodsNotInCoupon_GET': (query: AdminApiCouponGetGoodsNotInCouponGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mall/goods/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoGoods>,
  /**
   * No description
   * @name POST /admin/api/coupon/insertGoodsCoupon
   * @summary 批量新增参与的优惠券的商品
   * @tags 优惠券
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/coupon/insertGoodsCoupon_POST': (goodsCoupons: GoodsCouponDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/coupon/goods`,
      method: 'POST',
      data: goodsCoupons,
      ...options
    }) as unknown as Promise<JsonResultVoid>,

  '/admin/api/coupon/goods_DELETE': (query, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/coupon/goods`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/coupon/query
   * @summary 优惠券分页查询
   * @tags 优惠券
   * @response `200` `JsonResultPageInfoCoupon` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/coupon/query_GET': (query: AdminApiCouponQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/coupon/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoCoupon>,
  /**
   * No description
   * @name GET /admin/api/coupon/queryList
   * @summary 优惠券列表查询
   * @tags 优惠券
   * @response `200` `JsonResultListCoupon` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/coupon/queryList_GET': (query: AdminApiCouponQueryListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/coupon/grant/list`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListCoupon>,
  /**
   * No description
   * @name POST /admin/api/coupon/sendBatchCoupon
   * @summary 选择优惠券并发放
   * @tags 优惠券
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/coupon/sendBatchCoupon_POST': (entity: SendBatchCouponDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/coupon/grant`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,

  '/admin/api/coupon/enable_PUT': (entity: CouponDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/coupon/enable`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,

  /**
   * No description
   * @name PUT /admin/api/coupon/updateCoupon
   * @summary 优惠券编辑
   * @tags 优惠券
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/coupon/updateCoupon_PUT': (entity: CouponDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/coupon`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/coupon/{id}
   * @summary 详情
   * @tags 优惠券
   * @response `200` `JsonResultCoupon` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/coupon/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/coupon/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultCoupon>,
  /**
   * No description
   * @name PUT /admin/api/coupon/{id}
   * @summary 修改
   * @tags 优惠券
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/coupon/{id}_PUT': (id: number, t: Coupon, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/coupon/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/coupon/{id}
   * @summary 删除
   * @tags 优惠券
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/coupon/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/coupon/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/couponentity
   * @summary 新增
   * @tags 优惠券实体表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/couponentity_POST': (t: CouponEntity, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/couponentity`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/couponentity
   * @summary 修改
   * @tags 优惠券实体表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/couponentity_PUT': (t: CouponEntity, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/couponentity`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/couponentity/export
   * @summary 优惠券记录导出
   * @tags 优惠券实体表
   * @response `200` `void` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/couponentity/export_GET': (query: AdminApiCouponentityExportGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/couponentity/export`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<void>,
  /**
   * No description
   * @name GET /admin/api/couponentity/query
   * @summary 优惠券记录分页查询
   * @tags 优惠券实体表
   * @response `200` `JsonResultPageInfoCouponEntity` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/couponentity/query_GET': (query: AdminApiCouponentityQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/coupon/member/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoCouponEntity>,
  /**
   * No description
   * @name GET /admin/api/couponentity/{id}
   * @summary 详情
   * @tags 优惠券实体表
   * @response `200` `JsonResultCouponEntity` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/couponentity/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/couponentity/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultCouponEntity>,
  /**
   * No description
   * @name PUT /admin/api/couponentity/{id}
   * @summary 修改
   * @tags 优惠券实体表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/couponentity/{id}_PUT': (id: number, t: CouponEntity, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/couponentity/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/couponentity/{id}
   * @summary 删除
   * @tags 优惠券实体表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/couponentity/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/couponentity/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/couponsendlogs
   * @summary 新增
   * @tags 优惠券发放记录
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/couponsendlogs_POST': (t: CouponSendLogs, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/couponsendlogs`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/couponsendlogs
   * @summary 修改
   * @tags 优惠券发放记录
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/couponsendlogs_PUT': (t: CouponSendLogs, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/couponsendlogs`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/couponsendlogs/query
   * @summary 优惠券发放记录分页查询
   * @tags 优惠券发放记录
   * @response `200` `JsonResultPageInfoCouponSendLogsVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/couponsendlogs/query_GET': (query: AdminApiCouponsendlogsQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/coupon/grant/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoCouponSendLogsVo>,
  /**
   * No description
   * @name GET /admin/api/couponsendlogs/{id}
   * @summary 详情
   * @tags 优惠券发放记录
   * @response `200` `JsonResultCouponSendLogs` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/couponsendlogs/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/couponsendlogs/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultCouponSendLogs>,
  /**
   * No description
   * @name PUT /admin/api/couponsendlogs/{id}
   * @summary 修改
   * @tags 优惠券发放记录
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/couponsendlogs/{id}_PUT': (id: number, t: CouponSendLogs, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/couponsendlogs/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/couponsendlogs/{id}
   * @summary 删除
   * @tags 优惠券发放记录
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/couponsendlogs/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/couponsendlogs/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/couponsendlogsinfo
   * @summary 新增
   * @tags 优惠券发放记录详情
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/couponsendlogsinfo_POST': (t: CouponSendLogsInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/couponsendlogsinfo`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/couponsendlogsinfo
   * @summary 修改
   * @tags 优惠券发放记录详情
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/couponsendlogsinfo_PUT': (t: CouponSendLogsInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/couponsendlogsinfo`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/couponsendlogsinfo/export
   * @summary 优惠券发放记录详情导出
   * @tags 优惠券发放记录详情
   * @response `200` `void` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/couponsendlogsinfo/export_GET': (query: AdminApiCouponsendlogsinfoExportGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/couponsendlogsinfo/export`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<void>,
  /**
   * No description
   * @name GET /admin/api/couponsendlogsinfo/query
   * @summary 优惠券发放记录详情分页查询
   * @tags 优惠券发放记录详情
   * @response `200` `JsonResultPageInfoCouponSendLogsInfo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/couponsendlogsinfo/query_GET': (query: AdminApiCouponsendlogsinfoQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/coupon/grant/item/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoCouponSendLogsInfo>,
  /**
   * No description
   * @name GET /admin/api/couponsendlogsinfo/{id}
   * @summary 详情
   * @tags 优惠券发放记录详情
   * @response `200` `JsonResultCouponSendLogsInfo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/couponsendlogsinfo/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/couponsendlogsinfo/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultCouponSendLogsInfo>,
  /**
   * No description
   * @name PUT /admin/api/couponsendlogsinfo/{id}
   * @summary 修改
   * @tags 优惠券发放记录详情
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/couponsendlogsinfo/{id}_PUT': (id: number, t: CouponSendLogsInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/couponsendlogsinfo/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/couponsendlogsinfo/{id}
   * @summary 删除
   * @tags 优惠券发放记录详情
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/couponsendlogsinfo/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/couponsendlogsinfo/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/datastatistics/exportGoodsSaleStatistics
   * @summary 商品销售统计导出
   * @tags 数据统计
   * @response `200` `void` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/datastatistics/exportGoodsSaleStatistics_GET': (query: AdminApiDatastatisticsExportGoodsSaleStatisticsGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/datastatistics/exportGoodsSaleStatistics`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<void>,
  /**
   * No description
   * @name GET /admin/api/datastatistics/exportMemberPayStatistics
   * @summary 用户消费统计导出
   * @tags 数据统计
   * @response `200` `void` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/datastatistics/exportMemberPayStatistics_GET': (query: AdminApiDatastatisticsExportMemberPayStatisticsGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/datastatistics/exportMemberPayStatistics`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<void>,
  /**
   * No description
   * @name GET /admin/api/data/statistics/consume/goods
   * @summary 商品销售统计
   * @tags 数据统计
   * @response `200` `JsonResultPageInfoGoodsStatisticsDetailVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/data/statistics/consume/goods_GET': (query: AdminApiDatastatisticsGoodsSaleStatisticsGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/data/statistics/consume/goods`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoGoodsStatisticsDetailVo>,
  /**
   * No description
   * @name GET /admin/api/datastatistics/queryGoodsOrderNumber
   * @summary 商品销售统计下单量
   * @tags 数据统计
   * @response `200` `JsonResultInt` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/datastatistics/queryGoodsOrderNumber_GET': (query: AdminApiDatastatisticsQueryGoodsOrderNumberGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/datastatistics/queryGoodsOrderNumber`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultInt>,
  /**
   * No description
   * @name GET /admin/api/datastatistics/queryGoodsSaleStatisticsDetail
   * @summary 商品销售统计详情
   * @tags 数据统计
   * @response `200` `JsonResultListGoodsSkuStatisticsDetailVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/datastatistics/queryGoodsSaleStatisticsDetail_GET': (
    query: AdminApiDatastatisticsQueryGoodsSaleStatisticsDetailGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/datastatistics/queryGoodsSaleStatisticsDetail`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListGoodsSkuStatisticsDetailVo>,
  /**
   * No description
   * @name GET /admin/api/datastatistics/queryMemberOrderAmount
   * @summary 用户消费金额
   * @tags 数据统计
   * @response `200` `JsonResultBigdecimal` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/datastatistics/queryMemberOrderAmount_GET': (query: AdminApiDatastatisticsQueryMemberOrderAmountGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/data/statistics/consume/user/count`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBigdecimal>,
  /**
   * No description
   * @name GET /admin/api/datastatistics/queryMemberPayStatistics
   * @summary 用户消费统计
   * @tags 数据统计
   * @response `200` `JsonResultPageInfoMemberPayStatisticsDetailVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/datastatistics/queryMemberPayStatistics_GET': (query: AdminApiDatastatisticsQueryMemberPayStatisticsGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/data/statistics/consume/user`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMemberPayStatisticsDetailVo>,
  /**
   * No description
   * @name POST /admin/api/diamondregion
   * @summary 新增
   * @tags 金刚区
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/diamondregion_POST': (t: DiamondRegion, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/diamondregion`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/diamondregion
   * @summary 修改
   * @tags 金刚区
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/diamondregion_PUT': (t: DiamondRegion, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/diamondregion`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/diamondregion/addDiamondRegion
   * @summary 新增金刚区
   * @tags 金刚区
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/diamondregion/addDiamondRegion_POST': (dto: DiamondRegionDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/diamondregion/addDiamondRegion`,
      method: 'POST',
      data: dto,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name DELETE /admin/api/diamondregion/delete/{id}
   * @summary 删除金刚区
   * @tags 金刚区
   * @response `200` `JsonResult` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/diamondregion/delete/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/diamondregion/delete/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/diamondregion/query
   * @summary 分页查询金刚区
   * @tags 金刚区
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/diamondregion/query_GET': (query: AdminApiDiamondregionQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/diamondregion/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name PUT /admin/api/diamondregion/update
   * @summary 修改金刚区
   * @tags 金刚区
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/diamondregion/update_PUT': (dto: DiamondRegionDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/diamondregion/update`,
      method: 'PUT',
      data: dto,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * @description id：主键id ,useStatus: 状态 0 禁用 1启用
   * @name POST /admin/api/diamondregion/updateDiamondRegionStatus
   * @summary 金刚区启用/禁用
   * @tags 金刚区
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/diamondregion/updateDiamondRegionStatus_POST': (dto: DiamondRegionDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/diamondregion/updateDiamondRegionStatus`,
      method: 'POST',
      data: dto,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/diamondregion/{id}
   * @summary 详情
   * @tags 金刚区
   * @response `200` `JsonResultDiamondRegion` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/diamondregion/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/diamondregion/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultDiamondRegion>,
  /**
   * No description
   * @name PUT /admin/api/diamondregion/{id}
   * @summary 修改
   * @tags 金刚区
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/diamondregion/{id}_PUT': (id: number, t: DiamondRegion, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/diamondregion/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/diamondregion/{id}
   * @summary 删除
   * @tags 金刚区
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/diamondregion/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/diamondregion/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/doorplate
   * @summary 新增
   * @tags 门牌号管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/doorplate_POST': (t: DoorPlate, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/doorplate`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/doorplate
   * @summary 修改
   * @tags 门牌号管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/doorplate_PUT': (t: DoorPlate, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/doorplate`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/doorplate/addDoorPlate
   * @summary 新增门牌号
   * @tags 门牌号管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/doorplate/addDoorPlate_POST': (entity: DoorPlateDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/doorplate/addDoorPlate`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/api/doorplate/associateForHouseType
   * @summary 户型关联门牌号
   * @tags 门牌号管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/doorplate/associateForHouseType_POST': (doorPlateDtos: DoorPlateDto[], options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/doorplate/associateForHouseType`,
      method: 'POST',
      data: doorPlateDtos,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name DELETE /admin/api/doorplate/deleteDoorPlate
   * @summary 删除门牌号
   * @tags 门牌号管理
   * @response `200` `JsonResult` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/doorplate/deleteDoorPlate_DELETE': (query: AdminApiDoorplateDeleteDoorPlateDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/doorplate/deleteDoorPlate`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/api/doorplate/importDoorPlateInfo
   * @summary 导入门牌号信息
   * @tags 门牌号管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/doorplate/importDoorPlateInfo_POST': (file: any, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/doorplate/importDoorPlateInfo`,
      method: 'POST',
      data: file,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/doorplate/query
   * @summary 分页查询
   * @tags 门牌号管理
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/doorplate/query_GET': (query: AdminApiDoorplateQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/doorplate/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/doorplate/queryDoorPlateInfo
   * @summary 分页查询门牌列表
   * @tags 门牌号管理
   * @response `200` `JsonResultPageInfoDoorPlateVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/doorplate/queryDoorPlateInfo_GET': (query: AdminApiDoorplateQueryDoorPlateInfoGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/doorplate/queryDoorPlateInfo`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoDoorPlateVo>,
  /**
   * No description
   * @name GET /admin/api/doorplate/queryDoorPlateInfoBeyondHouseType
   * @summary 分页查询未关联当前户型的门牌
   * @tags 门牌号管理
   * @response `200` `JsonResultPageInfoDoorPlateVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/doorplate/queryDoorPlateInfoBeyondHouseType_GET': (
    query: AdminApiDoorplateQueryDoorPlateInfoBeyondHouseTypeGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/doorplate/queryDoorPlateInfoBeyondHouseType`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoDoorPlateVo>,
  /**
   * No description
   * @name GET /admin/api/doorplate/queryDoorPlateInfoList
   * @summary 查询小区和楼栋下门牌号
   * @tags 门牌号管理
   * @response `200` `JsonResultListDoorPlate` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/doorplate/queryDoorPlateInfoList_GET': (query: AdminApiDoorplateQueryDoorPlateInfoListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/doorplate/queryDoorPlateInfoList`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListDoorPlate>,
  /**
   * No description
   * @name PUT /admin/api/doorplate/removeAssociateForHouseType
   * @summary 删除户型门牌关联关系
   * @tags 门牌号管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/doorplate/removeAssociateForHouseType_PUT': (entity: DoorPlateDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/doorplate/removeAssociateForHouseType`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name PUT /admin/api/doorplate/updateDoorPlate
   * @summary 编辑门牌号
   * @tags 门牌号管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/doorplate/updateDoorPlate_PUT': (entity: DoorPlateDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/doorplate/updateDoorPlate`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/doorplate/{id}
   * @summary 详情
   * @tags 门牌号管理
   * @response `200` `JsonResultDoorPlate` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/doorplate/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/doorplate/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultDoorPlate>,
  /**
   * No description
   * @name PUT /admin/api/doorplate/{id}
   * @summary 修改
   * @tags 门牌号管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/doorplate/{id}_PUT': (id: number, t: DoorPlate, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/doorplate/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/doorplate/{id}
   * @summary 删除
   * @tags 门牌号管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/doorplate/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/doorplate/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/eventprizemanagement
   * @summary 新增
   * @tags 抽奖活动奖品管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/eventprizemanagement_POST': (t: EventPrizeManagement, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/eventprizemanagement`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/eventprizemanagement
   * @summary 修改
   * @tags 抽奖活动奖品管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/eventprizemanagement_PUT': (t: EventPrizeManagement, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/eventprizemanagement`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/eventprizemanagement/getEffectivePrizeList
   * @summary 根据活动id查询有效的奖品集合
   * @tags 抽奖活动奖品管理
   * @response `200` `JsonResultListEventPrizeManagement` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/eventprizemanagement/getEffectivePrizeList_GET': (
    query: AdminApiEventprizemanagementGetEffectivePrizeListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/eventprizemanagement/list`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListEventPrizeManagement>,
  /**
   * No description
   * @name GET /admin/api/eventprizemanagement/query
   * @summary 分页查询
   * @tags 抽奖活动奖品管理
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/eventprizemanagement/query_GET': (query: AdminApiEventprizemanagementQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/eventprizemanagement/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/eventprizemanagement/{id}
   * @summary 详情
   * @tags 抽奖活动奖品管理
   * @response `200` `JsonResultEventPrizeManagement` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/eventprizemanagement/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/eventprizemanagement/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultEventPrizeManagement>,
  /**
   * No description
   * @name PUT /admin/api/eventprizemanagement/{id}
   * @summary 修改
   * @tags 抽奖活动奖品管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/eventprizemanagement/{id}_PUT': (id: number, t: EventPrizeManagement, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/eventprizemanagement/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/eventprizemanagement/{id}
   * @summary 删除
   * @tags 抽奖活动奖品管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/eventprizemanagement/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/eventprizemanagement/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,

  /**
   * No description
   * @name POST /admin/api/family
   * @summary 新增
   * @tags 家庭实体管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/family_POST': (t: Family, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/family`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/family
   * @summary 修改
   * @tags 家庭实体管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/family_PUT': (t: Family, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/family`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/family/query
   * @summary 分页查询
   * @tags 家庭实体管理
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/family/query_GET': (query: AdminApiFamilyQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/family/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/family/{id}
   * @summary 详情
   * @tags 家庭实体管理
   * @response `200` `JsonResultFamily` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/family/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/family/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultFamily>,
  /**
   * No description
   * @name PUT /admin/api/family/{id}
   * @summary 修改
   * @tags 家庭实体管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/family/{id}_PUT': (id: number, t: Family, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/family/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/family/{id}
   * @summary 删除
   * @tags 家庭实体管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/family/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/family/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/familyinfo
   * @summary 新增
   * @tags 家庭信息
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/familyinfo_POST': (t: FamilyInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/familyinfo`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/familyinfo
   * @summary 修改
   * @tags 家庭信息
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/familyinfo_PUT': (t: FamilyInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/familyinfo`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/familyinfo/query
   * @summary 家庭信息分页查询
   * @tags 家庭信息
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/familyinfo/query_GET': (query: AdminApiFamilyinfoQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/familyinfo/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/familyinfo/{id}
   * @summary 详情
   * @tags 家庭信息
   * @response `200` `JsonResultFamilyInfo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/familyinfo/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/familyinfo/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultFamilyInfo>,
  /**
   * No description
   * @name PUT /admin/api/familyinfo/{id}
   * @summary 修改
   * @tags 家庭信息
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/familyinfo/{id}_PUT': (id: number, t: FamilyInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/familyinfo/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/familyinfo/{id}
   * @summary 删除
   * @tags 家庭信息
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/familyinfo/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/familyinfo/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/familyrelationship
   * @summary 新增
   * @tags 家庭成员关系表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/familyrelationship_POST': (t: FamilyRelationship, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/familyrelationship`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/familyrelationship
   * @summary 修改
   * @tags 家庭成员关系表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/familyrelationship_PUT': (t: FamilyRelationship, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/familyrelationship`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/familyrelationship/query
   * @summary 家庭成员关系表分页查询
   * @tags 家庭成员关系表
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/familyrelationship/query_GET': (query: AdminApiFamilyrelationshipQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/familyrelationship/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/familyrelationship/{id}
   * @summary 详情
   * @tags 家庭成员关系表
   * @response `200` `JsonResultFamilyRelationship` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/familyrelationship/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/familyrelationship/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultFamilyRelationship>,
  /**
   * No description
   * @name PUT /admin/api/familyrelationship/{id}
   * @summary 修改
   * @tags 家庭成员关系表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/familyrelationship/{id}_PUT': (id: number, t: FamilyRelationship, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/familyrelationship/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/familyrelationship/{id}
   * @summary 删除
   * @tags 家庭成员关系表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/familyrelationship/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/familyrelationship/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/feedback
   * @summary 新增
   * @tags 意见反馈
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/feedback_POST': (t: Feedback, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/feedback`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/feedback
   * @summary 修改
   * @tags 意见反馈
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/feedback_PUT': (t: Feedback, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/feedback`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/feedback/query
   * @summary 意见反馈分页查询
   * @tags 意见反馈
   * @response `200` `JsonResultPageInfoFeedback` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/feedback/query_GET': (query: AdminApiFeedbackQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/feedback/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoFeedback>,
  /**
   * No description
   * @name GET /admin/api/feedback/{id}
   * @summary 详情
   * @tags 意见反馈
   * @response `200` `JsonResultFeedback` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/feedback/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/feedback/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultFeedback>,
  /**
   * No description
   * @name PUT /admin/api/feedback/{id}
   * @summary 修改
   * @tags 意见反馈
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/feedback/{id}_PUT': (id: number, t: Feedback, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/feedback/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/feedback/{id}
   * @summary 删除
   * @tags 意见反馈
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/feedback/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/feedback/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/goods
   * @summary 新增
   * @tags 商品实体表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/goods_POST': (t: Goods, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/goods`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/goods
   * @summary 修改
   * @tags 商品实体表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/goods_PUT': (t: Goods, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/goods`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,

  /**
   * No description
   * @name GET /admin/api/goods/{id}
   * @summary 详情
   * @tags 商品实体表
   * @response `200` `JsonResultGoods` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/goods/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/goods/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultGoods>,
  /**
   * No description
   * @name PUT /admin/api/goods/{id}
   * @summary 修改
   * @tags 商品实体表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/goods/{id}_PUT': (id: number, t: Goods, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/goods/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/goods/{id}
   * @summary 删除
   * @tags 商品实体表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/goods/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/goods/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/goodsclassify
   * @summary 新增
   * @tags 商品分类
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/goodsclassify_POST': (t: GoodsClassify, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/goodsclassify`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/goodsclassify
   * @summary 修改
   * @tags 商品分类
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/goodsclassify_PUT': (t: GoodsClassify, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/goodsclassify`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/goodsclassify/addGoodsClassify
   * @summary 新增商品分类
   * @tags 商品分类
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/goodsclassify/addGoodsClassify_POST': (entity: GoodsClassifyDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mall/goods/classify`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/api/goodsclassify/deleteGoodsClassify/{id}
   * @summary 删除商品分类
   * @tags 商品分类
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/goodsclassify/deleteGoodsClassify/{id}_DELETE': (query, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mall/goods/classify`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/goodsclassify/queryGoodsClassifyList
   * @summary 查询商品分类
   * @tags 商品分类
   * @response `200` `JsonResultListGoodsClassifyVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/goodsclassify/queryGoodsClassifyList_GET': (query: AdminApiGoodsclassifyQueryGoodsClassifyListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mall/goods/classify/tree`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListGoodsClassifyVo>,
  /**
   * No description
   * @name PUT /admin/api/goodsclassify/updateGoodsClassify
   * @summary 编辑商品分类
   * @tags 商品分类
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/goodsclassify/updateGoodsClassify_PUT': (entity: GoodsClassifyDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mall/goods/classify`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/goodsclassify/{id}
   * @summary 详情
   * @tags 商品分类
   * @response `200` `JsonResultGoodsClassify` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/goodsclassify/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/goodsclassify/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultGoodsClassify>,
  /**
   * No description
   * @name PUT /admin/api/goodsclassify/{id}
   * @summary 修改
   * @tags 商品分类
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/goodsclassify/{id}_PUT': (id: number, t: GoodsClassify, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/goodsclassify/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/goodsclassify/{id}
   * @summary 删除
   * @tags 商品分类
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/goodsclassify/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/goodsclassify/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/goodssku
   * @summary 新增
   * @tags 商品sku表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/goodssku_POST': (t: GoodsSku, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/goodssku`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/goodssku
   * @summary 修改
   * @tags 商品sku表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/goodssku_PUT': (t: GoodsSku, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/goodssku`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/goodssku/query
   * @summary 商品sku表分页查询
   * @tags 商品sku表
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/goodssku/query_GET': (query: AdminApiGoodsskuQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/goodssku/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/goodssku/{id}
   * @summary 详情
   * @tags 商品sku表
   * @response `200` `JsonResultGoodsSku` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/goodssku/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/goodssku/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultGoodsSku>,
  /**
   * No description
   * @name PUT /admin/api/goodssku/{id}
   * @summary 修改
   * @tags 商品sku表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/goodssku/{id}_PUT': (id: number, t: GoodsSku, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/goodssku/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/goodssku/{id}
   * @summary 删除
   * @tags 商品sku表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/goodssku/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/goodssku/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/growthconfig
   * @summary 新增
   * @tags 成长值配置
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/growthconfig_POST': (t: GrowthConfig, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/growthconfig`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/growthconfig
   * @summary 修改
   * @tags 成长值配置
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/growthconfig_PUT': (t: GrowthConfig, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/growthconfig`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/growthconfig/query
   * @summary 分页查询
   * @tags 成长值配置
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/growthconfig/query_GET': (query: AdminApiGrowthconfigQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/growthconfig/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/growthconfig/queryGrowthConfig
   * @summary 查询成长值配置
   * @tags 成长值配置
   * @response `200` `JsonResultGrowthConfig` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/growthconfig/queryGrowthConfig_GET': (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/growthconfig/queryGrowthConfig`,
      method: 'GET',
      params,
      ...options
    }) as unknown as Promise<JsonResultGrowthConfig>,
  /**
   * No description
   * @name POST /admin/api/growthconfig/updateGrowthConfig
   * @summary 编辑/保存成长值配置
   * @tags 成长值配置
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/growthconfig/updateGrowthConfig_POST': (entity: GrowthConfigDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/growthconfig/updateGrowthConfig`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/growthconfig/{id}
   * @summary 详情
   * @tags 成长值配置
   * @response `200` `JsonResultGrowthConfig` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/growthconfig/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/growthconfig/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultGrowthConfig>,
  /**
   * No description
   * @name PUT /admin/api/growthconfig/{id}
   * @summary 修改
   * @tags 成长值配置
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/growthconfig/{id}_PUT': (id: number, t: GrowthConfig, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/growthconfig/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/growthconfig/{id}
   * @summary 删除
   * @tags 成长值配置
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/growthconfig/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/growthconfig/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/growthlog
   * @summary 新增
   * @tags 成长值日志
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/growthlog_POST': (t: GrowthLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/growthlog`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/growthlog
   * @summary 修改
   * @tags 成长值日志
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/growthlog_PUT': (t: GrowthLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/growthlog`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/growthlog/query
   * @summary 成长值日志分页查询
   * @tags 成长值日志
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/growthlog/query_GET': (query: AdminApiGrowthlogQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/growthlog/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/growthlog/{id}
   * @summary 详情
   * @tags 成长值日志
   * @response `200` `JsonResultGrowthLog` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/growthlog/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/growthlog/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultGrowthLog>,
  /**
   * No description
   * @name PUT /admin/api/growthlog/{id}
   * @summary 修改
   * @tags 成长值日志
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/growthlog/{id}_PUT': (id: number, t: GrowthLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/growthlog/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/growthlog/{id}
   * @summary 删除
   * @tags 成长值日志
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/growthlog/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/growthlog/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/housetype
   * @summary 新增
   * @tags 户型管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/housetype_POST': (t: HouseType, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/housetype`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/housetype
   * @summary 修改
   * @tags 户型管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/housetype_PUT': (t: HouseType, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/housetype`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/housetype/addHouseType
   * @summary 添加户型
   * @tags 户型管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/housetype/addHouseType_POST': (entity: HouseTypeDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/housetype/addHouseType`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name DELETE /admin/api/housetype/deleteHouseType
   * @summary 删除户型
   * @tags 户型管理
   * @response `200` `JsonResult` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/housetype/deleteHouseType_DELETE': (query: AdminApiHousetypeDeleteHouseTypeDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/housetype/deleteHouseType`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/api/housetype/importHouseType
   * @summary 导入户型
   * @tags 户型管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/housetype/importHouseType_POST': (file: any, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/housetype/importHouseType`,
      method: 'POST',
      data: file,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/api/housetype/importRelationDoorPlateInfoExcel
   * @summary 导入关联门牌号
   * @tags 户型管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/housetype/importRelationDoorPlateInfoExcel_POST': (file: any, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/housetype/importRelationDoorPlateInfoExcel`,
      method: 'POST',
      data: file,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/housetype/query
   * @summary 分页查询
   * @tags 户型管理
   * @response `200` `JsonResultPageInfoHouseType` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/housetype/query_GET': (query: AdminApiHousetypeQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/housetype/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoHouseType>,
  /**
   * No description
   * @name GET /admin/api/housetype/queryHousePage
   * @summary 分页查询户型
   * @tags 户型管理
   * @response `200` `JsonResultPageInfoHouseTypeVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/housetype/queryHousePage_GET': (query: AdminApiHousetypeQueryHousePageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/housetype/queryHousePage`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoHouseTypeVo>,
  /**
   * No description
   * @name PUT /admin/api/housetype/updateHouseType
   * @summary 修改户型
   * @tags 户型管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/housetype/updateHouseType_PUT': (entity: HouseTypeDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/housetype/updateHouseType`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/housetype/{id}
   * @summary 详情
   * @tags 户型管理
   * @response `200` `JsonResultHouseType` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/housetype/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/housetype/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultHouseType>,
  /**
   * No description
   * @name PUT /admin/api/housetype/{id}
   * @summary 修改
   * @tags 户型管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/housetype/{id}_PUT': (id: number, t: HouseType, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/housetype/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/housetype/{id}
   * @summary 删除
   * @tags 户型管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/housetype/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/housetype/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/identityauthenticationmanage
   * @summary 新增
   * @tags 认证审核
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/identityauthenticationmanage_POST': (t: IdentityAuthenticationManage, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/identityauthenticationmanage`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/identityauthenticationmanage
   * @summary 修改
   * @tags 认证审核
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/identityauthenticationmanage_PUT': (t: IdentityAuthenticationManage, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/identityauthenticationmanage`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/identityauthenticationmanage/audit
   * @summary 认证审核
   * @tags 认证审核
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/identityauthenticationmanage/audit_POST': (dto: IdentityAuthenticationManageDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/identityauthenticationmanage/audit`,
      method: 'POST',
      data: dto,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/identityauthenticationmanage/query
   * @summary 分页查询
   * @tags 认证审核
   * @response `200` `JsonResultPageInfoIdentityAuthenticationManage` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/identityauthenticationmanage/query_GET': (query: AdminApiIdentityauthenticationmanageQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/identityauthenticationmanage/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoIdentityAuthenticationManage>,
  /**
   * No description
   * @name GET /admin/api/identityauthenticationmanage/queryAuditInfo/{id}
   * @summary 认证信息列表
   * @tags 认证审核
   * @response `200` `JsonResultListIdentityAuthenticationManageVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/identityauthenticationmanage/queryAuditInfo/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/identityauthenticationmanage/queryAuditInfo/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultListIdentityAuthenticationManageVo>,
  /**
   * No description
   * @name GET /admin/api/identityauthenticationmanage/queryPage
   * @summary 分页查询认证审核列表
   * @tags 认证审核
   * @response `200` `JsonResultPageInfoIdentityAuthenticationManageVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/identityauthenticationmanage/queryPage_GET': (query: AdminApiIdentityauthenticationmanageQueryPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/identityauthenticationmanage/queryPage`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoIdentityAuthenticationManageVo>,
  /**
   * No description
   * @name GET /admin/api/identityauthenticationmanage/{id}
   * @summary 详情
   * @tags 认证审核
   * @response `200` `JsonResultIdentityAuthenticationManage` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/identityauthenticationmanage/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/identityauthenticationmanage/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultIdentityAuthenticationManage>,
  /**
   * No description
   * @name PUT /admin/api/identityauthenticationmanage/{id}
   * @summary 修改
   * @tags 认证审核
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/identityauthenticationmanage/{id}_PUT': (id: number, t: IdentityAuthenticationManage, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/identityauthenticationmanage/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/identityauthenticationmanage/{id}
   * @summary 删除
   * @tags 认证审核
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/identityauthenticationmanage/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/identityauthenticationmanage/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/information
   * @summary 新增
   * @tags 资讯管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/information_POST': (t: Information, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/information`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/information
   * @summary 修改
   * @tags 资讯管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/information_PUT': (t: Information, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/information`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/information/addInfo
   * @summary 资讯新增
   * @tags 资讯管理
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/information/addInfo_POST': (entity: InformationDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/information/addInfo`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/api/information/deleteInfo/{id}
   * @summary 资讯删除
   * @tags 资讯管理
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/information/deleteInfo/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/information/deleteInfo/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/information/query
   * @summary 资讯管理分页查询
   * @tags 资讯管理
   * @response `200` `JsonResultPageInfoInformationVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/information/query_GET': (query: AdminApiInformationQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/information/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoInformationVo>,


    '/admin/api/information/stat/total_GET': (query: AdminApiInformationQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/information/stat/total`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoInformationVo>,

    '/admin/api/information/stat/topTen_GET': (query: AdminApiInformationQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/information/stat/topTen`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoInformationVo>,

    '/admin/api/information/stat/chart_GET': (query, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/information/stat/chart`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoInformationVo>,
  /**
   * No description
   * @name PUT /admin/api/information/updateInfo
   * @summary 资讯编辑
   * @tags 资讯管理
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/information/updateInfo_PUT': (entity: InformationDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/information/updateInfo`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/information/{id}
   * @summary 详情
   * @tags 资讯管理
   * @response `200` `JsonResultInformation` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/information/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/information/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultInformation>,
  /**
   * No description
   * @name PUT /admin/api/information/{id}
   * @summary 修改
   * @tags 资讯管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/information/{id}_PUT': (id: number, t: Information, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/information/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/information/{id}
   * @summary 删除
   * @tags 资讯管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/information/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/information/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/informationClassify
   * @summary 新增
   * @tags 资讯分类管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/informationClassify_POST': (t: InformationClassify, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/informationClassify`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/informationClassify
   * @summary 修改
   * @tags 资讯分类管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/informationClassify_PUT': (t: InformationClassify, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/informationClassify`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/informationClassify/addInfo
   * @summary 资讯分类新增
   * @tags 资讯分类管理
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/informationClassify/addInfo_POST': (entity: InformationClassifyDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/informationClassify/addInfo`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/api/informationClassify/deleteInfo/{id}
   * @summary 资讯分类删除
   * @tags 资讯分类管理
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/informationClassify/deleteInfo/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/informationClassify/deleteInfo/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/informationClassify/queryList
   * @summary 资讯分类管理查询
   * @tags 资讯分类管理
   * @response `200` `JsonResultListInformationClassify` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/informationClassify/queryList_GET': (query: AdminApiInformationClassifyQueryListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/information/classify/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListInformationClassify>,
  /**
   * No description
   * @name GET /admin/api/informationClassify/queryPage
   * @summary 资讯分类管理分页查询
   * @tags 资讯分类管理
   * @response `200` `JsonResultPageInfoInformationClassify` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/informationClassify/queryPage_GET': (query: AdminApiInformationClassifyQueryPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/informationClassify/queryPage`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoInformationClassify>,
  /**
   * No description
   * @name PUT /admin/api/informationClassify/updateInfo
   * @summary 资讯分类编辑
   * @tags 资讯分类管理
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/informationClassify/updateInfo_PUT': (entity: InformationClassifyDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/informationClassify/updateInfo`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/informationClassify/{id}
   * @summary 详情
   * @tags 资讯分类管理
   * @response `200` `JsonResultInformationClassify` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/informationClassify/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/informationClassify/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultInformationClassify>,
  /**
   * No description
   * @name PUT /admin/api/informationClassify/{id}
   * @summary 修改
   * @tags 资讯分类管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/informationClassify/{id}_PUT': (id: number, t: InformationClassify, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/informationClassify/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/informationClassify/{id}
   * @summary 删除
   * @tags 资讯分类管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/informationClassify/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/informationClassify/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/integralconfig
   * @summary 新增
   * @tags 积分配置
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/integralconfig_POST': (t: IntegralConfig, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/integralconfig`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/integralconfig
   * @summary 修改
   * @tags 积分配置
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/integralconfig_PUT': (t: IntegralConfig, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/integralconfig`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/integralconfig/query
   * @summary 分页查询
   * @tags 积分配置
   * @response `200` `JsonResultIntegralConfig` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/integralconfig/query_GET': (query: AdminApiIntegralconfigQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/integralconfig/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultIntegralConfig>,
  /**
   * No description
   * @name POST /admin/api/integralconfig/updateIntegralConfig
   * @summary 编辑/新增积分配置
   * @tags 积分配置
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/integralconfig/updateIntegralConfig_POST': (entity: IntegralConfigDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/integralconfig/updateIntegralConfig`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/integralconfig/{id}
   * @summary 详情
   * @tags 积分配置
   * @response `200` `JsonResultIntegralConfig` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/integralconfig/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/integralconfig/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultIntegralConfig>,
  /**
   * No description
   * @name PUT /admin/api/integralconfig/{id}
   * @summary 修改
   * @tags 积分配置
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/integralconfig/{id}_PUT': (id: number, t: IntegralConfig, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/integralconfig/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/integralconfig/{id}
   * @summary 删除
   * @tags 积分配置
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/integralconfig/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/integralconfig/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/integrallog
   * @summary 新增
   * @tags 积分日志
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/integrallog_POST': (t: IntegralLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/integrallog`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/integrallog
   * @summary 修改
   * @tags 积分日志
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/integrallog_PUT': (t: IntegralLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/integrallog`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/integrallog/addIntegralLog
   * @summary 新增日志
   * @tags 积分日志
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/integrallog/addIntegralLog_POST': (entity: IntegralLogDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/integrallog/addIntegralLog`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/integrallog/query
   * @summary 分页查询
   * @tags 积分日志
   * @deprecated
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/integrallog/query_GET': (query: AdminApiIntegrallogQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/integrallog/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/integrallog/queryPage
   * @summary 分页查询积分日志
   * @tags 积分日志
   * @response `200` `JsonResultPageInfoIntegralLogVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/integrallog/queryPage_GET': (query: AdminApiIntegrallogQueryPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/integrallog/queryPage`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoIntegralLogVo>,
  /**
   * No description
   * @name GET /admin/api/integrallog/{id}
   * @summary 详情
   * @tags 积分日志
   * @response `200` `JsonResultIntegralLog` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/integrallog/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/integrallog/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultIntegralLog>,
  /**
   * No description
   * @name PUT /admin/api/integrallog/{id}
   * @summary 修改
   * @tags 积分日志
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/integrallog/{id}_PUT': (id: number, t: IntegralLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/integrallog/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/integrallog/{id}
   * @summary 删除
   * @tags 积分日志
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/integrallog/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/integrallog/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/intro
   * @summary 新增
   * @tags 商品详情-富文本
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/intro_POST': (t: Intro, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/intro`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/intro
   * @summary 修改
   * @tags 商品详情-富文本
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/intro_PUT': (t: Intro, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/intro`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/intro/query
   * @summary 商品详情-富文本分页查询
   * @tags 商品详情-富文本
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/intro/query_GET': (query: AdminApiIntroQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/intro/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/intro/{id}
   * @summary 详情
   * @tags 商品详情-富文本
   * @response `200` `JsonResultIntro` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/intro/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/intro/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultIntro>,
  /**
   * No description
   * @name PUT /admin/api/intro/{id}
   * @summary 修改
   * @tags 商品详情-富文本
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/intro/{id}_PUT': (id: number, t: Intro, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/intro/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/intro/{id}
   * @summary 删除
   * @tags 商品详情-富文本
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/intro/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/intro/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/invitefamilyrecord
   * @summary 新增
   * @tags 家庭邀请记录表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/invitefamilyrecord_POST': (t: InviteFamilyRecord, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/invitefamilyrecord`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/invitefamilyrecord
   * @summary 修改
   * @tags 家庭邀请记录表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/invitefamilyrecord_PUT': (t: InviteFamilyRecord, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/invitefamilyrecord`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/invitefamilyrecord/query
   * @summary 家庭邀请记录表分页查询
   * @tags 家庭邀请记录表
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/invitefamilyrecord/query_GET': (query: AdminApiInvitefamilyrecordQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/invitefamilyrecord/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/invitefamilyrecord/{id}
   * @summary 详情
   * @tags 家庭邀请记录表
   * @response `200` `JsonResultInviteFamilyRecord` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/invitefamilyrecord/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/invitefamilyrecord/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultInviteFamilyRecord>,
  /**
   * No description
   * @name PUT /admin/api/invitefamilyrecord/{id}
   * @summary 修改
   * @tags 家庭邀请记录表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/invitefamilyrecord/{id}_PUT': (id: number, t: InviteFamilyRecord, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/invitefamilyrecord/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/invitefamilyrecord/{id}
   * @summary 删除
   * @tags 家庭邀请记录表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/invitefamilyrecord/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/invitefamilyrecord/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/luckydrawactivitymanagement
   * @summary 新增
   * @tags 抽奖活动管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/luckydrawactivitymanagement_POST': (t: LuckyDrawActivityManagement, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/luckydrawactivitymanagement`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/luckydrawactivitymanagement
   * @summary 修改
   * @tags 抽奖活动管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/luckydrawactivitymanagement_PUT': (t: LuckyDrawActivityManagement, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/luckydrawactivitymanagement`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/luckydrawactivitymanagement/addLuckyDrawActivity
   * @summary  新增/编辑 抽奖活动
   * @tags 抽奖活动管理
   * @response `200` `JsonResultLong` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/luckydrawactivitymanagement/addLuckyDrawActivity_POST': (entity: LuckyDrawActivityManagementDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/luckydrawactivitymanagement/addLuckyDrawActivity`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultLong>,
  /**
   * No description
   * @name DELETE /admin/api/luckydrawactivitymanagement/deleteLuckyDrawActivity/{id}
   * @summary 删除抽奖活动
   * @tags 抽奖活动管理
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/luckydrawactivitymanagement/deleteLuckyDrawActivity/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/luckydrawactivitymanagement/deleteLuckyDrawActivity/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/luckydrawactivitymanagement/queryLuckyDrawActivityDetail
   * @summary 查询抽奖活动详情
   * @tags 抽奖活动管理
   * @response `200` `JsonResultLuckyDrawActivityManagementDetailVo` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/luckydrawactivitymanagement/queryLuckyDrawActivityDetail_POST': (
    query: AdminApiLuckydrawactivitymanagementQueryLuckyDrawActivityDetailPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/luckydrawactivitymanagement/queryLuckyDrawActivityDetail`,
      method: 'POST',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultLuckyDrawActivityManagementDetailVo>,
  /**
   * No description
   * @name GET /admin/api/luckydrawactivitymanagement/queryPage
   * @summary 分页查询抽奖活动
   * @tags 抽奖活动管理
   * @response `200` `JsonResultPageInfoLuckyDrawActivityManagementVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/luckydrawactivitymanagement/queryPage_GET': (query: AdminApiLuckydrawactivitymanagementQueryPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/luckydrawactivitymanagement/queryPage`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoLuckyDrawActivityManagementVo>,
  /**
   * No description
   * @name POST /admin/api/luckydrawactivitymanagement/updateActivityStatus
   * @summary 编辑抽奖活动状态
   * @tags 抽奖活动管理
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/luckydrawactivitymanagement/updateActivityStatus_POST': (entity: LuckyDrawActivityManagementDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/luckydrawactivitymanagement/updateActivityStatus`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/luckydrawactivitymanagement/updateLuckyDrawActivityStatus
   * @summary 修改抽奖活动状态
   * @tags 抽奖活动管理
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/luckydrawactivitymanagement/updateLuckyDrawActivityStatus_POST': (entity: LuckyDrawActivityManagementDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/luckydrawactivitymanagement/updateLuckyDrawActivityStatus`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/luckydrawactivitymanagement/updateWheelConfig
   * @summary 编辑抽奖轮盘设置
   * @tags 抽奖活动管理
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/luckydrawactivitymanagement/updateWheelConfig_POST': (entity: LuckyDrawActivityManagementDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/luckydrawactivitymanagement/updateWheelConfig`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/luckydrawactivitymanagement/{id}
   * @summary 详情
   * @tags 抽奖活动管理
   * @response `200` `JsonResultLuckyDrawActivityManagement` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/luckydrawactivitymanagement/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/luckydrawactivitymanagement/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultLuckyDrawActivityManagement>,
  /**
   * No description
   * @name PUT /admin/api/luckydrawactivitymanagement/{id}
   * @summary 修改
   * @tags 抽奖活动管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/luckydrawactivitymanagement/{id}_PUT': (id: number, t: LuckyDrawActivityManagement, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/luckydrawactivitymanagement/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/luckydrawactivitymanagement/{id}
   * @summary 删除
   * @tags 抽奖活动管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/luckydrawactivitymanagement/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/luckydrawactivitymanagement/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/luckydrawactivitymembermanagement
   * @summary 新增
   * @tags 抽奖活动-参与用户
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/luckydrawactivitymembermanagement_POST': (t: LuckyDrawActivityMemberManagement, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/luckydrawactivitymembermanagement`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/luckydrawactivitymembermanagement
   * @summary 修改
   * @tags 抽奖活动-参与用户
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/luckydrawactivitymembermanagement_PUT': (t: LuckyDrawActivityMemberManagement, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/luckydrawactivitymembermanagement`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/luckydrawactivitymembermanagement/exportLuckyDrawActivity
   * @summary 导出抽奖活动参与用户
   * @tags 抽奖活动-参与用户
   * @response `200` `void` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/luckydrawactivitymembermanagement/exportLuckyDrawActivity_GET': (
    query: AdminApiLuckydrawactivitymembermanagementExportLuckyDrawActivityGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/luckydrawactivitymembermanagement/exportLuckyDrawActivity`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<void>,
  /**
   * No description
   * @name GET /admin/api/luckydrawactivitymembermanagement/queryLuckyDrawActivityMemberPage
   * @summary 分页查询抽奖活动参与会员
   * @tags 抽奖活动-参与用户
   * @response `200` `JsonResultPageInfoLuckyDrawActivityMemberManagementVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/luckydrawactivitymembermanagement/queryLuckyDrawActivityMemberPage_GET': (
    query: AdminApiLuckydrawactivitymembermanagementQueryLuckyDrawActivityMemberPageGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/luckydrawactivitymembermanagement/queryLuckyDrawActivityMemberPage`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoLuckyDrawActivityMemberManagementVo>,
  /**
   * No description
   * @name GET /admin/api/luckydrawactivitymembermanagement/{id}
   * @summary 详情
   * @tags 抽奖活动-参与用户
   * @response `200` `JsonResultLuckyDrawActivityMemberManagement` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/luckydrawactivitymembermanagement/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/luckydrawactivitymembermanagement/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultLuckyDrawActivityMemberManagement>,
  /**
   * No description
   * @name PUT /admin/api/luckydrawactivitymembermanagement/{id}
   * @summary 修改
   * @tags 抽奖活动-参与用户
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/luckydrawactivitymembermanagement/{id}_PUT': (id: number, t: LuckyDrawActivityMemberManagement, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/luckydrawactivitymembermanagement/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/luckydrawactivitymembermanagement/{id}
   * @summary 删除
   * @tags 抽奖活动-参与用户
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/luckydrawactivitymembermanagement/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/luckydrawactivitymembermanagement/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/luckydrawactivitywheelconfig
   * @summary 新增
   * @tags 抽奖活动轮盘设置管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/luckydrawactivitywheelconfig_POST': (t: LuckyDrawActivityWheelConfig, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/luckydrawactivitywheelconfig`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/luckydrawactivitywheelconfig
   * @summary 修改
   * @tags 抽奖活动轮盘设置管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/luckydrawactivitywheelconfig_PUT': (t: LuckyDrawActivityWheelConfig, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/luckydrawactivitywheelconfig`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/luckydrawactivitywheelconfig/{id}
   * @summary 详情
   * @tags 抽奖活动轮盘设置管理
   * @response `200` `JsonResultLuckyDrawActivityWheelConfig` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/luckydrawactivitywheelconfig/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/luckydrawactivitywheelconfig/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultLuckyDrawActivityWheelConfig>,
  /**
   * No description
   * @name PUT /admin/api/luckydrawactivitywheelconfig/{id}
   * @summary 修改
   * @tags 抽奖活动轮盘设置管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/luckydrawactivitywheelconfig/{id}_PUT': (id: number, t: LuckyDrawActivityWheelConfig, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/luckydrawactivitywheelconfig/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/luckydrawactivitywheelconfig/{id}
   * @summary 删除
   * @tags 抽奖活动轮盘设置管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/luckydrawactivitywheelconfig/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/luckydrawactivitywheelconfig/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/mallConfPage/add
   * @summary 自定义页面新增
   * @tags 店铺导航（商户创建时需要默认创建三个）
   * @response `200` `JsonResultLong` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/mallConfPage/add_POST': (entity: MallConfPageAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfPage/add`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultLong>,
  /**
   * No description
   * @name DELETE /admin/api/mallConfPage/delete
   * @summary 自定义页面删除
   * @tags 店铺导航（商户创建时需要默认创建三个）
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/mallConfPage/delete_DELETE': (query: AdminApiMallConfPageDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfPage/delete`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/mallConfPage/detail
   * @summary 自定义页面内容详情
   * @tags 店铺导航（商户创建时需要默认创建三个）
   * @response `200` `JsonResultMallConfPageVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/mallConfPage/detail_GET': (query: AdminApiMallConfPageDetailGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfPage/detail`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMallConfPageVo>,
  /**
   * No description
   * @name GET /admin/api/mallConfPage/query
   * @summary 自定义页面分页查询
   * @tags 店铺导航（商户创建时需要默认创建三个）
   * @response `200` `JsonResultPageInfoMallConfPageVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/mallConfPage/query_GET': (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfPage/query`,
      method: 'GET',
      params,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallConfPageVo>,
  /**
   * No description
   * @name GET /admin/api/mallConfPage/queryAll
   * @summary 自定义页面不分页查询
   * @tags 店铺导航（商户创建时需要默认创建三个）
   * @response `200` `JsonResultListMallConfPageVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/mallConfPage/queryAll_GET': (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfPage/queryAll`,
      method: 'GET',
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfPageVo>,
  /**
   * No description
   * @name PUT /admin/api/mallConfPage/update
   * @summary 自定义页面修改
   * @tags 店铺导航（商户创建时需要默认创建三个）
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/mallConfPage/update_PUT': (entity: MallConfPageAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mallConfPage/update`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/marketingcampaignconfig
   * @summary 新增
   * @tags 营销活动-配置管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/marketingcampaignconfig_POST': (t: MarketingCampaignConfig, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/marketingcampaignconfig`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/marketingcampaignconfig
   * @summary 修改
   * @tags 营销活动-配置管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/marketingcampaignconfig_PUT': (t: MarketingCampaignConfig, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/marketingcampaignconfig`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/marketingcampaignconfig/queryConfig
   * @summary 查询配置管理
   * @tags 营销活动-配置管理
   * @response `200` `JsonResultMarketingCampaignConfig` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/marketingcampaignconfig/queryConfig_GET': (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/marketingcampaignconfig/queryConfig`,
      method: 'GET',
      params,
      ...options
    }) as unknown as Promise<JsonResultMarketingCampaignConfig>,
  /**
   * No description
   * @name POST /admin/api/marketingcampaignconfig/updateConfig
   * @summary 编辑/新增配置管理
   * @tags 营销活动-配置管理
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/marketingcampaignconfig/updateConfig_POST': (dto: MarketingCampaignConfigDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/marketingcampaignconfig/updateConfig`,
      method: 'POST',
      data: dto,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/marketingcampaignconfig/{id}
   * @summary 详情
   * @tags 营销活动-配置管理
   * @response `200` `JsonResultMarketingCampaignConfig` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/marketingcampaignconfig/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/marketingcampaignconfig/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultMarketingCampaignConfig>,
  /**
   * No description
   * @name PUT /admin/api/marketingcampaignconfig/{id}
   * @summary 修改
   * @tags 营销活动-配置管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/marketingcampaignconfig/{id}_PUT': (id: number, t: MarketingCampaignConfig, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/marketingcampaignconfig/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/marketingcampaignconfig/{id}
   * @summary 删除
   * @tags 营销活动-配置管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/marketingcampaignconfig/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/marketingcampaignconfig/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/memberaddressmanagement
   * @summary 新增
   * @tags 用户收货地址管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberaddressmanagement_POST': (t: MemberAddressManagement, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberaddressmanagement`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/memberaddressmanagement
   * @summary 修改
   * @tags 用户收货地址管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberaddressmanagement_PUT': (t: MemberAddressManagement, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberaddressmanagement`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/memberaddressmanagement/queryList
   * @summary 用户收货地址查询
   * @tags 用户收货地址管理
   * @response `200` `JsonResultListMemberAddressManagement` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberaddressmanagement/queryList_GET': (query: AdminApiMemberaddressmanagementQueryListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberaddressmanagement/queryList`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMemberAddressManagement>,
  /**
   * No description
   * @name GET /admin/api/memberaddressmanagement/{id}
   * @summary 详情
   * @tags 用户收货地址管理
   * @response `200` `JsonResultMemberAddressManagement` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberaddressmanagement/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberaddressmanagement/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultMemberAddressManagement>,
  /**
   * No description
   * @name PUT /admin/api/memberaddressmanagement/{id}
   * @summary 修改
   * @tags 用户收货地址管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberaddressmanagement/{id}_PUT': (id: number, t: MemberAddressManagement, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberaddressmanagement/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/memberaddressmanagement/{id}
   * @summary 删除
   * @tags 用户收货地址管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/memberaddressmanagement/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberaddressmanagement/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/memberaudirecord
   * @summary 新增
   * @tags 会员审核记录管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberaudirecord_POST': (t: MemberAudirecord, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberaudirecord`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/memberaudirecord
   * @summary 修改
   * @tags 会员审核记录管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberaudirecord_PUT': (t: MemberAudirecord, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberaudirecord`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/memberaudirecord/query
   * @summary 分页查询
   * @tags 会员审核记录管理
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberaudirecord/query_GET': (query: AdminApiMemberaudirecordQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberaudirecord/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/memberaudirecord/{id}
   * @summary 详情
   * @tags 会员审核记录管理
   * @response `200` `JsonResultMemberAudirecord` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberaudirecord/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberaudirecord/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultMemberAudirecord>,
  /**
   * No description
   * @name PUT /admin/api/memberaudirecord/{id}
   * @summary 修改
   * @tags 会员审核记录管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberaudirecord/{id}_PUT': (id: number, t: MemberAudirecord, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberaudirecord/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/memberaudirecord/{id}
   * @summary 删除
   * @tags 会员审核记录管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/memberaudirecord/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberaudirecord/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/memberinfo
   * @summary 新增
   * @tags 会员信息
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberinfo_POST': (t: MemberInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberinfo`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/memberinfo
   * @summary 修改
   * @tags 会员信息
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberinfo_PUT': (t: MemberInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberinfo`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/memberinfo/deleteFamily
   * @summary 删除家庭会员
   * @tags 会员信息
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberinfo/deleteFamily_POST': (dto: FamilyInfoDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberinfo/deleteFamily`,
      method: 'POST',
      data: dto,
      ...options
    }) as unknown as Promise<JsonResultVoid>,

    '/admin/api/member/info/tag_PUT': (dto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/member/info/tag`,
      method: 'PUT',
      data: dto,
      ...options
    }) as unknown as Promise<JsonResultVoid>,

  /**
   * No description
   * @name GET /admin/api/memberinfo/queryCarInfoForMember/{id}
   * @summary 查询会员车产信息
   * @tags 会员信息
   * @response `200` `JsonResultListCarInfo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberinfo/queryCarInfoForMember/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberinfo/queryCarInfoForMember/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultListCarInfo>,
  /**
   * No description
   * @name GET /admin/api/memberinfo/queryHouseForMember/{id}
   * @summary 查询会员房产信息
   * @tags 会员信息
   * @response `200` `JsonResultListHouseInfoVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberinfo/queryHouseForMember/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberinfo/queryHouseForMember/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultListHouseInfoVo>,
  /**
   * No description
   * @name POST /admin/api/memberinfo/queryInfoByIds
   * @summary 根据id查询会员信息
   * @tags 会员信息
   * @response `200` `JsonResultPageInfoMemberInfoVo` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberinfo/queryInfoByIds_POST': (dto: MemberInfoDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberinfo/queryInfoByIds`,
      method: 'POST',
      data: dto,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMemberInfoVo>,
  /**
   * No description
   * @name GET /admin/api/memberinfo/queryIntegralLogForMember
   * @summary 查询会员积分日志
   * @tags 会员信息
   * @response `200` `JsonResultPageInfoIntegralLog` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberinfo/queryIntegralLogForMember_GET': (query: AdminApiMemberinfoQueryIntegralLogForMemberGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberinfo/queryIntegralLogForMember`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoIntegralLog>,
  /**
   * No description
   * @name GET /admin/api/memberinfo/queryMemberInfo/{id}
   * @summary 查询会员信息
   * @tags 会员信息
   * @response `200` `JsonResultMemberInfo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberinfo/queryMemberInfo/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberinfo/queryMemberInfo/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultMemberInfo>,
  /**
   * No description
   * @name GET /admin/api/memberinfo/queryMemberPage
   * @summary 分页查询会员信息
   * @tags 会员信息
   * @response `200` `JsonResultPageInfoMemberInfoVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberinfo/queryMemberPage_GET': (query: AdminApiMemberinfoQueryMemberPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/member/info/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMemberInfoVo>,
  /**
   * No description
   * @name GET /admin/api/memberinfo/queryMyFamily
   * @summary 查询家庭成员
   * @tags 会员信息
   * @response `200` `JsonResultListMyFamilyVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberinfo/queryMyFamily_GET': (query: AdminApiMemberinfoQueryMyFamilyGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberinfo/queryMyFamily`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMyFamilyVo>,
  /**
   * No description
   * @name POST /admin/api/memberinfo/updateFamilyInfo
   * @summary 修改家庭会员
   * @tags 会员信息
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberinfo/updateFamilyInfo_POST': (dto: FamilyInfoDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberinfo/updateFamilyInfo`,
      method: 'POST',
      data: dto,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name PUT /admin/api/memberinfo/updateMemberAgentStatus
   * @summary 修改用户明星经纪人状态
   * @tags 会员信息
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberinfo/updateMemberAgentStatus_PUT': (dto: MemberInfoDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberinfo/updateMemberAgentStatus`,
      method: 'PUT',
      data: dto,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/memberinfo/{id}
   * @summary 详情
   * @tags 会员信息
   * @response `200` `JsonResultMemberInfo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberinfo/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberinfo/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultMemberInfo>,
  /**
   * No description
   * @name PUT /admin/api/memberinfo/{id}
   * @summary 修改
   * @tags 会员信息
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberinfo/{id}_PUT': (id: number, t: MemberInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberinfo/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/memberinfo/{id}
   * @summary 删除
   * @tags 会员信息
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/memberinfo/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberinfo/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/memberlevelconfig
   * @summary 新增
   * @tags 会员等级
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberlevelconfig_POST': (t: MemberLevelConfig, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberlevelconfig`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/memberlevelconfig
   * @summary 修改
   * @tags 会员等级
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberlevelconfig_PUT': (t: MemberLevelConfig, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberlevelconfig`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/memberlevelconfig/addMemberLevel
   * @summary 新增会员等级
   * @tags 会员等级
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberlevelconfig/addMemberLevel_POST': (dto: MemberLevelConfigDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberlevelconfig/addMemberLevel`,
      method: 'POST',
      data: dto,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name DELETE /admin/api/memberlevelconfig/deleteMemberLevel/{id}
   * @summary 删除会员等级
   * @tags 会员等级
   * @response `200` `JsonResult` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/memberlevelconfig/deleteMemberLevel/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberlevelconfig/deleteMemberLevel/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/memberlevelconfig/query
   * @summary 分页查询所有会员等级
   * @tags 会员等级
   * @response `200` `JsonResultPageInfoMemberLevelConfig` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberlevelconfig/query_GET': (query: AdminApiMemberlevelconfigQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberlevelconfig/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMemberLevelConfig>,
  /**
   * No description
   * @name GET /admin/api/memberlevelconfig/queryAll
   * @summary 全部查询
   * @tags 会员等级
   * @response `200` `JsonResultListMemberLevelConfigVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberlevelconfig/queryAll_GET': (query: AdminApiMemberlevelconfigQueryAllGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberlevelconfig/queryAll`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMemberLevelConfigVo>,
  /**
   * No description
   * @name PUT /admin/api/memberlevelconfig/updateMemberLevel
   * @summary 编辑会员等级
   * @tags 会员等级
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberlevelconfig/updateMemberLevel_PUT': (dto: MemberLevelConfigDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberlevelconfig/updateMemberLevel`,
      method: 'PUT',
      data: dto,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/memberlevelconfig/{id}
   * @summary 详情
   * @tags 会员等级
   * @response `200` `JsonResultMemberLevelConfig` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberlevelconfig/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberlevelconfig/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultMemberLevelConfig>,
  /**
   * No description
   * @name PUT /admin/api/memberlevelconfig/{id}
   * @summary 修改
   * @tags 会员等级
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberlevelconfig/{id}_PUT': (id: number, t: MemberLevelConfig, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberlevelconfig/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/memberlevelconfig/{id}
   * @summary 删除
   * @tags 会员等级
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/memberlevelconfig/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberlevelconfig/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/memberlevellog
   * @summary 新增
   * @tags 会员等级日志管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberlevellog_POST': (t: MemberLevelLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberlevellog`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/memberlevellog
   * @summary 修改
   * @tags 会员等级日志管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberlevellog_PUT': (t: MemberLevelLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberlevellog`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/memberlevellog/query
   * @summary 分页查询
   * @tags 会员等级日志管理
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberlevellog/query_GET': (query: AdminApiMemberlevellogQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberlevellog/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/memberlevellog/{id}
   * @summary 详情
   * @tags 会员等级日志管理
   * @response `200` `JsonResultMemberLevelLog` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberlevellog/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberlevellog/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultMemberLevelLog>,
  /**
   * No description
   * @name PUT /admin/api/memberlevellog/{id}
   * @summary 修改
   * @tags 会员等级日志管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberlevellog/{id}_PUT': (id: number, t: MemberLevelLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberlevellog/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/memberlevellog/{id}
   * @summary 删除
   * @tags 会员等级日志管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/memberlevellog/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberlevellog/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/memberpowerconfig
   * @summary 新增
   * @tags 会员权益
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberpowerconfig_POST': (t: MemberPowerConfig, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberpowerconfig`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/memberpowerconfig
   * @summary 修改
   * @tags 会员权益
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberpowerconfig_PUT': (t: MemberPowerConfig, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberpowerconfig`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/memberpowerconfig/addMemberPower
   * @summary 新增会员权益
   * @tags 会员权益
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberpowerconfig/addMemberPower_POST': (entity: MemberPowerConfigDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberpowerconfig/addMemberPower`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/memberpowerconfig/query
   * @summary 分页查询
   * @tags 会员权益
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberpowerconfig/query_GET': (query: AdminApiMemberpowerconfigQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberpowerconfig/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/memberpowerconfig/queryAll
   * @summary 查询全部会员权益
   * @tags 会员权益
   * @response `200` `JsonResultListMemberPowerConfig` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberpowerconfig/queryAll_GET': (query: AdminApiMemberpowerconfigQueryAllGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberpowerconfig/queryAll`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMemberPowerConfig>,
  /**
   * No description
   * @name GET /admin/api/memberpowerconfig/queryMemberPowerOfLevel
   * @summary 查询会员等级权益
   * @tags 会员权益
   * @response `200` `JsonResultListLong` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberpowerconfig/queryMemberPowerOfLevel_GET': (query: AdminApiMemberpowerconfigQueryMemberPowerOfLevelGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberpowerconfig/queryMemberPowerOfLevel`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListLong>,
  /**
   * No description
   * @name DELETE /admin/api/memberpowerconfig/removeMemberPower/{id}
   * @summary 删除会员权益
   * @tags 会员权益
   * @response `200` `JsonResult` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/memberpowerconfig/removeMemberPower/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberpowerconfig/removeMemberPower/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name PUT /admin/api/memberpowerconfig/updateMemberPower
   * @summary 修改会员权益
   * @tags 会员权益
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberpowerconfig/updateMemberPower_PUT': (entity: MemberPowerConfigDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberpowerconfig/updateMemberPower`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/memberpowerconfig/{id}
   * @summary 详情
   * @tags 会员权益
   * @response `200` `JsonResultMemberPowerConfig` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberpowerconfig/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberpowerconfig/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultMemberPowerConfig>,
  /**
   * No description
   * @name PUT /admin/api/memberpowerconfig/{id}
   * @summary 修改
   * @tags 会员权益
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberpowerconfig/{id}_PUT': (id: number, t: MemberPowerConfig, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberpowerconfig/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/memberpowerconfig/{id}
   * @summary 删除
   * @tags 会员权益
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/memberpowerconfig/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberpowerconfig/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/memberpowerlevelref
   * @summary 新增
   * @tags 会员等级权益关联
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberpowerlevelref_POST': (t: MemberPowerLevelRef, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberpowerlevelref`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/memberpowerlevelref
   * @summary 修改
   * @tags 会员等级权益关联
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberpowerlevelref_PUT': (t: MemberPowerLevelRef, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberpowerlevelref`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/memberpowerlevelref/query
   * @summary 分页查询
   * @tags 会员等级权益关联
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberpowerlevelref/query_GET': (query: AdminApiMemberpowerlevelrefQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberpowerlevelref/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/memberpowerlevelref/{id}
   * @summary 详情
   * @tags 会员等级权益关联
   * @response `200` `JsonResultMemberPowerLevelRef` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberpowerlevelref/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberpowerlevelref/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultMemberPowerLevelRef>,
  /**
   * No description
   * @name PUT /admin/api/memberpowerlevelref/{id}
   * @summary 修改
   * @tags 会员等级权益关联
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/memberpowerlevelref/{id}_PUT': (id: number, t: MemberPowerLevelRef, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberpowerlevelref/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/memberpowerlevelref/{id}
   * @summary 删除
   * @tags 会员等级权益关联
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/memberpowerlevelref/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/memberpowerlevelref/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/offlineactivitymanagement
   * @summary 新增
   * @tags 营销活动-线下活动管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/offlineactivitymanagement_POST': (t: OfflineActivityManagement, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/offlineactivitymanagement`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/offlineactivitymanagement
   * @summary 修改
   * @tags 营销活动-线下活动管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/offlineactivitymanagement_PUT': (t: OfflineActivityManagement, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/offlineactivitymanagement`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/offlineactivitymanagement/addOfflineActivity
   * @summary 添加线下活动
   * @tags 营销活动-线下活动管理
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/offlineactivitymanagement/addOfflineActivity_POST': (
    query: AdminApiOfflineactivitymanagementAddOfflineActivityPostParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/offlineactivitymanagement/addOfflineActivity`,
      method: 'POST',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/offlineactivitymanagement/queryListPage
   * @summary 分页查询线下活动
   * @tags 营销活动-线下活动管理
   * @response `200` `JsonResultPageInfoOfflineActivityManagement` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/offlineactivitymanagement/queryListPage_GET': (query: AdminApiOfflineactivitymanagementQueryListPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/offlineactivitymanagement/queryListPage`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoOfflineActivityManagement>,
  /**
   * No description
   * @name GET /admin/api/offlineactivitymanagement/queryOfflineActivityDetail
   * @summary 查询线下活动详情
   * @tags 营销活动-线下活动管理
   * @response `200` `JsonResultOfflineActivityManagementDetailVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/offlineactivitymanagement/queryOfflineActivityDetail_GET': (
    query: AdminApiOfflineactivitymanagementQueryOfflineActivityDetailGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/offlineactivitymanagement/queryOfflineActivityDetail`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultOfflineActivityManagementDetailVo>,
  /**
   * No description
   * @name DELETE /admin/api/offlineactivitymanagement/removeOfflineActivity/{id}
   * @summary 删除线下活动
   * @tags 营销活动-线下活动管理
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/offlineactivitymanagement/removeOfflineActivity/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/offlineactivitymanagement/removeOfflineActivity/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name PUT /admin/api/offlineactivitymanagement/updateOfflineActivity
   * @summary 编辑线下活动
   * @tags 营销活动-线下活动管理
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/offlineactivitymanagement/updateOfflineActivity_PUT': (entity: OfflineActivityManagementDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/offlineactivitymanagement/updateOfflineActivity`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/offlineactivitymanagement/updateOfflineActivityStatus
   * @summary 编辑线下活动状态
   * @tags 营销活动-线下活动管理
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/offlineactivitymanagement/updateOfflineActivityStatus_POST': (entity: OfflineActivityManagementDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/offlineactivitymanagement/updateOfflineActivityStatus`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/offlineactivitymanagement/{id}
   * @summary 详情
   * @tags 营销活动-线下活动管理
   * @response `200` `JsonResultOfflineActivityManagement` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/offlineactivitymanagement/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/offlineactivitymanagement/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultOfflineActivityManagement>,
  /**
   * No description
   * @name PUT /admin/api/offlineactivitymanagement/{id}
   * @summary 修改
   * @tags 营销活动-线下活动管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/offlineactivitymanagement/{id}_PUT': (id: number, t: OfflineActivityManagement, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/offlineactivitymanagement/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/offlineactivitymanagement/{id}
   * @summary 删除
   * @tags 营销活动-线下活动管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/offlineactivitymanagement/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/offlineactivitymanagement/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/offlineactivitymembermanagement
   * @summary 新增
   * @tags 营销活动-线下活动参与会员
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/offlineactivitymembermanagement_POST': (t: OfflineActivityMemberManagement, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/offlineactivitymembermanagement`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/offlineactivitymembermanagement
   * @summary 修改
   * @tags 营销活动-线下活动参与会员
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/offlineactivitymembermanagement_PUT': (t: OfflineActivityMemberManagement, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/offlineactivitymembermanagement`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/offlineactivitymembermanagement/exportFreeMemberList
   * @summary 导出线下活动免费参与人员
   * @tags 营销活动-线下活动参与会员
   * @response `200` `void` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/offlineactivitymembermanagement/exportFreeMemberList_GET': (
    query: AdminApiOfflineactivitymembermanagementExportFreeMemberListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/offlineactivitymembermanagement/exportFreeMemberList`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<void>,
  /**
   * No description
   * @name GET /admin/api/offlineactivitymembermanagement/exportPayMemberList
   * @summary 导出线下活动付费参与人员
   * @tags 营销活动-线下活动参与会员
   * @response `200` `void` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/offlineactivitymembermanagement/exportPayMemberList_GET': (
    query: AdminApiOfflineactivitymembermanagementExportPayMemberListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/offlineactivitymembermanagement/exportPayMemberList`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<void>,
  /**
   * No description
   * @name POST /admin/api/offlineactivitymembermanagement/freeActivitiesParticipateAudit
   * @summary 免费线下活动会员参加审核
   * @tags 营销活动-线下活动参与会员
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/offlineactivitymembermanagement/freeActivitiesParticipateAudit_POST': (entity: OfflineActivityMemberManagementDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/offlineactivitymembermanagement/freeActivitiesParticipateAudit`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/offlineactivitymembermanagement/queryFreeMemberListPage
   * @summary 分页查询线下活动免费参与人员
   * @tags 营销活动-线下活动参与会员
   * @response `200` `JsonResultPageInfoOfflineActivityMemberManagementVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/offlineactivitymembermanagement/queryFreeMemberListPage_GET': (
    query: AdminApiOfflineactivitymembermanagementQueryFreeMemberListPageGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/offlineactivitymembermanagement/queryFreeMemberListPage`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoOfflineActivityMemberManagementVo>,
  /**
   * No description
   * @name GET /admin/api/offlineactivitymembermanagement/queryPayMemberListPage
   * @summary 分页查询线下活动付费参与人员
   * @tags 营销活动-线下活动参与会员
   * @response `200` `JsonResultPageInfoOfflineActivityMemberManagementVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/offlineactivitymembermanagement/queryPayMemberListPage_GET': (
    query: AdminApiOfflineactivitymembermanagementQueryPayMemberListPageGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/offlineactivitymembermanagement/queryPayMemberListPage`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoOfflineActivityMemberManagementVo>,
  /**
   * No description
   * @name GET /admin/api/offlineactivitymembermanagement/{id}
   * @summary 详情
   * @tags 营销活动-线下活动参与会员
   * @response `200` `JsonResultOfflineActivityMemberManagement` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/offlineactivitymembermanagement/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/offlineactivitymembermanagement/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultOfflineActivityMemberManagement>,
  /**
   * No description
   * @name PUT /admin/api/offlineactivitymembermanagement/{id}
   * @summary 修改
   * @tags 营销活动-线下活动参与会员
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/offlineactivitymembermanagement/{id}_PUT': (id: number, t: OfflineActivityMemberManagement, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/offlineactivitymembermanagement/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/offlineactivitymembermanagement/{id}
   * @summary 删除
   * @tags 营销活动-线下活动参与会员
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/offlineactivitymembermanagement/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/offlineactivitymembermanagement/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/order
   * @summary 新增
   * @tags 订单
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/order_POST': (t: Order, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/order`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/order
   * @summary 修改
   * @tags 订单
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/order_PUT': (t: Order, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/order`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/order/deliverGoods
   * @summary 发货
   * @tags 订单
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/order/deliverGoods_POST': (entity: OrderDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/order/deliverGoods`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/order/queryOrderDetail
   * @summary 订单分页查询
   * @tags 订单
   * @response `200` `JsonResultOrderDetailVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/order/queryOrderDetail_GET': (query: AdminApiOrderQueryOrderDetailGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mall/order/detail`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultOrderDetailVo>,
  /**
   * No description
   * @name GET /admin/api/mall/order/page
   * @summary 分页查询订单列表
   * @tags 订单
   * @response `200` `JsonResultPageInfoOrderDetailVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/mall/order/page_GET': (query: AdminApiOrderQueryOrderListPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mall/order/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoOrderDetailVo>,
  /**
   * No description
   * @name GET /admin/api/order/{id}
   * @summary 详情
   * @tags 订单
   * @response `200` `JsonResultOrder` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/order/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/order/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultOrder>,
  /**
   * No description
   * @name PUT /admin/api/order/{id}
   * @summary 修改
   * @tags 订单
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/order/{id}_PUT': (id: number, t: Order, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/order/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/order/{id}
   * @summary 删除
   * @tags 订单
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/order/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/order/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/orderitems
   * @summary 新增
   * @tags 订单详情
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/orderitems_POST': (t: OrderItems, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/orderitems`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/orderitems
   * @summary 修改
   * @tags 订单详情
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/orderitems_PUT': (t: OrderItems, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/orderitems`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/orderitems/query
   * @summary 订单详情分页查询
   * @tags 订单详情
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/orderitems/query_GET': (query: AdminApiOrderitemsQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/orderitems/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/orderitems/{id}
   * @summary 详情
   * @tags 订单详情
   * @response `200` `JsonResultOrderItems` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/orderitems/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/orderitems/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultOrderItems>,
  /**
   * No description
   * @name PUT /admin/api/orderitems/{id}
   * @summary 修改
   * @tags 订单详情
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/orderitems/{id}_PUT': (id: number, t: OrderItems, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/orderitems/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/orderitems/{id}
   * @summary 删除
   * @tags 订单详情
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/orderitems/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/orderitems/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/ownerinfo
   * @summary 新增
   * @tags 业主信息管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/ownerinfo_POST': (t: OwnerInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/ownerinfo`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/ownerinfo
   * @summary 修改
   * @tags 业主信息管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/ownerinfo_PUT': (t: OwnerInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/ownerinfo`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/ownerinfo/addOwnerHouse
   * @summary 新增业主房产
   * @tags 业主信息管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/ownerinfo/addOwnerHouse_POST': (dto: DoorPlateDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/ownerinfo/addOwnerHouse`,
      method: 'POST',
      data: dto,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/api/ownerinfo/addOwnerInfo
   * @summary 新增业主
   * @tags 业主信息管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/ownerinfo/addOwnerInfo_POST': (entity: OwnerInfoDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/ownerinfo/addOwnerInfo`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name DELETE /admin/api/ownerinfo/deleteOwnerHouse
   * @summary 删除业主房产
   * @tags 业主信息管理
   * @response `200` `JsonResult` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/ownerinfo/deleteOwnerHouse_DELETE': (query: AdminApiOwnerinfoDeleteOwnerHouseDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/ownerinfo/deleteOwnerHouse`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/api/ownerinfo/importOwnerInfo
   * @summary 业主信息导入
   * @tags 业主信息管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/ownerinfo/importOwnerInfo_POST': (file: any, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/ownerinfo/importOwnerInfo`,
      method: 'POST',
      data: file,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/ownerinfo/query
   * @summary 分页查询业主信息
   * @tags 业主信息管理
   * @response `200` `JsonResultPageInfoOwnerInfo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/ownerinfo/query_GET': (query: AdminApiOwnerinfoQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/ownerinfo/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoOwnerInfo>,
  /**
   * No description
   * @name GET /admin/api/ownerinfo/queryOwnerHouse
   * @summary 查询业主房产
   * @tags 业主信息管理
   * @response `200` `JsonResultListOwnerHouseVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/ownerinfo/queryOwnerHouse_GET': (query: AdminApiOwnerinfoQueryOwnerHouseGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/ownerinfo/queryOwnerHouse`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListOwnerHouseVo>,
  /**
   * No description
   * @name POST /admin/api/ownerinfo/updateOwnerHouse
   * @summary 编辑业主房产
   * @tags 业主信息管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/ownerinfo/updateOwnerHouse_POST': (dto: DoorPlateDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/ownerinfo/updateOwnerHouse`,
      method: 'POST',
      data: dto,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name PUT /admin/api/ownerinfo/updateOwnerInfo
   * @summary 编辑业主信息
   * @tags 业主信息管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/ownerinfo/updateOwnerInfo_PUT': (entity: OwnerInfoDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/ownerinfo/updateOwnerInfo`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/ownerinfo/{id}
   * @summary 详情
   * @tags 业主信息管理
   * @response `200` `JsonResultOwnerInfo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/ownerinfo/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/ownerinfo/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultOwnerInfo>,
  /**
   * No description
   * @name PUT /admin/api/ownerinfo/{id}
   * @summary 修改
   * @tags 业主信息管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/ownerinfo/{id}_PUT': (id: number, t: OwnerInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/ownerinfo/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/ownerinfo/{id}
   * @summary 删除
   * @tags 业主信息管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/ownerinfo/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/ownerinfo/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/paidactivityordermanagement
   * @summary 新增
   * @tags 线下活动-付费活动订单管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/paidactivityordermanagement_POST': (t: PaidActivityOrderManagement, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/paidactivityordermanagement`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/paidactivityordermanagement
   * @summary 修改
   * @tags 线下活动-付费活动订单管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/paidactivityordermanagement_PUT': (t: PaidActivityOrderManagement, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/paidactivityordermanagement`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/paidactivityordermanagement/exportPaidOrderList
   * @summary 导出付费活动订单
   * @tags 线下活动-付费活动订单管理
   * @response `200` `void` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/paidactivityordermanagement/exportPaidOrderList_GET': (
    query: AdminApiPaidactivityordermanagementExportPaidOrderListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/paidactivityordermanagement/exportPaidOrderList`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<void>,
  /**
   * No description
   * @name POST /admin/api/paidactivityordermanagement/payActivitiesRefundAudit
   * @summary 付费线下活动会员退款审核
   * @tags 线下活动-付费活动订单管理
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/paidactivityordermanagement/payActivitiesRefundAudit_POST': (entity: PaidActivityOrderManagementDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/paidactivityordermanagement/payActivitiesRefundAudit`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/paidactivityordermanagement/queryOrderListPage
   * @summary 分页查询活动订单
   * @tags 线下活动-付费活动订单管理
   * @response `200` `JsonResultPageInfoPaidActivityOrderManagementVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/paidactivityordermanagement/queryOrderListPage_GET': (
    query: AdminApiPaidactivityordermanagementQueryOrderListPageGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/admin/api/paidactivityordermanagement/queryOrderListPage`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoPaidActivityOrderManagementVo>,
  /**
   * No description
   * @name GET /admin/api/paidactivityordermanagement/{id}
   * @summary 详情
   * @tags 线下活动-付费活动订单管理
   * @response `200` `JsonResultPaidActivityOrderManagement` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/paidactivityordermanagement/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/paidactivityordermanagement/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultPaidActivityOrderManagement>,
  /**
   * No description
   * @name PUT /admin/api/paidactivityordermanagement/{id}
   * @summary 修改
   * @tags 线下活动-付费活动订单管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/paidactivityordermanagement/{id}_PUT': (id: number, t: PaidActivityOrderManagement, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/paidactivityordermanagement/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/paidactivityordermanagement/{id}
   * @summary 删除
   * @tags 线下活动-付费活动订单管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/paidactivityordermanagement/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/paidactivityordermanagement/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/paidactivityorderrefundrecord
   * @summary 新增
   * @tags 付费活动退款记录
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/paidactivityorderrefundrecord_POST': (t: PaidActivityOrderRefundRecord, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/paidactivityorderrefundrecord`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/paidactivityorderrefundrecord
   * @summary 修改
   * @tags 付费活动退款记录
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/paidactivityorderrefundrecord_PUT': (t: PaidActivityOrderRefundRecord, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/paidactivityorderrefundrecord`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/paidactivityorderrefundrecord/query
   * @summary 付费活动退款记录分页查询
   * @tags 付费活动退款记录
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/paidactivityorderrefundrecord/query_GET': (query: AdminApiPaidactivityorderrefundrecordQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/paidactivityorderrefundrecord/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/paidactivityorderrefundrecord/{id}
   * @summary 详情
   * @tags 付费活动退款记录
   * @response `200` `JsonResultPaidActivityOrderRefundRecord` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/paidactivityorderrefundrecord/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/paidactivityorderrefundrecord/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultPaidActivityOrderRefundRecord>,
  /**
   * No description
   * @name PUT /admin/api/paidactivityorderrefundrecord/{id}
   * @summary 修改
   * @tags 付费活动退款记录
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/paidactivityorderrefundrecord/{id}_PUT': (id: number, t: PaidActivityOrderRefundRecord, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/paidactivityorderrefundrecord/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/paidactivityorderrefundrecord/{id}
   * @summary 删除
   * @tags 付费活动退款记录
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/paidactivityorderrefundrecord/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/paidactivityorderrefundrecord/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/prizeWhitelist
   * @summary 新增
   * @tags 奖品白名单管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/prizeWhitelist_POST': (t: PrizeWhitelist, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/prizeWhitelist`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/prizeWhitelist
   * @summary 修改
   * @tags 奖品白名单管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/prizeWhitelist_PUT': (t: PrizeWhitelist, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/prizeWhitelist`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/prizeWhitelist/addInfo
   * @summary 奖品白名单新增
   * @tags 奖品白名单管理
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/prizeWhitelist/addInfo_POST': (entity: PrizeWhitelistDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/luckyDrawActivity/whitelist`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/api/prizeWhitelist/deleteInfo/{id}
   * @summary 奖品白名单删除
   * @tags 奖品白名单管理
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/prizeWhitelist/deleteInfo/{id}_DELETE': (query, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/luckyDrawActivity/whitelist`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/prizeWhitelist/getWhitelistNum/{prizeId}
   * @summary 查询该奖品白名单数量与该奖品现有剩余库存数量
   * @tags 奖品白名单管理
   * @response `200` `JsonResultPrizeWhitelistVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/prizeWhitelist/getWhitelistNum/{prizeId}_GET': (prizeId: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/prizeWhitelist/getWhitelistNum/${prizeId}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultPrizeWhitelistVo>,
  /**
   * No description
   * @name GET /admin/api/prizeWhitelist/queryPage
   * @summary 奖品白名单分页查询
   * @tags 奖品白名单管理
   * @response `200` `JsonResultPageInfoPrizeWhitelistVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/prizeWhitelist/queryPage_GET': (query: AdminApiPrizeWhitelistQueryPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/prizeWhitelist/queryPage`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoPrizeWhitelistVo>,
  /**
   * No description
   * @name PUT /admin/api/prizeWhitelist/updateInfo
   * @summary 奖品白名单编辑
   * @tags 奖品白名单管理
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/prizeWhitelist/updateInfo_PUT': (entity: PrizeWhitelistDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/luckyDrawActivity/whitelist`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/prizeWhitelist/{id}
   * @summary 详情
   * @tags 奖品白名单管理
   * @response `200` `JsonResultPrizeWhitelist` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/prizeWhitelist/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/prizeWhitelist/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultPrizeWhitelist>,
  /**
   * No description
   * @name PUT /admin/api/prizeWhitelist/{id}
   * @summary 修改
   * @tags 奖品白名单管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/prizeWhitelist/{id}_PUT': (id: number, t: PrizeWhitelist, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/prizeWhitelist/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/prizeWhitelist/{id}
   * @summary 删除
   * @tags 奖品白名单管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/prizeWhitelist/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/prizeWhitelist/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/questionnaire
   * @summary 新增
   * @tags 问卷主体
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/questionnaire_POST': (t: Questionnaire, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/questionnaire`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/questionnaire
   * @summary 修改
   * @tags 问卷主体
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/questionnaire_PUT': (t: Questionnaire, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/questionnaire`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/questionnaire/addQuestionnaire
   * @summary 问卷新增/修改
   * @tags 问卷主体
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/questionnaire/addQuestionnaire_POST': (dto: QuestionnaireDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/questionnaire/addQuestionnaire`,
      method: 'POST',
      data: dto,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/api/questionnaire/delete/{id}
   * @summary 问卷删除
   * @tags 问卷主体
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/questionnaire/delete/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/questionnaire/delete/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/questionnaire/query
   * @summary 问卷主体分页查询
   * @tags 问卷主体
   * @response `200` `JsonResultPageInfoQuestionnaire` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/questionnaire/query_GET': (query: AdminApiQuestionnaireQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/questionnaire/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoQuestionnaire>,
  /**
   * No description
   * @name GET /admin/api/questionnaire/queryInfo/{id}
   * @summary 问卷详情查询
   * @tags 问卷主体
   * @response `200` `JsonResultQuestionnaire` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/questionnaire/queryInfo/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/questionnaire/queryInfo/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultQuestionnaire>,
  /**
   * No description
   * @name GET /admin/api/questionnaire/{id}
   * @summary 详情
   * @tags 问卷主体
   * @response `200` `JsonResultQuestionnaire` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/questionnaire/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/questionnaire/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultQuestionnaire>,
  /**
   * No description
   * @name PUT /admin/api/questionnaire/{id}
   * @summary 修改
   * @tags 问卷主体
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/questionnaire/{id}_PUT': (id: number, t: Questionnaire, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/questionnaire/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/questionnaire/{id}
   * @summary 删除
   * @tags 问卷主体
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/questionnaire/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/questionnaire/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/questionnaireFill
   * @summary 新增
   * @tags 填写问卷主表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/questionnaireFill_POST': (t: QuestionnaireFill, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/questionnaireFill`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/questionnaireFill
   * @summary 修改
   * @tags 填写问卷主表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/questionnaireFill_PUT': (t: QuestionnaireFill, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/questionnaireFill`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/questionnaireFill/answerList
   * @summary 某项答案的所有答题列表分页
   * @tags 填写问卷主表
   * @response `200` `JsonResultPageInfoQuestionnaireFillAnswer` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/questionnaireFill/answerList_GET': (query: AdminApiQuestionnaireFillAnswerListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/questionnaireFill/answerList`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoQuestionnaireFillAnswer>,
  /**
   * No description
   * @name GET /admin/api/questionnaireFill/getInfo
   * @summary 用户维度统计-获取问卷详情
   * @tags 填写问卷主表
   * @response `200` `JsonResultListQuestionnaireFillQuestion` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/questionnaireFill/getInfo_GET': (query: AdminApiQuestionnaireFillGetInfoGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/questionnaireFill/getInfo`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListQuestionnaireFillQuestion>,
  /**
   * No description
   * @name GET /admin/api/questionnaireFill/list
   * @summary 题目维度统计
   * @tags 填写问卷主表
   * @response `200` `JsonResultListQuestionStatisticsVO` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/questionnaireFill/list_GET': (query: AdminApiQuestionnaireFillListGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/questionnaireFill/list`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListQuestionStatisticsVO>,
  /**
   * No description
   * @name GET /admin/api/questionnaireFill/query
   * @summary 用户维度统计分页查询
   * @tags 填写问卷主表
   * @response `200` `JsonResultPageInfoQuestionnaireFill` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/questionnaireFill/query_GET': (query: AdminApiQuestionnaireFillQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/questionnaireFill/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoQuestionnaireFill>,
  /**
   * No description
   * @name GET /admin/api/questionnaireFill/{id}
   * @summary 详情
   * @tags 填写问卷主表
   * @response `200` `JsonResultQuestionnaireFill` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/questionnaireFill/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/questionnaireFill/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultQuestionnaireFill>,
  /**
   * No description
   * @name PUT /admin/api/questionnaireFill/{id}
   * @summary 修改
   * @tags 填写问卷主表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/questionnaireFill/{id}_PUT': (id: number, t: QuestionnaireFill, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/questionnaireFill/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/questionnaireFill/{id}
   * @summary 删除
   * @tags 填写问卷主表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/questionnaireFill/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/questionnaireFill/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/questionrecord
   * @summary 新增
   * @tags 问答管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/questionrecord_POST': (t: QuestionRecord, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/questionrecord`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/questionrecord
   * @summary 修改
   * @tags 问答管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/questionrecord_PUT': (t: QuestionRecord, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/questionrecord`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/questionrecord/query
   * @summary 分页查询
   * @tags 问答管理
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/questionrecord/query_GET': (query: AdminApiQuestionrecordQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/questionrecord/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/questionrecord/queryQuestionRecordPage
   * @summary 分页查询问答管理
   * @tags 问答管理
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/questionrecord/queryQuestionRecordPage_GET': (query: AdminApiQuestionrecordQueryQuestionRecordPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/questionrecord/queryQuestionRecordPage`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/questionrecord/{id}
   * @summary 详情
   * @tags 问答管理
   * @response `200` `JsonResultQuestionRecord` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/questionrecord/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/questionrecord/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultQuestionRecord>,
  /**
   * No description
   * @name PUT /admin/api/questionrecord/{id}
   * @summary 修改
   * @tags 问答管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/questionrecord/{id}_PUT': (id: number, t: QuestionRecord, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/questionrecord/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/questionrecord/{id}
   * @summary 删除
   * @tags 问答管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/questionrecord/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/questionrecord/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/recommendconfig
   * @summary 新增
   * @tags 推荐配置管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/recommendconfig_POST': (t: RecommendConfig, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/recommendconfig`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/recommendconfig
   * @summary 修改
   * @tags 推荐配置管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/recommendconfig_PUT': (t: RecommendConfig, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/recommendconfig`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/recommendconfig/query
   * @summary 查询推荐配置
   * @tags 推荐配置管理
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/recommendconfig/query_GET': (query: AdminApiRecommendconfigQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/recommendconfig/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name POST /admin/api/recommendconfig/query
   * @summary 编辑/保存 推荐配置
   * @tags 推荐配置管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/recommendconfig/query_POST': (entity: RecommendConfigDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/recommendconfig/query`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/recommendconfig/{id}
   * @summary 详情
   * @tags 推荐配置管理
   * @response `200` `JsonResultRecommendConfig` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/recommendconfig/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/recommendconfig/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultRecommendConfig>,
  /**
   * No description
   * @name PUT /admin/api/recommendconfig/{id}
   * @summary 修改
   * @tags 推荐配置管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/recommendconfig/{id}_PUT': (id: number, t: RecommendConfig, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/recommendconfig/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/recommendconfig/{id}
   * @summary 删除
   * @tags 推荐配置管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/recommendconfig/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/recommendconfig/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/recommendrecord
   * @summary 新增
   * @tags 推荐记录
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/recommendrecord_POST': (t: RecommendRecord, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/recommendrecord`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/recommendrecord
   * @summary 修改
   * @tags 推荐记录
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/recommendrecord_PUT': (t: RecommendRecord, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/recommendrecord`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/recommendrecord/exportRecommendRecord
   * @summary 导出推荐记录
   * @tags 推荐记录
   * @response `200` `void` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/recommendrecord/exportRecommendRecord_GET': (query: AdminApiRecommendrecordExportRecommendRecordGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/recommendrecord/exportRecommendRecord`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<void>,
  /**
   * No description
   * @name GET /admin/api/recommendrecord/query
   * @summary 分页查询
   * @tags 推荐记录
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/recommendrecord/query_GET': (query: AdminApiRecommendrecordQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/recommendrecord/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/recommendrecord/queryRecommendRecord
   * @summary 分页查询推荐记录
   * @tags 推荐记录
   * @response `200` `JsonResultPageInfoRecommendRecordVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/recommendrecord/queryRecommendRecord_GET': (query: AdminApiRecommendrecordQueryRecommendRecordGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/recommendrecord/queryRecommendRecord`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoRecommendRecordVo>,
  /**
   * No description
   * @name GET /admin/api/recommendrecord/{id}
   * @summary 详情
   * @tags 推荐记录
   * @response `200` `JsonResultRecommendRecord` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/recommendrecord/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/recommendrecord/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultRecommendRecord>,
  /**
   * No description
   * @name PUT /admin/api/recommendrecord/{id}
   * @summary 修改
   * @tags 推荐记录
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/recommendrecord/{id}_PUT': (id: number, t: RecommendRecord, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/recommendrecord/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/recommendrecord/{id}
   * @summary 删除
   * @tags 推荐记录
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/recommendrecord/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/recommendrecord/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/recommendrewardrecord
   * @summary 新增
   * @tags 推荐奖励明细
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/recommendrewardrecord_POST': (t: RecommendRewardRecord, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/recommendrewardrecord`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/recommendrewardrecord
   * @summary 修改
   * @tags 推荐奖励明细
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/recommendrewardrecord_PUT': (t: RecommendRewardRecord, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/recommendrewardrecord`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/recommendrewardrecord/query
   * @summary 分页查询
   * @tags 推荐奖励明细
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/recommendrewardrecord/query_GET': (query: AdminApiRecommendrewardrecordQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/recommendrewardrecord/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/recommendrewardrecord/{id}
   * @summary 详情
   * @tags 推荐奖励明细
   * @response `200` `JsonResultRecommendRewardRecord` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/recommendrewardrecord/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/recommendrewardrecord/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultRecommendRewardRecord>,
  /**
   * No description
   * @name PUT /admin/api/recommendrewardrecord/{id}
   * @summary 修改
   * @tags 推荐奖励明细
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/recommendrewardrecord/{id}_PUT': (id: number, t: RecommendRewardRecord, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/recommendrewardrecord/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/recommendrewardrecord/{id}
   * @summary 删除
   * @tags 推荐奖励明细
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/recommendrewardrecord/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/recommendrewardrecord/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/refundorder
   * @summary 新增
   * @tags 售后表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/refundorder_POST': (t: RefundOrderReq, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/refundorder`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/refundorder
   * @summary 修改
   * @tags 售后表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/refundorder_PUT': (t: RefundOrderReq, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/refundorder`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/refundorder/batchPass
   * @summary 批量通过
   * @tags 售后表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/refundorder/batchPass_POST': (ids: number[], options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/refundorder/batchPass`,
      method: 'POST',
      data: ids,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/refundorder/batchRefuse
   * @summary 批量拒绝
   * @tags 售后表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/refundorder/batchRefuse_POST': (dto: BatchRefuseDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/refundorder/batchRefuse`,
      method: 'POST',
      data: dto,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/refundorder/pass
   * @summary 通过
   * @tags 售后表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/refundorder/pass_POST': (entity: RefundOrderDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/refundorder/pass`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/refundorder/queryRefundOrderDetail
   * @summary 查看售后详情
   * @tags 售后表
   * @response `200` `JsonResultRefundOrderDetailVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/refundorder/queryRefundOrderDetail_GET': (query: AdminApiRefundorderQueryRefundOrderDetailGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/refundorder/queryRefundOrderDetail`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultRefundOrderDetailVo>,
  /**
   * No description
   * @name GET /admin/api/mall/refundOrder/page
   * @summary 分页查询售后列表
   * @tags 售后表
   * @response `200` `JsonResultPageInfoRefundOrderDetailVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/mall/refundOrder/page_GET': (query: AdminApiRefundorderQueryRefundOrderPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/mall/refundOrder/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoRefundOrderDetailVo>,
  /**
   * No description
   * @name POST /admin/api/refundorder/receiveAndRefund
   * @summary 收货并退款
   * @tags 售后表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/refundorder/receiveAndRefund_POST': (entity: RefundOrderDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/refundorder/receiveAndRefund`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/refundorder/refund
   * @summary 退款
   * @tags 售后表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/refundorder/refund_POST': (entity: RefundOrderDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/refundorder/refund`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/refundorder/refuse
   * @summary 拒绝
   * @tags 售后表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/refundorder/refuse_POST': (entity: RefundOrderDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/refundorder/refuse`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/refundorder/{id}
   * @summary 详情
   * @tags 售后表
   * @response `200` `JsonResultRefundOrder` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/refundorder/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/refundorder/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultRefundOrder>,
  /**
   * No description
   * @name PUT /admin/api/refundorder/{id}
   * @summary 修改
   * @tags 售后表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/refundorder/{id}_PUT': (id: number, t: RefundOrderReq, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/refundorder/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/refundorder/{id}
   * @summary 删除
   * @tags 售后表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/refundorder/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/refundorder/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/refundorderitems
   * @summary 新增
   * @tags 售后详情表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/refundorderitems_POST': (t: RefundOrderItems, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/refundorderitems`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/refundorderitems
   * @summary 修改
   * @tags 售后详情表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/refundorderitems_PUT': (t: RefundOrderItems, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/refundorderitems`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/refundorderitems/query
   * @summary 售后详情表分页查询
   * @tags 售后详情表
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/refundorderitems/query_GET': (query: AdminApiRefundorderitemsQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/refundorderitems/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/refundorderitems/{id}
   * @summary 详情
   * @tags 售后详情表
   * @response `200` `JsonResultRefundOrderItems` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/refundorderitems/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/refundorderitems/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultRefundOrderItems>,
  /**
   * No description
   * @name PUT /admin/api/refundorderitems/{id}
   * @summary 修改
   * @tags 售后详情表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/refundorderitems/{id}_PUT': (id: number, t: RefundOrderItems, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/refundorderitems/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/refundorderitems/{id}
   * @summary 删除
   * @tags 售后详情表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/refundorderitems/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/refundorderitems/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/reviewmanager
   * @summary 新增
   * @tags 审核管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/reviewmanager_POST': (t: ReviewManager, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/reviewmanager`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/reviewmanager
   * @summary 修改
   * @tags 审核管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/reviewmanager_PUT': (t: ReviewManager, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/reviewmanager`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/reviewmanager/audit
   * @summary 审核
   * @tags 审核管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/reviewmanager/audit_POST': (dto: ReviewManagerDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/reviewmanager/audit`,
      method: 'POST',
      data: dto,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/reviewmanager/exportReviewManager
   * @summary 导出审核管理
   * @tags 审核管理
   * @response `200` `void` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/reviewmanager/exportReviewManager_GET': (query: AdminApiReviewmanagerExportReviewManagerGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/reviewmanager/exportReviewManager`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<void>,
  /**
   * No description
   * @name GET /admin/api/reviewmanager/query
   * @summary 分页查询
   * @tags 审核管理
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/reviewmanager/query_GET': (query: AdminApiReviewmanagerQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/reviewmanager/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/reviewmanager/queryReviewManagerPage
   * @summary 分页查询审核管理
   * @tags 审核管理
   * @response `200` `JsonResultPageInfoReviewManagerVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/reviewmanager/queryReviewManagerPage_GET': (query: AdminApiReviewmanagerQueryReviewManagerPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/reviewmanager/queryReviewManagerPage`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoReviewManagerVo>,
  /**
   * No description
   * @name POST /admin/api/reviewmanager/updateReview
   * @summary 编辑审核管理
   * @tags 审核管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/reviewmanager/updateReview_POST': (vo: ReviewManagerVo, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/reviewmanager/updateReview`,
      method: 'POST',
      data: vo,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/reviewmanager/{id}
   * @summary 详情
   * @tags 审核管理
   * @response `200` `JsonResultReviewManager` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/reviewmanager/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/reviewmanager/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultReviewManager>,
  /**
   * No description
   * @name PUT /admin/api/reviewmanager/{id}
   * @summary 修改
   * @tags 审核管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/reviewmanager/{id}_PUT': (id: number, t: ReviewManager, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/reviewmanager/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/reviewmanager/{id}
   * @summary 删除
   * @tags 审核管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/reviewmanager/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/reviewmanager/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/signactivity
   * @summary 新增
   * @tags 签到活动
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/signactivity_POST': (t: SignActivity, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/signactivity`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/signactivity
   * @summary 修改
   * @tags 签到活动
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/signactivity_PUT': (t: SignActivity, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/signactivity`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/signactivity/addSignActivity
   * @summary 新增活动
   * @tags 签到活动
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/signactivity/addSignActivity_POST': (entity: SignActivityDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/signactivity/addSignActivity`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name DELETE /admin/api/signactivity/deleteSignActivity
   * @summary 删除活动
   * @tags 签到活动
   * @response `200` `JsonResult` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/signactivity/deleteSignActivity_DELETE': (query: AdminApiSignactivityDeleteSignActivityDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/signactivity/deleteSignActivity`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/signactivity/query
   * @summary 分页查询签到活动
   * @tags 签到活动
   * @response `200` `JsonResultPageInfoSignActivity` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/signactivity/query_GET': (query: AdminApiSignactivityQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/signactivity/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSignActivity>,
  /**
   * No description
   * @name PUT /admin/api/signactivity/updateSignActivity
   * @summary 编辑活动
   * @tags 签到活动
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/signactivity/updateSignActivity_PUT': (entity: SignActivityDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/signactivity/updateSignActivity`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/signactivity/{id}
   * @summary 详情
   * @tags 签到活动
   * @response `200` `JsonResultSignActivity` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/signactivity/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/signactivity/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultSignActivity>,
  /**
   * No description
   * @name PUT /admin/api/signactivity/{id}
   * @summary 修改
   * @tags 签到活动
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/signactivity/{id}_PUT': (id: number, t: SignActivity, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/signactivity/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/signactivity/{id}
   * @summary 删除
   * @tags 签到活动
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/signactivity/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/signactivity/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/signconfig
   * @summary 新增
   * @tags 签到配置
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/signconfig_POST': (t: SignConfig, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/signconfig`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/signconfig
   * @summary 修改
   * @tags 签到配置
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/signconfig_PUT': (t: SignConfig, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/signconfig`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/signconfig/query
   * @summary 查询签到配置
   * @tags 签到配置
   * @response `200` `JsonResultSignConfig` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/signconfig/query_GET': (query: AdminApiSignconfigQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/signconfig/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSignConfig>,
  /**
   * No description
   * @name POST /admin/api/signconfig/updateSignConfig
   * @summary 编辑/添加签到配置
   * @tags 签到配置
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/signconfig/updateSignConfig_POST': (entity: SignConfigDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/signconfig/updateSignConfig`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/signconfig/{id}
   * @summary 详情
   * @tags 签到配置
   * @response `200` `JsonResultSignConfig` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/signconfig/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/signconfig/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultSignConfig>,
  /**
   * No description
   * @name PUT /admin/api/signconfig/{id}
   * @summary 修改
   * @tags 签到配置
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/signconfig/{id}_PUT': (id: number, t: SignConfig, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/signconfig/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/signconfig/{id}
   * @summary 删除
   * @tags 签到配置
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/signconfig/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/signconfig/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/signlog
   * @summary 新增
   * @tags 签到日志
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/signlog_POST': (t: SignLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/signlog`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/signlog
   * @summary 修改
   * @tags 签到日志
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/signlog_PUT': (t: SignLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/signlog`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/api/signlog/query
   * @summary 分页查询
   * @tags 签到日志
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/signlog/query_GET': (query: AdminApiSignlogQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/signlog/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/signlog/querySignLog
   * @summary 查询签到日志
   * @tags 签到日志
   * @response `200` `JsonResultPageInfoSignLogVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/signlog/querySignLog_GET': (query: AdminApiSignlogQuerySignLogGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/signlog/querySignLog`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSignLogVo>,
  /**
   * No description
   * @name GET /admin/api/signlog/{id}
   * @summary 详情
   * @tags 签到日志
   * @response `200` `JsonResultSignLog` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/signlog/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/signlog/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultSignLog>,
  /**
   * No description
   * @name PUT /admin/api/signlog/{id}
   * @summary 修改
   * @tags 签到日志
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/signlog/{id}_PUT': (id: number, t: SignLog, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/signlog/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/signlog/{id}
   * @summary 删除
   * @tags 签到日志
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/signlog/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/signlog/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/surroundinfo
   * @summary 新增
   * @tags 周边管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/surroundinfo_POST': (t: SurroundInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/surroundinfo`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/surroundinfo
   * @summary 修改
   * @tags 周边管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/surroundinfo_PUT': (t: SurroundInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/surroundinfo`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/surroundinfo/addForCommunity
   * @summary 添加小区周边
   * @tags 周边管理
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/surroundinfo/addForCommunity_POST': (entityList: SurroundInfo[], options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/surroundinfo/addForCommunity`,
      method: 'POST',
      data: entityList,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name GET /admin/api/surroundinfo/query
   * @summary 分页查询
   * @tags 周边管理
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/surroundinfo/query_GET': (query: AdminApiSurroundinfoQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/surroundinfo/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/surroundinfo/queryForCommunity
   * @summary 查询小区周边
   * @tags 周边管理
   * @response `200` `JsonResultListSurroundInfo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/surroundinfo/queryForCommunity_GET': (query: AdminApiSurroundinfoQueryForCommunityGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/surroundinfo/queryForCommunity`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListSurroundInfo>,
  /**
   * No description
   * @name GET /admin/api/surroundinfo/{id}
   * @summary 详情
   * @tags 周边管理
   * @response `200` `JsonResultSurroundInfo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/surroundinfo/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/surroundinfo/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultSurroundInfo>,
  /**
   * No description
   * @name PUT /admin/api/surroundinfo/{id}
   * @summary 修改
   * @tags 周边管理
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/surroundinfo/{id}_PUT': (id: number, t: SurroundInfo, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/surroundinfo/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /admin/api/surroundinfo/{id}
   * @summary 删除
   * @tags 周边管理
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/surroundinfo/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/surroundinfo/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /admin/api/sysRole/add
   * @summary 新增角色(返回ID)
   * @tags 系统-角色表
   * @response `200` `JsonResultLong` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/sysRole/add_POST': (entity: SysRoleAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRole/add`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultLong>,
  /**
   * No description
   * @name GET /admin/api/sysRole/allMenu
   * @summary 所有菜单模块
   * @tags 系统-角色表
   * @response `200` `JsonResultListSysMenuVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/sysRole/allMenu_GET': (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRole/allMenu`,
      method: 'GET',
      params,
      ...options
    }) as unknown as Promise<JsonResultListSysMenuVo>,
  /**
   * No description
   * @name DELETE /admin/api/sysRole/delete
   * @summary 角色删除
   * @tags 系统-角色表
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/sysRole/delete_DELETE': (query: AdminApiSysRoleDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRole/delete`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/sysRole/query
   * @summary 角色表分页查询
   * @tags 系统-角色表
   * @response `200` `JsonResultPageInfoSysRoleDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/sysRole/query_GET': (query: AdminApiSysRoleQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRole/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSysRoleDto>,
  /**
   * No description
   * @name GET /admin/api/sysRole/queryAll
   * @summary 角色表不分页查询
   * @tags 系统-角色表
   * @response `200` `JsonResultListSysRoleDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/sysRole/queryAll_GET': (query: AdminApiSysRoleQueryAllGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRole/queryAll`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListSysRoleDto>,
  /**
   * No description
   * @name PUT /admin/api/sysRole/update
   * @summary 修改角色
   * @tags 系统-角色表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/sysRole/update_PUT': (entity: SysRoleAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysRole/update`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/sysStoreUserGroup/add
   * @summary 资源分组新增(返回ID)
   * @tags 系统-企业资源分组
   * @response `200` `JsonResultLong` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/sysStoreUserGroup/add_POST': (entity: SysStoreUserGroupAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysStoreUserGroup/add`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultLong>,
  /**
   * No description
   * @name DELETE /admin/api/sysStoreUserGroup/delete
   * @summary 资源分组删除
   * @tags 系统-企业资源分组
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/sysStoreUserGroup/delete_DELETE': (query: AdminApiSysStoreUserGroupDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysStoreUserGroup/delete`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/sysStoreUserGroup/detail
   * @summary 资源分组详情
   * @tags 系统-企业资源分组
   * @response `200` `JsonResultSysStoreUserGroupVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/sysStoreUserGroup/detail_GET': (query: AdminApiSysStoreUserGroupDetailGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysStoreUserGroup/detail`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultSysStoreUserGroupVo>,
  /**
   * No description
   * @name GET /admin/api/sysStoreUserGroup/query
   * @summary 资源分组分页查询
   * @tags 系统-企业资源分组
   * @response `200` `JsonResultPageInfoSysStoreUserGroupVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/sysStoreUserGroup/query_GET': (query: AdminApiSysStoreUserGroupQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysStoreUserGroup/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSysStoreUserGroupVo>,
  /**
   * No description
   * @name PUT /admin/api/sysStoreUserGroup/update
   * @summary 资源分组修改
   * @tags 系统-企业资源分组
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/sysStoreUserGroup/update_PUT': (entity: SysStoreUserGroupAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysStoreUserGroup/update`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/sysUser/add
   * @summary 员工新增(返回ID)
   * @tags 系统-员工管理
   * @response `200` `JsonResultLong` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/sysUser/add_POST': (entity: SysUserAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysUser/add`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultLong>,
  /**
   * No description
   * @name DELETE /admin/api/sysUser/delete
   * @summary 员工删除
   * @tags 系统-员工管理
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/sysUser/delete_DELETE': (query: AdminApiSysUserDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysUser/delete`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/sysUser/query
   * @summary 员工表分页查询
   * @tags 系统-员工管理
   * @response `200` `JsonResultPageInfoSysUserVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/sysUser/query_GET': (query: AdminApiSysUserQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysUser/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSysUserVo>,
  /**
   * No description
   * @name DELETE /admin/api/sysUser/resetDefault
   * @summary 重置密码
   * @tags 系统-员工管理
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/sysUser/resetDefault_DELETE': (query: AdminApiSysUserResetDefaultDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysUser/resetDefault`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name PUT /admin/api/sysUser/update
   * @summary 员工修改
   * @tags 系统-员工管理
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/sysUser/update_PUT': (entity: SysUserAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysUser/update`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/api/sysUser/updatePassword
   * @summary 密码修改
   * @tags 系统-员工管理
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/sysUser/updatePassword_DELETE': (query: AdminApiSysUserUpdatePasswordDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysUser/updatePassword`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/api/sysdept/add
   * @summary 部门新增(返回ID)
   * @tags 部门表
   * @response `200` `JsonResultLong` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/sysdept/add_POST': (entity: SysDeptAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysdept/add`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultLong>,
  /**
   * No description
   * @name DELETE /admin/api/sysdept/delete
   * @summary 部门删除
   * @tags 部门表
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/api/sysdept/delete_DELETE': (query: AdminApiSysdeptDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysdept/delete`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/sysdept/query
   * @summary 部门表分页查询
   * @tags 部门表
   * @response `200` `JsonResultPageInfoSysDeptVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/sysdept/query_GET': (query: AdminApiSysdeptQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysdept/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoSysDeptVo>,
  /**
   * No description
   * @name GET /admin/api/sysdept/queryAll
   * @summary 部门表不分页查询
   * @tags 部门表
   * @response `200` `JsonResultListSysDeptVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/sysdept/queryAll_GET': (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysdept/queryAll`,
      method: 'GET',
      params,
      ...options
    }) as unknown as Promise<JsonResultListSysDeptVo>,
  /**
   * No description
   * @name GET /admin/api/sysdept/queryAllHaveUser
   * @summary 部门表不分页查询（包含员工信息）
   * @tags 部门表
   * @response `200` `JsonResultListSysDeptHaveUserVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/sysdept/queryAllHaveUser_GET': (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysdept/queryAllHaveUser`,
      method: 'GET',
      params,
      ...options
    }) as unknown as Promise<JsonResultListSysDeptHaveUserVo>,
  /**
   * No description
   * @name PUT /admin/api/sysdept/update
   * @summary 部门修改
   * @tags 部门表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/sysdept/update_PUT': (entity: SysDeptAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/sysdept/update`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/api/upload/info
   * @summary 阿里云存储信息
   * @tags 文件上传
   * @response `200` `JsonResultMapStringObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/upload/info_GET': (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/upload/info`,
      method: 'GET',
      params,
      ...options
    }) as unknown as Promise<JsonResultMapStringObject>,
  /**
   * No description
   * @name POST /admin/api/upload/uploadTest
   * @summary 测试图片存储
   * @tags 文件上传
   * @response `200` `JsonResult` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/api/upload/uploadTest_POST': (file: any, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/upload/uploadTest`,
      method: 'POST',
      data: file,
      ...options
    }) as unknown as Promise<JsonResult>,
  /**
   * No description
   * @name POST /admin/auth/forgot
   * @summary 忘记密码
   * @tags 授权（登录、注册、修改密码、获取当前权限、忘记密码）
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/auth/forgot_POST': (loginDto: RegisterDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/auth/forgot`,
      method: 'POST',
      data: loginDto,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * @description 默认账号:19999999999 密码ocj123456 密码需要aes加密，获取token后放在header中{Authorization:Bearer 你的token}
   * @name POST /admin/auth/login
   * @summary 登录
   * @tags 授权（登录、注册、修改密码、获取当前权限、忘记密码）
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/auth/login_POST': (loginDto: LoginDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/auth/login`,
      method: 'POST',
      data: loginDto,
      ...options
    }) as unknown as Promise<JsonResultString>,

  /**
   * No description
   * @name GET /admin/auth/query
   * @summary 获取当前用户权限
   * @tags 授权（登录、注册、修改密码、获取当前权限、忘记密码）
   * @response `200` `JsonResultSysAuthVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/auth/query_GET': (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/auth/query`,
      method: 'GET',
      params,
      ...options
    }) as unknown as Promise<JsonResultSysAuthVo>,
  /**
   * No description
   * @name POST /admin/auth/register
   * @summary 注册（返回token）
   * @tags 授权（登录、注册、修改密码、获取当前权限、忘记密码）
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/auth/register_POST': (loginDto: RegisterDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/auth/register`,
      method: 'POST',
      data: loginDto,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/auth/resetPwd
   * @summary 修改密码
   * @tags 授权（登录、注册、修改密码、获取当前权限、忘记密码）
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/auth/resetPwd_PUT': (resetPwdParam: ResetPwdParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/auth/resetPwd`,
      method: 'PUT',
      data: resetPwdParam,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/mallConfAdvertising/add
   * @summary 广告位新增
   * @tags 广告位设置
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfAdvertising/add_POST': (entity: MallConfAdvertisingAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfAdvertising/add`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/mallConfAdvertising/delete
   * @summary 广告位删除
   * @tags 广告位设置
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/mallConfAdvertising/delete_DELETE': (query: AdminMallConfAdvertisingDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfAdvertising/delete`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallConfAdvertising/detail
   * @summary 查询详情
   * @tags 广告位设置
   * @response `200` `JsonResultMallConfAdvertisingVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfAdvertising/detail_GET': (query: AdminMallConfAdvertisingDetailGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfAdvertising/detail`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMallConfAdvertisingVo>,
  /**
   * No description
   * @name GET /admin/mallConfAdvertising/query
   * @summary 广告位分页查询
   * @tags 广告位设置
   * @response `200` `JsonResultPageInfoMallConfAdvertisingVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfAdvertising/query_GET': (query: AdminMallConfAdvertisingQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfAdvertising/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallConfAdvertisingVo>,
  /**
   * No description
   * @name PUT /admin/mallConfAdvertising/update
   * @summary 广告位修改
   * @tags 广告位设置
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfAdvertising/update_PUT': (entity: MallConfAdvertisingAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfAdvertising/update`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/mallConfChannel/add
   * @summary 渠道新增
   * @tags 渠道
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfChannel/add_POST': (entity: MallConfChannelAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfChannel/add`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/mallConfChannel/delete
   * @summary 渠道删除
   * @tags 渠道
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/mallConfChannel/delete_DELETE': (query: AdminMallConfChannelDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfChannel/delete`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallConfChannel/query
   * @summary 渠道分页查询
   * @tags 渠道
   * @response `200` `JsonResultPageInfoMallConfChannelDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfChannel/query_GET': (query: AdminMallConfChannelQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfChannel/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallConfChannelDto>,
  /**
   * No description
   * @name PUT /admin/mallConfChannel/update
   * @summary 渠道修改
   * @tags 渠道
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfChannel/update_PUT': (entity: MallConfChannelAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfChannel/update`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/mallConfHotKey/add
   * @summary add
   * @tags 热门搜索
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfHotKey/add_POST': (entity: MallConfHotKeyAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfHotKey/add`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/mallConfHotKey/delete
   * @summary delete
   * @tags 热门搜索
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/mallConfHotKey/delete_DELETE': (query: AdminMallConfHotKeyDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfHotKey/delete`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallConfHotKey/query
   * @summary 热词分页查询
   * @tags 热门搜索
   * @response `200` `JsonResultPageInfoMallConfHotKeyDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfHotKey/query_GET': (query: AdminMallConfHotKeyQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfHotKey/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallConfHotKeyDto>,
  /**
   * No description
   * @name PUT /admin/mallConfHotKey/update
   * @summary updateAdmin
   * @tags 热门搜索
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfHotKey/update_PUT': (entity: MallConfHotKeyAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfHotKey/update`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/mallConfMaterial/add
   * @summary 素材库新增
   * @tags 素材库
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfMaterial/add_POST': (entity: MallConfMaterialAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfMaterial/add`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/mallConfMaterial/delete
   * @summary 素材库删除
   * @tags 素材库
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/mallConfMaterial/delete_DELETE': (query: AdminMallConfMaterialDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfMaterial/delete`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name PUT /admin/mallConfMaterial/move
   * @summary 移动素材到分组
   * @tags 素材库
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfMaterial/move_PUT': (entity: MallConfMaterialMoveParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfMaterial/move`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallConfMaterial/query
   * @summary 素材库分页查询
   * @tags 素材库
   * @response `200` `JsonResultPageInfoMallConfMaterialVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfMaterial/query_GET': (query: AdminMallConfMaterialQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfMaterial/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoMallConfMaterialVo>,
  /**
   * No description
   * @name PUT /admin/mallConfMaterial/update
   * @summary 素材库修改
   * @tags 素材库
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfMaterial/update_PUT': (entity: MallConfMaterialAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfMaterial/update`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/mallConfMaterialGroup/add
   * @summary 素材库分组新增
   * @tags 素材库分组
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfMaterialGroup/add_POST': (entity: MallConfMaterialGroupAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfMaterialGroup/add`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/mallConfMaterialGroup/delete
   * @summary 素材库分组删除
   * @tags 素材库分组
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/mallConfMaterialGroup/delete_DELETE': (query: AdminMallConfMaterialGroupDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfMaterialGroup/delete`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallConfMaterialGroup/queryAll
   * @summary 素材库分组不分页查询
   * @tags 素材库分组
   * @response `200` `JsonResultListMallConfMaterialGroupVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfMaterialGroup/queryAll_GET': (query: AdminMallConfMaterialGroupQueryAllGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfMaterialGroup/queryAll`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMallConfMaterialGroupVo>,
  /**
   * No description
   * @name PUT /admin/mallConfMaterialGroup/update
   * @summary 素材库分组修改
   * @tags 素材库分组
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfMaterialGroup/update_PUT': (entity: MallConfMaterialGroupAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfMaterialGroup/update`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/mallConfNavigation/add
   * @summary 店铺导航新增
   * @tags 店铺导航（商户创建时需要默认创建三个）
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfNavigation/add_POST': (entity: MallConfNavigationAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfNavigation/add`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/mallConfNavigation/delete
   * @summary 店铺导航删除
   * @tags 店铺导航（商户创建时需要默认创建三个）
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/mallConfNavigation/delete_DELETE': (query: AdminMallConfNavigationDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfNavigation/delete`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallConfNavigation/queryAll
   * @summary 店铺导航不分页查询
   * @tags 店铺导航（商户创建时需要默认创建三个）
   * @response `200` `JsonResultListMallConfNavigationVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfNavigation/queryAll_GET': (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfNavigation/queryAll`,
      method: 'GET',
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfNavigationVo>,
  /**
   * No description
   * @name PUT /admin/mallConfNavigation/update
   * @summary 店铺导航修改
   * @tags 店铺导航（商户创建时需要默认创建三个）
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfNavigation/update_PUT': (entity: MallConfNavigationAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfNavigation/update`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name POST /admin/mallConfRefundReason/add
   * @summary add
   * @tags 售后原因
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfRefundReason/add_POST': (entity: MallConfRefundReasonAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfRefundReason/add`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /admin/mallConfRefundReason/delete
   * @summary 售后原因删除
   * @tags 售后原因
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/mallConfRefundReason/delete_DELETE': (query: AdminMallConfRefundReasonDeleteDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfRefundReason/delete`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallConfRefundReason/queryAll
   * @summary 售后原因不分页查询
   * @tags 售后原因
   * @response `200` `JsonResultListMallConfRefundReasonDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfRefundReason/queryAll_GET': (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfRefundReason/queryAll`,
      method: 'GET',
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfRefundReasonDto>,
  /**
   * No description
   * @name PUT /admin/mallConfRefundReason/update
   * @summary updateAdmin
   * @tags 售后原因
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfRefundReason/update_PUT': (entity: MallConfRefundReasonAddParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfRefundReason/update`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallConfigController/base/activityNavigation
   * @summary 装修设置-活动导航 （通用设置接口可修改）             返回参数：is_open,  是否启动 true / falseimg_url ： 导航图片url_link ： 跳转连接
   * @tags 系统配置（支付、提现、客服等）
   * @response `200` `JsonResultListMallConfConfigDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfigController/base/activityNavigation_GET': (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfigController/base/activityNavigation`,
      method: 'GET',
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfConfigDto>,
  /**
   * No description
   * @name GET /admin/mallConfigController/base/order
   * @summary 基础设置-订单设置、售后流程、评论设置查询              返回参数：order_payment_waiting_time_hours, X小时未支付自动关闭订单            返回参数：order_receiving_waiting_time_day,发货后自动收货时间            返回参数：order_receiving_close,已收货自动关闭时间            返回参数：refund_only_money_auto_agree_day, *买家发起仅退款申请：后商家未处理，系统将自动同意并退款            返回参数：refund_money_and_goods_auto_agree_day, *买家发起退货退款申请：后商家未处理，系统将自动同意并退款            返回参数：refund_agree_goods_buyers_no_send, *商家同意退货退款，后买家未退货，系统将自动拒绝售后            返回参数：refund_agree_goods_buyers_send, *买家已退货，后商家未处理，系统将自动同意并退款            返回参数：auto_comment,收货后自动评价  true / false            返回参数：order_auto_comment_time_day,自动评价,待评价订单xx天后自动评价，默认好评            返回参数：auto_comment_content,自动评价内容
   * @tags 系统配置（支付、提现、客服等）
   * @response `200` `JsonResultListMallConfConfigDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfigController/base/order_GET': (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfigController/base/order`,
      method: 'GET',
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfConfigDto>,
  /**
   * No description
   * @name GET /admin/mallConfigController/base/themeConfig
   * @summary 基础设置-平台主题 （通用设置接口可修改）             返回参数：theme_config, 默认主题肤色   1 / 2 / 3 / 4
   * @tags 系统配置（支付、提现、客服等）
   * @response `200` `JsonResultListMallConfConfigDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfigController/base/themeConfig_GET': (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfigController/base/themeConfig`,
      method: 'GET',
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfConfigDto>,
  /**
   * No description
   * @name POST /admin/mallConfigController/insertAndUpdate
   * @summary 通用设置保存与修改 {key : value} 形式
   * @tags 系统配置（支付、提现、客服等）
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfigController/insertAndUpdate_POST': (data: Record<string, string>, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfigController/insertAndUpdate`,
      method: 'POST',
      data: data,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /admin/mallConfigController/queryByKey
   * @summary 根据key查询
   * @tags 系统配置（支付、提现、客服等）
   * @response `200` `JsonResultString` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfigController/queryByKey_GET': (query: AdminMallConfigControllerQueryByKeyGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfigController/queryByKey`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /admin/mallConfigController/store/orderConfig
   * @summary 店铺设置-订单设置 （通用设置接口可修改） 返回参数：inventory_deductions_type,  库存扣减方式 1 下单扣减 / 2 支付扣减 under_stock_type ： 库存不足处理方式 1 允许扣为负数 2 订单自动关闭并退款  3 缺货商品自动售后并退款
   * @tags 系统配置（支付、提现、客服等）
   * @response `200` `JsonResultListMallConfConfigDto` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallConfigController/store/orderConfig_GET': (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallConfigController/store/orderConfig`,
      method: 'GET',
      params,
      ...options
    }) as unknown as Promise<JsonResultListMallConfConfigDto>,
  /**
   * No description
   * @name DELETE /admin/mallGoodsCategory/del
   * @summary 类目删除 级联删除
   * @tags 商品类目表
   * @response `200` `JsonResultBoolean` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/admin/mallGoodsCategory/del_DELETE': (query: AdminMallGoodsCategoryDelDeleteParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallGoodsCategory/del`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name GET /admin/mallGoodsCategory/getOne
   * @summary 获取内容 三级类目 得到规格和属性
   * @tags 商品类目表
   * @response `200` `JsonResultMallGoodsCategoryVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallGoodsCategory/getOne_GET': (query: AdminMallGoodsCategoryGetOneGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallGoodsCategory/getOne`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultMallGoodsCategoryVo>,
  /**
   * @description 商品类目表查询
   * @name GET /admin/mallGoodsCategory/getPage
   * @summary 商品类目表查询
   * @tags 商品类目表
   * @response `200` `JsonResultListMallGoodsCategoryVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallGoodsCategory/getPage_GET': (query: AdminMallGoodsCategoryGetPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallGoodsCategory/getPage`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListMallGoodsCategoryVo>,
  /**
   * No description
   * @name POST /admin/mallGoodsCategory/saveOrUpdate
   * @summary 新增修改
   * @tags 商品类目表
   * @response `200` `JsonResultBoolean` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallGoodsCategory/saveOrUpdate_POST': (mallGoodsCategoryDto: MallGoodsCategoryParam, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallGoodsCategory/saveOrUpdate`,
      method: 'POST',
      data: mallGoodsCategoryDto,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name PUT /admin/mallGoodsCategory/show
   * @summary 是否展示
   * @tags 商品类目表
   * @response `200` `JsonResultBoolean` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallGoodsCategory/show_PUT': (query: AdminMallGoodsCategoryShowPutParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallGoodsCategory/show`,
      method: 'PUT',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name PUT /admin/mallGoodsCategory/sort
   * @summary 修改展示顺序 越大 越靠前
   * @tags 商品类目表
   * @response `200` `JsonResultBoolean` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/mallGoodsCategory/sort_PUT': (query: AdminMallGoodsCategorySortPutParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/mallGoodsCategory/sort`,
      method: 'PUT',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultBoolean>,
  /**
   * No description
   * @name GET /admin/oss/info
   * @summary 阿里云存储信息
   * @tags 阿里云存储
   * @response `200` `JsonResultMapStringObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/admin/oss/info_GET': (params: Record<string, any> = {}, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/oss/info`,
      method: 'GET',
      params,
      ...options
    }) as unknown as Promise<JsonResultMapStringObject>,

  //物业-员工列表查询
  '/admin/api/employeeInfo/page_GET': (query: staffListParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/employeeInfo/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //物业-员工删除

  '/admin/api/employeeInfo/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/employeeInfo`,
      method: 'DELETE',
      params: { id },
      ...options
    }) as unknown as Promise<JsonResultVoid>,

  //物业-添加员工
  '/admin/api/employeeInfo_POST': (t: AcceptanceEmployeesPost, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/employeeInfo`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,

  //物业-编辑员工
  '/admin/api/employeeInfo_PUT': (t: AcceptanceEmployeesPost, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/employeeInfo`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,

  //内容管理-资讯分类列表
  '/admin/api/information/classify/page_GET': (query: infoListParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/information/classify/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultString>,

  //内容管理-资讯分类新增
  '/admin/api/information/classify_POST': (entity: InformationClassifyDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/information/classify`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultString>,

  //内容管理-资讯分类修改
  '/admin/api/information/classify/updateInfo_PUT': (entity: InformationClassifyDto, t: InformationClassify, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/information/classify`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultString>,

  //内容管理-资讯分类删除
  '/admin/api/information/classify/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/information/classify?id=${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,

  //物业-维修分类列表
  '/admin/api/maintainClassify/page_GET': (query: infoListParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/maintain/classify/tree`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultString>,

  //物业-维修分类新增
  '/admin/api/maintainClassify_POST': (entity: maintainClassifyParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/maintain/classify`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultString>,

  //物业-维修分类修改
  '/admin/api/maintainClassify/updateInfo_PUT': (entity: maintainClassifyParams, t: InformationClassify, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/maintain/classify`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultString>,

  //物业-维修分类删除
  '/admin/api/maintainClassify/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/maintain/classify?id=${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,

  //物业-车位管理费详情
  '/admin/api/cartParkingFeeSetting/page_GET': (options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/cartParkingFeeSetting`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultParking>,

  //物业-车位管理费更新
  '/admin/api/cartParkingFeeSetting/updateInfo_PUT': (entity: parkingParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/cartParkingFeeSetting`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultString>,

  //物业-管家管理列表
  '/admin/api/employeeInfo/housekeeper/page_GET': (query: housekeeperSearchParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/employeeInfo/housekeeper/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //物业-管家管理新增
  '/admin/api/employeeInfo/housekeeper_POST': (entity: housekeeperParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/employeeInfo/housekeeper`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultString>,

  //物业-管家管理修改
  '/admin/api/employeeInfo/housekeeper/updateInfo_PUT': (entity: housekeeperParams, t: InformationClassify, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/employeeInfo/housekeeper`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultString>,

  //物业-管家管理删除
  '/admin/api/employeeInfo/housekeeper/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/employeeInfo/housekeeper?id=${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,

  //物业-管家关联业主管理列表
  '/admin/api/employeeOwnerRelationship/page_GET': (query, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/employeeOwnerRelationship/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //物业-业主未关联列表
  '/admin/api/employeeOwnerRelationship/queryUnRelationship_GET': (query, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/employeeOwnerRelationship/queryUnRelationship`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  // 管家新增关联业主
  '/admin/api/employeeOwnerRelationship_POST': (entity, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/employeeOwnerRelationship`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<any>,

  // 管家解绑业主
  '/admin/api/employeeOwnerRelationship_DELETE': (query, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/employeeOwnerRelationship`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //订单-物业维修订单列表
  '/admin/api/repair/order/page_GET': (query: repairOrderParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/repair/order/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //订单-物业维修订单详情
  '/admin/api/repair/order_GET': (query: repairOrderDetailParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/repair/order`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<repairOrderDetail>,

  //订单-物业维修订单分配员工
  '/admin/api/repair/order/allocate_PUT': (entity: allocateParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/repair/order/allocate`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultString>,

      //订单-物业维修订单重新分配员工
  '/admin/api/repair/order/reassign_PUT': (entity: allocateParams, options: RequestConfig = {}) =>
  requestInstance({
    url: `/admin/api/repair/order/reassign`,
    method: 'PUT',
    data: entity,
    ...options
  }) as unknown as Promise<JsonResultString>,

      //订单-物业维修订单分配员工-批量
  '/admin/api/repair/order/allocate/batch_PUT': (entity: allocateParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/repair/order/allocate/batch`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultString>,

          //订单-物业维修订单重新分配员工-批量
  '/admin/api/repair/order/reassign/batch_PUT': (entity: allocateParams, options: RequestConfig = {}) =>
  requestInstance({
    url: `/admin/api/repair/order/reassign/batch`,
    method: 'PUT',
    data: entity,
    ...options
  }) as unknown as Promise<JsonResultString>,

  //订单-物业维修订单取消
  '/admin/api/repair/order/cancel_GET': (query: repairOrderDetailParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/repair/order/cancel`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //订单-物业维修订单完成
  '/admin/api/repair/order/completed_GET': (query: repairOrderDetailParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/repair/order/completed`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //订单-物业已缴费列表
  '/admin/api/propertyFee/order/paid_GET': (query: propertyPayableParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/propertyFee/order/paid`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //订单-物业未缴费列表
  '/admin/api/propertyFee/order/unpaid_GET': (query: propertyPayableParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/propertyFee/order/unpaid`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  // 删除未缴费订单
  '/admin/api/propertyFee/order/unpaid_DELETE': (query: propertyPayableParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/propertyFee/order/unpaid`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //订单-访客预约申请列表
  '/admin/api/visitor/appointment/page_GET': (query: visitorAppointParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/visitor/appointment/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //订单-访客预约单个审批
  '/admin/api/visitor/appointment/single/approval_PUT': (entity: visitorAppointApproval, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/visitor/appointment/single/approval`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<any>,

  //订单-访客预约批量审批
  '/admin/api/visitor/appointment/batch/approval_PUT': (entity: visitorAppointApprovalAll, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/visitor/appointment/batch/approval`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<any>,
  '/admin/api/visitor/appointment/export_GET': (query: visitorAppointParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/admin/api/visitor/appointment/export`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  // 走马灯列表
  '/admin/api/marquee/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/marquee/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  // 新增走马灯
  '/admin/api/marquee_POST': (entity, options = {}) =>
    requestInstance({
      url: `/admin/api/marquee`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<any>,

  // 编辑走马灯数据
  '/admin/api/marquee/{id}_PUT': (entity, options = {}) =>
    requestInstance({
      url: `/admin/api/marquee`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResult>,

  // 删除走马灯数据
  '/admin/api/marquee/{id}_DELETE': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/marquee`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  // 转发界面管理
  '/admin/api/forwardInterface_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/forwardInterface`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //转发界面编辑
  '/admin/api/forwardInterface_PUT': (entity, options = {}) =>
    requestInstance({
      url: `/admin/api/forwardInterface`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<any>,

  //活动区管理-列表
  '/admin/api/activity/area/page_GET': (entity, options = {}) =>
    requestInstance({
      url: `/admin/api/activity/area/page`,
      method: 'GET',
      params: entity,
      ...options
    }) as unknown as Promise<any>,

  //活动区管理-编辑
  '/admin/api/activity/area_PUT': (entity, options = {}) =>
    requestInstance({
      url: `/admin/api/activity/area`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<any>,

  //物业问卷分类-列表
  '/admin/api/questionnaire/classify/page_GET': (entity, options = {}) =>
    requestInstance({
      url: `/admin/api/questionnaire/classify/page`,
      method: 'GET',
      params: entity,
      ...options
    }) as unknown as Promise<any>,

  //物业问卷分类-新增
  '/admin/api/questionnaire/classify_POST': (entity, options = {}) =>
    requestInstance({
      url: `/admin/api/questionnaire/classify`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<any>,

  //物业问卷分类-编辑
  '/admin/api/questionnaire/classify_PUT': (entity, options = {}) =>
    requestInstance({
      url: `/admin/api/questionnaire/classify`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<any>,

  //物业问卷分类-删除
  '/admin/api/questionnaire/classify_DELETE': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/questionnaire/classify`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //资讯评价-分页查询资讯评价列表
  '/admin/api/information/evaluation/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/information/evaluation/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  // 资讯评价-回复
  '/admin/api/information/evaluation/reply_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/information/evaluation/reply`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,
  // 资讯评价-删除
  '/admin/api/information/evaluation_DELETE': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/information/evaluation`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //客服中心-获取数据
  '/admin/api/customer/config_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/customer/config`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  // 客服中心-更新数据
  '/admin/api/customer/config_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/customer/config`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //获取配置数据-获取数据
  '/admin/api/rakeConfig_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/rakeConfig`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

      // 获取配置数据-更新数据
  '/admin/api/rakeConfig_PUT': (query, options = {}) =>
  requestInstance({
    url: `/admin/api/rakeConfig`,
    method: 'PUT',
    data: query,
    ...options
  }) as unknown as Promise<any>,

  //相关协议-页面数据列表
  '/admin/api/protocol/manage/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/protocol/manage/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //相关协议-页面数据修改
  '/admin/api/protocol/manage_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/protocol/manage`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //Q&A设置-分页查询列表
  '/admin/api/qa/manage/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/qa/manage/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //Q&A设置-新增
  '/admin/api/qa/manage_POST': (entity, options = {}) =>
    requestInstance({
      url: `/admin/api/qa/manage`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<any>,
  // Q&A设置-更新
  '/admin/api/qa/manage_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/qa/manage`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,
  // Q&A设置-删除
  '/admin/api/qa/manage_DELETE': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/qa/manage`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //用户标签管理-页面数据列表
  '/admin/api/member/tag/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/member/tag/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //用户标签管理-新增
  '/admin/api/member/tag_POST': (entity, options = {}) =>
    requestInstance({
      url: `/admin/api/member/tag`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<any>,

  // 用户标签管理-更新
  '/admin/api/member/tag_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/member/tag`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  // 用户标签管理-删除
  '/admin/api/member/tag_DELETE': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/member/tag`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //分页查询服务评价列表
  '/admin/api/service/evaluation/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/service/evaluation/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  // 服务评价列表-删除
  '/admin/api/service/evaluation_DELETE': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/service/evaluation`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  // 入口图设置-获取页面数据
  '/admin/api/mall/entrance_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/mall/entrance`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  // 入口图设置-更新
  '/admin/api/mall/entrance_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/mall/entrance`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  // 商家管理-分页查询数据列表
  '/admin/api/mall/store/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/mall/store/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  // 商家管理-新增商家
  '/admin/api/mall/store_POST': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/mall/store`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  // 商家管理-详情
  '/admin/api/mall/store_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/mall/store`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  // 商家管理-更新
  '/admin/api/mall/store_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/mall/store`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,
  // 商家管理-删除
  '/admin/api/mall/store_DELETE': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/mall/store`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  // 商家管理-冻结/取消冻结
  '/admin/api/mall/store/freeze_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/mall/store/freeze`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  // 商家分类-页面数据列表
  '/admin/api/mall/store/classify/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/mall/store/classify/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  // 商家分类-新增
  '/admin/api/mall/store/classify_POST': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/mall/store/classify`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,
  //商家分类-更新
  '/admin/api/mall/store/classify_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/mall/store/classify`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,
  //商家分类-删除
  '/admin/api/mall/store/classify_DELETE': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/mall/store/classify`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  // 分页查询自营认证审核列表
  '/admin/api/mall/store/authentication/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/mall/store/authentication/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //自营认证审核操作
  '/admin/api/mall/store/authentication/audit_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/mall/store/authentication/audit`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  // 生活轮播-分页查询
  '/admin/api/lifeServiceBanner/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeServiceBanner/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  // 生活轮播-新增
  '/admin/api/lifeServiceBanner_POST': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeServiceBanner`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  // 生活轮播-更新
  '/admin/api/lifeServiceBanner_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeServiceBanner`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //生活轮播-删除
  '/admin/api/lifeServiceBanner_DELETE': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeServiceBanner`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  // -公告指南
  '/admin/api/lifeService/notice/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeService/notice/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  // -公告指南 详情
  '/admin/api/lifeServiceNotice_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeService/notice`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //公告指南-新增
  '/admin/api/lifeService/notice_POST': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeService/notice`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,
  //公告指南-编辑
  '/admin/api/lifeService/notice_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeService/notice`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,
  //公告指南-删除
  '/admin/api/lifeService/notice_DELETE': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeService/notice`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  // 跳蚤-分类
  '/admin/api/fleaMarketClassify/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/fleaMarketClassify/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  // 跳蚤-新增
  '/admin/api/fleaMarketClassify_POST': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/fleaMarketClassify`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  // 跳蚤-修改
  '/admin/api/fleaMarketClassify_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/fleaMarketClassify`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  // 跳蚤-置顶修改
  '/admin/api/fleaMarketPost/top_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/fleaMarketPost/top`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,
  // 跳蚤-下架
  '/admin/api/fleaMarketPost/office_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/fleaMarketPost/office`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //跳蚤分类-删除
  '/admin/api/fleaMarketClassify_DELETE': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/fleaMarketClassify`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  // 跳蚤帖子列表
  '/admin/api/fleaMarketPost/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/fleaMarketPost/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  // 健身门店-管理列表
  '/admin/api/lifeService/shopInfo/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeService/shopInfo/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  // 健身门店-详情
  '/admin/api/lifeService/shopInfo_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeService/shopInfo`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  // 健身门店-添加
  '/admin/api/lifeService/shopInfo_POST': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeService/shopInfo`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //健身门店-编辑
  '/admin/api/lifeService/shopInfo_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeService/shopInfo`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //健身门店-删除
  '/admin/api/lifeService/shopInfo_DELETE': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeService/shopInfo`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //门店设备列表
  '/admin/api/lifeService/shopProject/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeService/shopProject/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  // 健身门店-设备详情
  '/admin/api/lifeService/shopProject_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeService/shopProject`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  // 健身门店-添加设备
  '/admin/api/lifeService/shopProject_POST': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeService/shopProject`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //健身门店-编辑设备
  '/admin/api/lifeService/shopProject_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeService/shopProject`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,
  //健身门店-删除设备
  '/admin/api/lifeService/shopProject_DELETE': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeService/shopProject`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //家政服务列表
  '/admin/api/lifeService/housekeepingService/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeService/housekeepingService/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //家政服务列表-删除
  '/admin/api/lifeService/housekeepingService_DELETE': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeService/housekeepingService`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //家政服务列表-详情
  '/admin/api/lifeService/housekeepingService_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeService/housekeepingService`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  // 家政服务列表-添加
  '/admin/api/lifeService/housekeepingService_POST': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeService/housekeepingService`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //家政服务列表-编辑
  '/admin/api/lifeService/housekeepingService_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeService/housekeepingService`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //家政服务分类-列表
  '/admin/api/lifeService/housekeepingClassify/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeService/housekeepingClassify/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //家政服务分类-详情
  '/admin/api/lifeService/housekeepingClassify_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeService/housekeepingClassify`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  // 家政服务分类-添加
  '/admin/api/lifeService/housekeepingClassify_POST': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeService/housekeepingClassify`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //家政服务分类-编辑
  '/admin/api/lifeService/housekeepingClassify_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeService/housekeepingClassify`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //家政服务分类--删除
  '/admin/api/lifeService/housekeepingClassify_DELETE': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeService/housekeepingClassify`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //投票分类
  '/admin/api/vote/classify/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/vote/classify/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  // 投票分类-添加
  '/admin/api/vote/classify_POST': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/vote/classify`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //投票分类-编辑
  '/admin/api/vote/classify_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/vote/classify`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,
  //投票分类--删除
  '/admin/api/vote/classify_DELETE': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/vote/classify`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //投票管理-列表
  '/admin/api/vote/manage/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/vote/manage/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  // 投票管理-添加
  '/admin/api/vote/manage_POST': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/vote/manage`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,
  //投票管理-编辑
  '/admin/api/vote/manage_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/vote/manage`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //投票管理-详情
  '/admin/api/vote/manage/detail_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/vote/manage/detail`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //投票管理-删除
  '/admin/api/vote/manage_DELETE': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/vote/manage`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //投票管理-参与人页面数据列表
  '/admin/api/participant/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/participant/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //投票管理-参与者新增
  '/admin/api/participant_POST': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/participant`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //投票管理-参与者更新
  '/admin/api/participant_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/participant`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //投票管理-参与者删除
  '/admin/api/participant_DELETE': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/participant`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //投票管理-获奖者名单
  '/admin/api/participant/award_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/participant/award`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //红包管理
  '/admin/api/red/packet/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/red/packet/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
//领取列表
 '/admin/api/red/packet/receive/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/red/packet/receive/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //领取列表-统计
 '/admin/api/red/packet/receive/statistics_GET': (query, options = {}) =>
 requestInstance({
   url: `/admin/api/red/packet/receive/statistics`,
   method: 'GET',
   params: query,
   ...options
 }) as unknown as Promise<any>,  

  // 新增红包
  '/admin/api/red/packet_POST': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/red/packet`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,
  //红包管理新增
  '/admin/api/red/packet_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/red/packet`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,
  //红包管理-删除
  '/admin/api/red/packet_DELETE': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/red/packet`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //红包管理-固定设置
  '/admin/api/red/packet/fixed_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/red/packet/fixed`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //红包管理-随机设置
  '/admin/api/red/packet/random_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/red/packet/random`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,
  //红包管理 -每日最多中奖次数
  '/admin/api/red/packet/config_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/red/packet/config`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //红包管理-每日最多中奖次数
  '/admin/api/red/packet/config_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/red/packet/config`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //敏感词
  '/admin/api/sensitive/word_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/sensitive/word`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //敏感词-编辑
  '/admin/api/sensitive/word_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/sensitive/word`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //家政服务订单列表
  '/admin/api/life/housekeeping/order/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/life/housekeeping/order/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //家政服务订单列表-员工列表
  '/admin/api/employeeInfo/list_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/employeeInfo/list`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //家政服务订单列表-分配员工
  '/admin/api/life/housekeeping/order/assign_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/life/housekeeping/order/assign`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,
 //家政服务订单列表-重新分配员工
 '/admin/api/life/housekeeping/order/reassign_PUT': (query, options = {}) =>
 requestInstance({
   url: `/admin/api/life/housekeeping/order/reassign`,
   method: 'PUT',
   data: query,
   ...options
 }) as unknown as Promise<any>,
      //家政服务订单列表-批量分配员工
  '/admin/api/life/housekeeping/order/assign/batch_PUT': (query, options = {}) =>
  requestInstance({
    url: `/admin/api/life/housekeeping/order/assign/batch`,
    method: 'PUT',
    data: query,
    ...options
  }) as unknown as Promise<any>,

      //家政服务订单列表-批量重新分配员工
      '/admin/api/life/housekeeping/order/reassign/batch_PUT': (query, options = {}) =>
      requestInstance({
        url: `/admin/api/life/housekeeping/order/reassign/batch`,
        method: 'PUT',
        data: query,
        ...options
      }) as unknown as Promise<any>,

  //家政服务订单列表-详情
  '/admin/api/life/housekeeping/order/detail_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/life/housekeeping/order/detail`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //健身娱乐订单列表
  '/admin/api/life/shopProject/order/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/life/shopProject/order/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //健身娱乐-核销
  '/admin/api/life/shopProject/order/redeem_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/life/shopProject/order/redeem`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //健身娱乐订单列表-详情
  '/admin/api/life/shopProject/order/detail_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/life/shopProject/order/detail`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //健身娱乐订单列表-门店
  '/admin/api/lifeService/shopInfo/list_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/lifeService/shopInfo/list`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //分页查询普通商品列表（用于商品推荐）
  '/admin/api/mall/goods/recommend/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/mall/goods/recommend/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //推荐商品
  '/admin/api/mall/goods/recommend/recommend_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/mall/goods/recommend/recommend`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,
  //设置排序值
  '/admin/api/mall/goods/recommend/sort_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/mall/goods/recommend/sort`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //复制问卷
  '/admin/api/questionnaire/copy_POST': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/questionnaire/copy`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //审批配送申请
  '/admin/api/mall/order/delivery/audit_POST': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/mall/order/delivery/audit`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

      //批量审批配送申请
  '/admin/api/mall/order/delivery/audit/batch_POST': (query, options = {}) =>
  requestInstance({
    url: `/admin/api/mall/order/delivery/audit/batch`,
    method: 'POST',
    data: query,
    ...options
  }) as unknown as Promise<any>,
  //优惠券全部商品
  '/admin/api/coupon/goods/allPage_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/coupon/goods/allPage`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //优惠券部分商品
  '/admin/api/coupon/goods/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/coupon/goods/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //店铺统计
  '/admin/api/data/statistics/store_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/data/statistics/store`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //店铺分类
  '/admin/api/mall/store/classify/all_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/mall/store/classify/all`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //会员总数统计
  '/admin/api/data/statistics/member/total_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/data/statistics/member/total`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //会员图表统计
  '/admin/api/data/statistics/member/chart_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/data/statistics/member/chart`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,  
    //流量统计 
    '/admin/api/data/statistics/flow_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/data/statistics/flow`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //用户画像
  '/admin/api/data/statistics/personas_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/data/statistics/personas`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //获取热门搜索词
  '/admin/api/site/keywords_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/site/keywords`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //更新热门搜索词

  '/admin/api/site/keywords_PUT': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/site/keywords`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,
  //获取抽奖白名单
  '/admin/api/luckyDrawActivity/whitelist/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/luckyDrawActivity/whitelist/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  // 新增抽奖白名单

  '/admin/api/luckyDrawActivity/whitelist_POST': (query, options = {}) =>
  requestInstance({
    url: `/admin/api/luckyDrawActivity/whitelist`,
    method: 'POST',
    data: query,
    ...options
  }) as unknown as Promise<any>,

//编辑抽奖白名单
'/admin/api/luckyDrawActivity/whitelist_PUT': (query, options = {}) =>
requestInstance({
  url: `/admin/api/luckyDrawActivity/whitelist`,
  method: 'PUT',
  data: query,
  ...options
}) as unknown as Promise<any>,

//删除抽奖白名单

'/admin/api/luckyDrawActivity/whitelist_DELETE': (query, options = {}) =>
requestInstance({
  url: `/admin/api/luckyDrawActivity/whitelist`,
  method: 'DELETE',
  params: query,
  ...options
}) as unknown as Promise<any>,


//修改账号审核
'/admin/api/mall/account/authentication/audit_PUT': (query, options = {}) =>
requestInstance({
  url: `/admin/api/mall/account/authentication/audit`,
  method: 'PUT',
  data: query,
  ...options
}) as unknown as Promise<any>,
  //分页查询修改账号审核的列表
  '/admin/api/mall/account/authentication/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/mall/account/authentication/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //分页查询评价列表
  '/admin/api/mall/goods/evaluation/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/mall/goods/evaluation/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

 //拒绝
'/admin/api/mall/goods/evaluation/reject_PUT': (query, options = {}) =>
requestInstance({
  url: `/admin/api/mall/goods/evaluation/reject`,
  method: 'PUT',
  data: query,
  ...options
}) as unknown as Promise<any>,   
//删除评论
'/admin/api/mall/goods/evaluation_DELETE': (query, options = {}) =>
requestInstance({
  url: `/admin/api/mall/goods/evaluation`,
  method: 'DELETE',
  params: query,
  ...options
}) as unknown as Promise<any>,
//导入员工
'/admin/api/employeeInfo/import_POST': (file: any, options: RequestConfig = {}) =>
requestInstance({
  url: `/admin/api/employeeInfo/import`,
  method: 'POST',
  data: file,
  ...options
}) as unknown as Promise<JsonResult>,
//导入管家
'/admin/api/employeeInfo/housekeeper/import_POST': (file: any, options: RequestConfig = {}) =>
requestInstance({
  url: `/admin/api/employeeInfo/housekeeper/import`,
  method: 'POST',
  data: file,
  ...options
}) as unknown as Promise<JsonResult>,

//删除小区
'/admin/api/community_DELETE': (query, options = {}) =>
requestInstance({
  url: `/admin/api/community`,
  method: 'DELETE',
  params: query,
  ...options
}) as unknown as Promise<any>,

  //商家异议列表
  '/admin/api/mall/appeal/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/mall/appeal/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
// 处理异议
    '/admin/api/mall/appeal_POST': (file: any, options: RequestConfig = {}) =>
requestInstance({
  url: `/admin/api/mall/appeal`,
  method: 'POST',
  data: file,
  ...options
}) as unknown as Promise<JsonResult>,

  //财务数据-统计
  '/admin/api/mall/settlement/statistics_GET': (query, options = {}) =>
    requestInstance({
      url: `/admin/api/mall/settlement/statistics`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

      //财务数据列表-分页
  '/admin/api/mall/settlement/page_GET': (query, options = {}) =>
  requestInstance({
    url: `/admin/api/mall/settlement/page`,
    method: 'GET',
    params: query,
    ...options
  }) as unknown as Promise<any>,

        //商城优惠券结算-分页
        '/admin/api/mall/settlement/coupon/page_GET': (query, options = {}) =>
        requestInstance({
          url: `/admin/api/mall/settlement/coupon/page`,
          method: 'GET',
          params: query,
          ...options
        }) as unknown as Promise<any>,

  //结算
'/admin/api/mall/settlement/coupon_POST': (file: any, options: RequestConfig = {}) =>
requestInstance({
  url: `/admin/api/mall/settlement/coupon`,
  method: 'POST',
  data: file,
  ...options
}) as unknown as Promise<JsonResult>,    

// 提醒
'/admin/api/mall/settlement/coupon/remind_PUT': (query, options = {}) =>
requestInstance({
  url: `/admin/api/mall/settlement/coupon/remind`,
  method: 'PUT',
  data: query,
  ...options
}) as unknown as Promise<any>,    


        //分享管理列表
        '/admin/share/manage/list_GET': (query, options = {}) =>
        requestInstance({
          url: `/admin/share/manage/list`,
          method: 'GET',
          params: query,
          ...options
        }) as unknown as Promise<any>,
                //分享管理编辑
        '/admin/share/manage_GET': (query, options = {}) =>
        requestInstance({
          url: `/admin/share/manage`,
          method: 'PUT',
  data: query,
          ...options
        }) as unknown as Promise<any>,
  //商家端接口


  //商家端
  '/admin/merchant/login_POST': (loginDto: LoginDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/auth/login`,
      method: 'POST',
      data: loginDto,
      ...options
    }) as unknown as Promise<JsonResultString>,

  //获取自营认证详情
  '/merchant/api/authentication_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/authentication`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //提交自营认证信息
  '/merchant/api/authentication_PUT': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/authentication`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //获取店铺详情
  '/merchant/api/store_GET': (query, options = {}) =>
    requestInstance({
      // url: `/merchant/api/store`,
      url: `/merchant/api/store`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //修改店铺信息
  '/merchant/api/store_PUT': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/store`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //获取店铺账号信息
  '/merchant/api/account/authentication_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/account/authentication`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //修改店铺账号信息
  '/merchant/api/account/authentication_PUT': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/account/authentication`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  /**
   * No description
   * @name POST /merchant/api/expresstemplate
   * @summary 新增
   * @tags 运费模板表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplate_POST': (t: ExpressTemplate, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplate`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /merchant/api/expresstemplate
   * @summary 修改
   * @tags 运费模板表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplate_PUT': (t: ExpressTemplate, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplate`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /merchant/api/expresstemplate/addTemplate
   * @summary 新增模板
   * @tags 运费模板表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplate/addTemplate_POST': (entity: ExpressTemplateDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplate/addTemplate`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name DELETE /merchant/api/expresstemplate/deleteTemplate/{id}
   * @summary 删除模板
   * @tags 运费模板表
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/merchant/api/expresstemplate/deleteTemplate/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplate/deleteTemplate/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /merchant/api/expresstemplate/queryAllExpressTemplateList
   * @summary 查询所有运费模板
   * @tags 运费模板表
   * @response `200` `JsonResultListExpressTemplate` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplate/queryAllExpressTemplateList_GET': (
    query: AdminApiExpresstemplateQueryAllExpressTemplateListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/merchant/api/expresstemplate/queryAllExpressTemplateList`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultListExpressTemplate>,
  /**
   * No description
   * @name GET /merchant/api/expresstemplate/queryExpressTemplateDetail
   * @summary 模板详情
   * @tags 运费模板表
   * @response `200` `JsonResultExpressTemplateVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplate/queryExpressTemplateDetail_GET': (
    query: AdminApiExpresstemplateQueryExpressTemplateDetailGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/merchant/api/expresstemplate/queryExpressTemplateDetail`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultExpressTemplateVo>,
  /**
   * No description
   * @name GET /merchant/api/expresstemplate/queryExpressTemplateList
   * @summary 运费模板表分页查询
   * @tags 运费模板表
   * @response `200` `JsonResultPageInfoExpressTemplate` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplate/queryExpressTemplateList_GET': (
    query: AdminApiExpresstemplateQueryExpressTemplateListGetParams,
    options: RequestConfig = {}
  ) =>
    requestInstance({
      url: `/merchant/api/expresstemplate/queryExpressTemplateList`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoExpressTemplate>,
  /**
   * No description
   * @name PUT /merchant/api/expresstemplate/updateTemplate
   * @summary 编辑模板
   * @tags 运费模板表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplate/updateTemplate_PUT': (entity: ExpressTemplateDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplate/updateTemplate`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /merchant/api/expresstemplate/{id}
   * @summary 详情
   * @tags 运费模板表
   * @response `200` `JsonResultExpressTemplate` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplate/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplate/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultExpressTemplate>,
  /**
   * No description
   * @name PUT /admin/api/expresstemplate/{id}
   * @summary 修改
   * @tags 运费模板表
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplate/{id}_PUT': (id: number, t: ExpressTemplate, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplate/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /merchant/api/expresstemplate/{id}
   * @summary 删除
   * @tags 运费模板表
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/merchant/api/expresstemplate/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplate/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /merchant/api/expresstemplatearea
   * @summary 新增
   * @tags 运费模版地址
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplatearea_POST': (t: ExpressTemplateArea, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplatearea`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /merchant/api/expresstemplatearea
   * @summary 修改
   * @tags 运费模版地址
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplatearea_PUT': (t: ExpressTemplateArea, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplatearea`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /merchant/api/expresstemplatearea/query
   * @summary 运费模版地址分页查询
   * @tags 运费模版地址
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplatearea/query_GET': (query: AdminApiExpresstemplateareaQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplatearea/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /merchant/api/expresstemplatearea/{id}
   * @summary 详情
   * @tags 运费模版地址
   * @response `200` `JsonResultExpressTemplateArea` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplatearea/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplatearea/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultExpressTemplateArea>,
  /**
   * No description
   * @name PUT /merchant/api/expresstemplatearea/{id}
   * @summary 修改
   * @tags 运费模版地址
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplatearea/{id}_PUT': (id: number, t: ExpressTemplateArea, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplatearea/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /merchant/api/expresstemplatearea/{id}
   * @summary 删除
   * @tags 运费模版地址
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/merchant/api/expresstemplatearea/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplatearea/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name POST /merchant/api/expresstemplatedetails
   * @summary 新增
   * @tags 运费模版详情
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplatedetails_POST': (t: ExpressTemplateDetails, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplatedetails`,
      method: 'POST',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name PUT /admin/api/expresstemplatedetails
   * @summary 修改
   * @tags 运费模版详情
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplatedetails_PUT': (t: ExpressTemplateDetails, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplatedetails`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name GET /merchant/api/expresstemplatedetails/query
   * @summary 运费模版详情分页查询
   * @tags 运费模版详情
   * @response `200` `JsonResultObject` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplatedetails/query_GET': (query: AdminApiExpresstemplatedetailsQueryGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplatedetails/query`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultObject>,
  /**
   * No description
   * @name GET /admin/api/expresstemplatedetails/{id}
   * @summary 详情
   * @tags 运费模版详情
   * @response `200` `JsonResultExpressTemplateDetails` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplatedetails/{id}_GET': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplatedetails/${id}`,
      method: 'GET',
      ...options
    }) as unknown as Promise<JsonResultExpressTemplateDetails>,
  /**
   * No description
   * @name PUT /merchant/api/expresstemplatedetails/{id}
   * @summary 修改
   * @tags 运费模版详情
   * @response `200` `JsonResultString` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/expresstemplatedetails/{id}_PUT': (id: number, t: ExpressTemplateDetails, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplatedetails/${id}`,
      method: 'PUT',
      data: t,
      ...options
    }) as unknown as Promise<JsonResultString>,
  /**
   * No description
   * @name DELETE /merchant/api/expresstemplatedetails/{id}
   * @summary 删除
   * @tags 运费模版详情
   * @response `200` `JsonResultString` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/merchant/api/expresstemplatedetails/{id}_DELETE': (id: number, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/expresstemplatedetails/${id}`,
      method: 'DELETE',
      ...options
    }) as unknown as Promise<JsonResultString>,

  /**
   * No description
   * @name GET /merchant/api/goods/page
   * @summary 商品实体表分页查询
   * @tags 商品实体表
   * @response `200` `JsonResultPageInfoGoods` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/goods/page_GET': (query: AdminApiGoodsQueryGoodsPageGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/goods/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultPageInfoGoods>,

  /**
   * No description
   * @name PUT /merchant/api/goods/sale
   * @summary 修改商品状态
   * @tags 商品实体表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/goods/sale_PUT': (entity: GoodsDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/goods/sale`,
      method: 'PUT',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,

  //获取商品分类列表(普通列表)
  '/merchant/api/goods/classify/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/goods/classify/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  /**
   * No description
   * @name PUT /merchant/api/goods
   * @summary 编辑商品
   * @tags 商品实体表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/goods_PUT': (query, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/goods`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,

  /**
   * No description
   * @name POST /merchant/api/goods
   * @summary 新增商品
   * @tags 商品实体表
   * @response `200` `JsonResultVoid` OK |  `201` `void` Created |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/goods_POST': (entity: GoodsDto, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/goods`,
      method: 'POST',
      data: entity,
      ...options
    }) as unknown as Promise<JsonResultVoid>,

  /**
   * No description
   * @name DELETE /merchant/api/goods
   * @summary 删除商品
   * @tags 商品实体表
   * @response `200` `JsonResultVoid` OK |  `204` `void` No Content |  `401` `void` Unauthorized |  `403` `void` Forbidden
   */
  '/merchant/api/goods_DELETE': (query, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/goods`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultVoid>,
  /**
   * No description
   * @name GET /merchant/api/goods
   * @summary 查询商品详情
   * @tags 商品实体表
   * @response `200` `JsonResultGoodsDetailVo` OK |  `401` `void` Unauthorized |  `403` `void` Forbidden |  `404` `void` Not Found
   */
  '/merchant/api/goods_GET': (query: AdminApiGoodsQueryGoodsDetailGetParams, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/goods`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<JsonResultGoodsDetailVo>,

  //评价管理
  '/merchant/api/goods/evaluation/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/goods/evaluation/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //评价回复
  '/merchant/api/goods/evaluation/reply_PUT': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/goods/evaluation/reply`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,
   //评价回复
  '/merchant/api/goods/evaluation/review/reply_PUT': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/goods/evaluation/review/reply`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,   
  //评价申诉
  '/merchant/api/goods/evaluation/appeal_PUT': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/goods/evaluation/appeal`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //分页查询优惠卷列表
  '/merchant/api/store/coupon/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/store/coupon/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //新增优惠券
  '/merchant/api/store/coupon_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/store/coupon`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //更新优惠卷
  '/merchant/api/store/coupon_PUT': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/store/coupon`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //优惠券-删除
  '/merchant/api/store/coupon_DELETE': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/store/coupon`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //优惠卷上架/下架
  '/merchant/api/store/coupon/enable_PUT': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/store/coupon/enable`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //优惠券-部分商品
  '/merchant/api/store/coupon/goods/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/store/coupon/goods/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //更新优惠券-部分商品
  '/merchant/api/store/coupon/goods_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/store/coupon/goods`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,
  //优惠券-删除部分商品
  '/merchant/api/store/coupon/goods_DELETE': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/store/coupon/goods`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //分页查询拼团商品列表
  '/merchant/api/combination/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/combination/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //分页查询拼团列表
  '/merchant/api/ms/pink/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/pink/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //拼团列表-详情
  '/merchant/api/ms/pink/detail_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/pink/detail`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //拼团商品详情
  '/merchant/api/combination_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/combination`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //分页查询可选择的商品列表（用户拼团和秒杀选择商品）
  '/merchant/api/goods/select_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/goods/select`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //新增拼团商品
  '/merchant/api/combination_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/combination`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //更新拼团商品
  '/merchant/api/combination_PUT': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/combination`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //拼团商品上架/下架
  '/merchant/api/combination/sale_PUT': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/combination/sale`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,
  //删除拼团商品
  '/merchant/api/combination_DELETE': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/combination`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //分页查询秒杀商品列表
  '/merchant/api/seckill/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/seckill/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //删除秒杀商品
  '/merchant/api/seckill_DELETE': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/seckill`,
      method: 'DELETE',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //新增秒杀商品
  '/merchant/api/seckill_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/seckill`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //更新秒杀商品
  '/merchant/api/seckill_PUT': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/seckill`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //秒杀商品上架/下架
  '/merchant/api/seckill/sale_PUT': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/seckill/sale`,
      method: 'PUT',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //查询秒杀商品详情
  '/merchant/api/seckill_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/seckill`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //订单管理
  '/merchant/api/ms/order/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/order/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //订单详情
  '/merchant/api/ms/order/detail_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/order/detail`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //订单-申请配送列表
  '/merchant/api/ms/order/delivery/audit/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/order/delivery/audit/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //申请平台配送
  '/merchant/api/ms/order/deliver/apply_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/order/deliver/apply`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //批量申请平台配送
  '/merchant/api/ms/order/deliver/apply/batch_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/order/deliver/apply/batch`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //商家配送
  '/merchant/api/ms/order/deliver/merchant_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/order/deliver/merchant`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //物流发货
  '/merchant/api/ms/order/express_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/order/express`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //自提
  '/merchant/api/ms/order/delivery/self_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/order/delivery/self`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //售后-列表
  '/merchant/api/ms/refundOrder/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/refundOrder/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //售后-详情
  '/merchant/api/ms/refundOrder/detail_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/refundOrder/detail`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //审核通过
  '/merchant/api/ms/refundOrder/audit/pass_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/refundOrder/audit/pass`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,
  //审核不通过
  '/merchant/api/ms/refundOrder/audit/refuse_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/refundOrder/audit/refuse`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //财务管理-账单列表
  '/merchant/api/ms/settlement/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/settlement/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,
  //财务管理-账单列表-申诉
  '/merchant/api/ms/appeal_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/appeal`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,

  //财务管理-商城优惠券结算列表
  '/merchant/api/ms/settlement/coupon/page_GET': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/settlement/coupon/page`,
      method: 'GET',
      params: query,
      ...options
    }) as unknown as Promise<any>,

  //财务管理-商城优惠券结算列表-确认
  '/merchant/api/ms/settlement/coupon_POST': (query, options = {}) =>
    requestInstance({
      url: `/merchant/api/ms/settlement/coupon`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<any>,
      //优惠券使用列表
  '/merchant/api/store/coupon/statistics/page_GET': (query, options = {}) =>
  requestInstance({
    url: `/merchant/api/store/coupon/statistics/page`,
    method: 'GET',
    params: query,
    ...options
  }) as unknown as Promise<any>,
       //优惠券使用列表-统计
       '/merchant/api/store/coupon/statistics_GET': (query, options = {}) =>
       requestInstance({
         url: `/merchant/api/store/coupon/statistics`,
         method: 'GET',
         params: query,
         ...options
       }) as unknown as Promise<any>,

       //今日销售总额/订单量
       '/merchant/api/store/statistics/today_GET': (query, options = {}) =>
       requestInstance({
         url: `/merchant/api/store/statistics/today`,
         method: 'GET',
         params: query,
         ...options
       }) as unknown as Promise<any>,
       //商品销售排行榜（前十）
       '/merchant/api/store/statistics/goods/rank_GET': (query, options = {}) =>
       requestInstance({
         url: `/merchant/api/store/statistics/goods/rank`,
         method: 'GET',
         params: query,
         ...options
       }) as unknown as Promise<any>,

       //商品统计
       '/merchant/api/store/statistics/goods_GET': (query, options = {}) =>
       requestInstance({
         url: `/merchant/api/store/statistics/goods`,
         method: 'GET',
         params: query,
         ...options
       }) as unknown as Promise<any>,
       //订单情况
       '/merchant/api/store/statistics/order/state_GET': (query, options = {}) =>
       requestInstance({
         url: `/merchant/api/store/statistics/order/state`,
         method: 'GET',
         params: query,
         ...options
       }) as unknown as Promise<any>,  
       
       //订单统计
       '/merchant/api/store/statistics/order_GET': (query, options = {}) =>
       requestInstance({
         url: `/merchant/api/store/statistics/order`,
         method: 'GET',
         params: query,
         ...options
       }) as unknown as Promise<any>,  
     //销售统计         
     '/merchant/api/store/statistics/sale_GET': (query, options = {}) =>
     requestInstance({
       url: `/merchant/api/store/statistics/sale`,
       method: 'GET',
       params: query,
       ...options
     }) as unknown as Promise<any>,


     //分页查询优惠卷领取列表
        '/merchant/api/store/coupon/member/page_GET': (query, options = {}) =>
     requestInstance({
       url: `/merchant/api/store/coupon/member/page`,
       method: 'GET',
       params: query,
       ...options
     }) as unknown as Promise<any> ,
       //自提（发货）
  '/merchant/api/ms/order/delivery/pack_POST': (query, options = {}) =>
  requestInstance({
    url: `/merchant/api/ms/order/delivery/pack`,
    method: 'POST',
    data: query,
    ...options
  }) as unknown as Promise<any>,
  //商品导入
  '/merchant/api/goods/import_POST': (file: any, options: RequestConfig = {}) =>
  requestInstance({
    url: `/merchant/api/goods/import`,
    method: 'POST',
    data: file,
    ...options
  }) as unknown as Promise<JsonResult>,


    //确认退款
    '/merchant/api/ms/refundOrder/doRefund_POST': (query: any, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/ms/refundOrder/doRefund`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<JsonResult>,
    //确认不退款
    '/merchant/api/ms/refundOrder/notRefund_POST': (query: any, options: RequestConfig = {}) =>
    requestInstance({
      url: `/merchant/api/ms/refundOrder/notRefund`,
      method: 'POST',
      data: query,
      ...options
    }) as unknown as Promise<JsonResult>    
}
