import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
import { EAuthCode } from '~/enums/EAuthCode';

export default [
  {
    path: routeNames.recommendConfiguration,
    component: () => import('../../pages/recommendManage/recommendConfiguration'),
    meta: { title: '推荐配置',
    code:EAuthCode.AdviseManage_Config }
  },
  {
    path: routeNames.verifyManage,
    component: () => import('../../pages/recommendManage/verifyManage'),
    meta: { title: '审核管理',
    code:EAuthCode.AdviseManage_AuditManage }
  },
  {
    path: routeNames.recommendManage,
    component: () => import('../../pages/recommendManage'),
    meta: { title: '推荐管理',
    code:EAuthCode.AdviseManage_AManage }
  }
] as TRoutes[]