import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';
import { EAuthCode } from '~/enums/EAuthCode';
export default [
  {
    path: routeNames.departmentManagement,
    component: () => import('../../pages/departmentManagement'),
    meta: { title: '部门管理' ,
    code:EAuthCode.PermissionsManage_DeptManage }
  },
  {
    path: routeNames.employeeManagement,
    component: () => import('../../pages/departmentManagement/employeeManagement'),
    meta: { title: '用户管理',
    code:EAuthCode.PermissionsManage_UserManage }
  },
  {
    path: routeNames.roleManagement,
    component: () => import('../../pages/departmentManagement/roleManagement'),
    meta: { title: '角色管理' ,
    code:EAuthCode.PermissionsManage_RoleManage}
  }
] as TRoutes[]